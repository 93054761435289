import { createAsyncThunk } from "@reduxjs/toolkit";
import userApi from "api/userApi";
// import exApi from "api/Extenal/exApi";
import TokenService from "utils/TokenService";

const userLogin = createAsyncThunk("user/login", async (params, { rejectWithValue }) => {
  try {
    const { username, password, remember } = params;
    const data = await userApi.login(params);
    // const token = await exApi.setTokenCall();
    await TokenService.setUserLocalStorage(data);
    // await TokenService.updateTokenCall(token?.data?.payload?.access_token);
    TokenService.setRemember(remember);
    if (remember) {
      TokenService.setAccount({ username, password });
    } else {
      TokenService.removeAccount();
    }
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const userRefresh = createAsyncThunk("user/refresh", async (params, { rejectWithValue }) => {
  try {
    const data = await userApi.refreshToken(params);
    TokenService.updateTokenLocalStorage(data.token);
    return data;
  } catch (error) {
    TokenService.removeUserLocalStorage();
    window.location.href = "/login";
    return rejectWithValue(error);
  }
});

export { userLogin, userRefresh };
