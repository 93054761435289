import { createSlice } from "@reduxjs/toolkit";
import { userMgtSearchAll } from "./userMgtAction";

const initialState = {
  loading: false,
  error: null,
  userList: [],
  userRoleList: [],
  success: false,
};

const userMgtSlice = createSlice({
  name: "userMgt",
  initialState,
  reducers: {
    storeUserlist: (state, { payload }) => {
      state.userList = payload;
    },
  },

  extraReducers: {
    [userMgtSearchAll.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userMgtSearchAll.fulfilled]: (state, { payload }) => {
      state.userList = payload.data.userList;
      state.userRoleList = payload.data.userRoleList;
      state.loading = false;
      state.success = true;
    },
    [userMgtSearchAll.rejected]: (state, { payload }) => {
      state.loading = true;
      state.error = payload;
    },
  },
});

const { reducer, actions } = userMgtSlice;

export { actions as userMgt };

export default reducer;
