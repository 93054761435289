import React from "react";
import Dashboard from "pages/Admin/Dashboard";
import Chart from "pages/Chart/Chart";
import Login from "pages/Login/Login";
import NotFound from "pages/NotFound";
import { useRoutes } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import MenuMgt from "pages/system/MenuMgt/MenuMgt";
import UserMgt from "pages/system/Organtization/User/UserMgt";
import TripMap from "pages/TripMap/TripMap";
import TripPlan from "pages/TripPlan/TripPlan";
import TripMap2 from "pages/TripMap-2/TripMap";
import KhungGio from "pages/KhungGio/KhungGio";
import Driver from "pages/Driver/Driver";
import Car from "pages/Car/Car";
import TripRecordList from "pages/TripRecordList/TripRecordList";
import CallHistoryList from "pages/CallHistoryList/CallHistoryList";
import RevenueList from "pages/RevenueList/RevenueList";
import VeThangList from "pages/VeThangList/VeThangList";
import DriverSalaryList from "pages/DriverSalary/DriverSalaryList";
const Route = () => {
  const element = useRoutes([
    {
      path: "/login",
      element: <Login />,
    },
    {
      element: <AuthRoute />,
      children: [
        {
          path: "/",
          element: <Chart />,
        },
        {
          path: "/*",
          element: <NotFound />,
        },
        {
          path: "/UI_ADM_006",
          element: <MenuMgt />,
        },
        {
          path: "/user",
          element: <UserMgt />,
        },
        {
          path: "/trip-map",
          element: <TripMap />,
        },
        {
          path: "/trip-plan",
          element: <TripPlan />,
        },
        {
          path: "/trip-map-2",
          element: <TripMap2 />,
        },
        {
          path: "/khung-gio",
          element: <KhungGio />,
        },
        {
          path: "/tai-xe",
          element: <Driver />,
        },
        {
          path: "/xe",
          element: <Car />,
        },
        {
          path: "/trip-record",
          element: <TripRecordList />,
        },
        {
          path: "/revenue",
          element: <RevenueList />,
        },
        {
          path: "/call-history",
          element: <CallHistoryList />,
        },
        {
          path: "/ve-thang",
          element: <VeThangList />,
        },
        {
          path: "/salary",
          element: <DriverSalaryList />,
        },
      ],
    },
  ]);
  return element;
};

export default Route;
