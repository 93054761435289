import axiosClient from "../core/axiosClient";

const userMgtApi = {
  searchDefaultCodeList: () => {
    const url = "/api/uiAdm003/searchDefaultCodeListUserManagement";
    return axiosClient.post(url);
  },

  searchUserList: (params) => {
    const url = "/api/uiAdm003/searchUserListUserManagement";
    return axiosClient.post(url, params);
  },

  searchUsrInfo: (params) => {
    const url = "/api/uiAdm003/searchUsrInfo";
    return axiosClient.post(url, params);
  },

  updateUsrInfo: (params) => {
    const url = "/api/uiAdm003/manageUsrInfo";
    return axiosClient.post(url, params);
  },

  deleteUsrInfo: (params) => {
    const url = "/api/uiAdm003/deleteUsrInfo";
    return axiosClient.post(url, params);
  },

  validUserCode: (params) => {
    const url = "/api/uiAdm003/checkUserCode";
    return axiosClient.post(url, params);
  },

  validUserId: (params) => {
    const url = "/api/uiAdm003/checkUserId";
    return axiosClient.post(url, params);
  },
};

export default userMgtApi;
