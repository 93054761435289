// import "./voip24hlibrary.min.js";
// import "./voip24hgateway.min.js";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import store from "./app/store";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import "./i18n.js";
import $ from "jquery";
import { HelmetProvider, Helmet } from "react-helmet-async";
const helmetContext = {};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider context={helmetContext}>
    <Helmet>
      {/* <script
        type="text/javascript"
        async
        defer
        src="https://sipgetway.voip24h.vn/public/js/voip24hlibrary.min.js"
      ></script>
      <script
        type="text/javascript"
        async
        defer
        src="https://sipgetway.voip24h.vn/public/js/voip24hgateway.min.js"
      ></script> */}
    </Helmet>
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </HelmetProvider>
);
