import { useSelector } from "react-redux";
import { Button } from "antd";
import { PhoneFilled } from "@ant-design/icons";

function IncomingCall() {
  const { status, phoneNumber, hangup, acceptCall } = useSelector((state) => state.calling);

  const answer = () => {
    window.omiSDK.acceptCall();
  };

  const reject = () => {
    window.omiSDK.stopCall();
  };
  return (
    <div
      className={
        (status === "inbound" && !hangup && !acceptCall ? "" : "hidden ") +
        "pb-2 shadow-xl rounded-md bg-gradient-to-b from-blue-500 to-cyan-500 w-72 h-44"
      }
    >
      <div className="p-2 text-white bg-blue-600 px-2 rounded-t-md font-bold  w-full">
        <p className="m-0">Cuộc gọi đến</p>
      </div>
      <div className="w-full mt-4 flex">
        <p className="text-2xl text-white w-full text-center font-semibold">{phoneNumber}</p>
      </div>
      <div className="flex w-full justify-center">
        <Button
          onClick={answer}
          shape="circle"
          icon={<PhoneFilled style={{ fontSize: "20px", color: "#ffffff" }} />}
          className="!bg-[#63d089] !h-12 !w-12"
          size="large"
        />
        <Button
          onClick={reject}
          shape="circle"
          className="!bg-[#dc4848] !ml-28 !h-12 !w-12"
          icon={<PhoneFilled rotate={225} style={{ fontSize: "20px", color: "#ffffff" }} />}
          size="large"
        />
      </div>
    </div>
  );
}

export default IncomingCall;
