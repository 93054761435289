const { default: axiosClient } = require("core/axiosClient");

const popupApi = {
  searchAllUserWithCd: (params) => {
    const url = "/api/selectAllListUsrWithCoCd";
    return axiosClient.post(url, { params });
  },
  getListPONoBySupCd: (params) => {
    const url = "/api/purchaseOrderPopup/getListPONoBySupCd";
    return axiosClient.get(url, { params });
  },
  getDataGridByListPoNo: (params) => {
    const url = "/api/getDataGridByListPoNo";
    return axiosClient.get(url, { params });
  },
  getListMaterial: (params) => {
    const url = "/inventoryHistory/getListMaterial";
    return axiosClient.get(url, { params });
  },

  getListMaterialFilter: (params) => {
    const url = "/inventoryHistory/getListMaterialByIdAndName";
    return axiosClient.get(url, { params });
  },

  countMaterial: (params) => {
    const url = "/inventoryHistory/countMaterial";
    return axiosClient.get(url, { params });
  },
  getListPONoByMtrl: (params) => {
    const url = "/api/purchaseOrderPopup/getListPONoByMtrl";
    return axiosClient.get(url, { params });
  },
};

export default popupApi;
