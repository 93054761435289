import { message, Modal, Button, Row, Col } from "antd";
import React, { useState, useEffect } from "react";
import TripRecordListTable from "pages/TripRecordList/TripRecordListTable";
import "./CallHistoryPopup.scss";
import callApi from "api/Call/callApi";
import TripMapPopup from "pages/TripMapPopup/TripMapPopup";
import CallTable from "components/callPopup/CallTable";

const { confirm } = Modal;

function CallHistoryPopup({ isOpen, onClose, data }) {
  const [loading, setLoading] = useState();
  const [dataSource, setDataSource] = useState();

  const [dataTripMapPopup, setDataTripMapPopup] = useState();
  const [isOpenTripMapPopup, setIsOpenTripMapPopup] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (data) {
        getCallHistory({ callNumber: data.cusId });
      } else {
        getCallHistory();
      }
    }
  }, [isOpen]);

  const getCallHistory = async (values) => {
    try {
      setLoading(true);
      const result = await callApi.getCallHistory(values);
      if (result.success) {
        setLoading(false);
        setDataSource(result.data);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const onNew = () => {
    setDataTripMapPopup({ cusId: dataSource[0].cusId, name: dataSource[0].name, status: "NEW" });
    setIsOpenTripMapPopup(true);
  };

  const onCloseTripMapPopup = () => {
    setIsOpenTripMapPopup(false);
  };

  const handleClose = () => {
    onClose(true);
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        className="call-history-popup"
        onCancel={() => {
          handleClose();
        }}
        title={
          <div className="flex justify-between">
            <div className="flex">
              <p className="text-lg mb-0 mt-1">SĐT</p>
              <p className="text-lg text-blue-500 ml-4 mb-0 mt-1">{data ? data.cusId : ""}</p>
            </div>

            <div className="flex">
              <Button
                className="button btn_custom w-24"
                type="primary"
                onClick={onNew}
                size="medium"
              >
                Tạo mới
              </Button>
              <Button
                danger
                className="button btn_custom w-20"
                type="primary"
                onClick={onClose}
                size="medium"
              >
                Hủy
              </Button>
            </div>
          </div>
        }
        closable={false}
        footer={null}
        width={"90%"}
        open={isOpen}
      >
        <div className="flex h-full">
          <Row>
            <Col className="table-inventory-his">
              <CallTable dataTable={dataSource} loading={loading} scroll={1000} />
            </Col>
          </Row>
        </div>
      </Modal>
      <TripMapPopup
        data={dataTripMapPopup}
        isOpen={isOpenTripMapPopup}
        onClose={onCloseTripMapPopup}
      ></TripMapPopup>
    </>
  );
}

export default CallHistoryPopup;
