import { createAsyncThunk } from "@reduxjs/toolkit";
import menuMgtApi from "api/menuMgtApi";

const searchDataForMenu = createAsyncThunk(
  "menuMgt/searchDataForMenu",
  async (params, { rejectWithValue }) => {
    try {
      const result = await menuMgtApi.searchDataForMenu();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const searchButtonForMenu = createAsyncThunk(
  "menuMgt/searchButtonForMenu",
  async (params, { rejectWithValue }) => {
    try {
      const result = await menuMgtApi.searchButtonForMenu(params);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const searchButtonList = createAsyncThunk(
  "menuMgt/searchButtonList",
  async (params, { rejectWithValue }) => {
    try {
      const result = await menuMgtApi.searchButtonList();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const searchGeneralList = createAsyncThunk(
  "menuMgt/searchGeneralList",
  async (params, { rejectWithValue }) => {
    try {
      const result = await menuMgtApi.searchItemList({ params: { type: "General" } });
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const searchGridList = createAsyncThunk(
  "menuMgt/searchGridList",
  async (params, { rejectWithValue }) => {
    try {
      const result = await menuMgtApi.searchItemList({ params: { type: "Grid" } });
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createMenu = createAsyncThunk("menuMgt/createMenu", async (params, { rejectWithValue }) => {
  try {
    const data = await menuMgtApi.createMenu(params);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const updateMenu = createAsyncThunk("menuMgt/updateMenu", async (params, { rejectWithValue }) => {
  try {
    const data = await menuMgtApi.updateMenu(params);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const deleteMenu = createAsyncThunk("menuMgt/deleteMenu", async (params, { rejectWithValue }) => {
  try {
    const data = await menuMgtApi.deleteMenu(params);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export {
  searchDataForMenu,
  searchButtonForMenu,
  searchButtonList,
  searchGeneralList,
  searchGridList,
  createMenu,
  updateMenu,
  deleteMenu,
};
