export const vPgeSize = 12;
export const TableButtonDefineColumn = [
  {
    title: "Button Name",
    dataIndex: "btnNm",
  },
];

export const TableGeneralItmColumn = [
  {
    title: "Component Id",
    dataIndex: "itmNo",
  },
  {
    title: "Component Name",
    dataIndex: "itmNm",
  },
  {
    title: "Component Type",
    dataIndex: "itmTpNm",
  },
];

export const TableGridColumn = [
  {
    title: "Grid Id",
    dataIndex: "itmNo",
  },
  {
    title: "Column Id",
    dataIndex: "itmNm",
  },
  {
    title: "Column Name",
    dataIndex: "itmTpNm",
  },
];
