import React from "react";
import { useSelector } from "react-redux";
const AnotherCall = () => {
  const { acceptCallByOther, phoneNumberOther, userAccept } = useSelector((state) => state.calling);

  return (
    <div
      className={
        (acceptCallByOther ? "" : "hidden ") +
        "pb-2 shadow-xl rounded-md w-80 h-36 bg-gradient-to-b from-blue-500 to-cyan-500"
      }
    >
      <div className="py-8">
        <div className="flex justify-center">
          <p className="text-xl mr-2 font-bold text-[#ffffff] text-center">{userAccept}</p>
          <p className="text-xl text-[#ffffff] text-center">đang tư vấn</p>
        </div>
        <div className="flex justify-center">
          <p className="text-xl mr-2 text-[#ffffff] text-center">Số điện thoại</p>
          <p className="text-xl font-bold text-[#ffffff] text-center">{phoneNumberOther}</p>
        </div>
      </div>
    </div>
  );
};

export default AnotherCall;
