import React, { useEffect, useState } from "react";
import { Button, Form, Input, Alert, Checkbox } from "antd";
import "./styles/Login.scss";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "redux/userAction";
import { useNavigate } from "react-router-dom";
import { loginBroadcast } from "core/Broadcast";
import { useTranslation } from "react-i18next";
import backGround from "../../assets/images/icon/Frame.png";
import TokenService from "utils/TokenService";
import { resetCallData } from "redux/callingSlice.js";

function Login() {
  const { token, loading, error } = useSelector((state) => state.user);
  const [lang, setLang] = useState("vi");
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);

  useEffect(() => {
    loginBroadcast.onmessage = () => {
      window.location.reload();
    };
  }, []);

  const onFinish = (values) => {
    const { username, password, remember } = values;
    TokenService.setUsrId(form.getFieldValue("username"));
    dispatch(userLogin({ username, password, remember }));
    dispatch(resetCallData());
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  const handleSelectLang = (event) => {
    setLang(event.target.value);
  };

  return (
    <>
      <div className="container-login">
        <div className="wrap-image">
          <img src={backGround} alt="" />
        </div>

        <div className="wrap-login">
          <div className="login">
            <div className="login-control">
              <div className="title">
                <h1>Vũ Gia</h1>
              </div>
              <div className="sub-title">
                <span>{t("login.title")}</span>
              </div>
              <div className="login-form">
                <div className="wrap-control">
                  <Form
                    form={form}
                    name="normal_login"
                    className="form"
                    layout="vertical"
                    initialValues={{
                      remember: true,
                      username: TokenService.getAccount().username,
                      password: TokenService.getAccount().password,
                    }}
                    onFinish={onFinish}
                  >
                    <Form.Item
                      label={t("login.user")}
                      required
                      name="username"
                      className="control"
                      rules={[
                        {
                          required: true,
                          message: `${t("login.user_validate")}`,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label={t("login.pass")}
                      required
                      name="password"
                      className="control"
                      rules={[
                        {
                          required: true,
                          message: `${t("login.pass_validate")}`,
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      name="remember"
                      className="remember"
                      valuePropName="checked"
                      wrapperCol={{ offset: 0, span: 16 }}
                    >
                      <Checkbox>{t("login.remember")}</Checkbox>
                    </Form.Item>
                    {error ? (
                      <Form.Item className="alert">
                        <Alert message={t("login.false")} type="error" showIcon />
                      </Form.Item>
                    ) : null}
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        loading={loading}
                        block
                      >
                        {t("login.login")}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
              <div className="line">
                <hr />
              </div>
              <div className="language">
                <select onChange={handleSelectLang} value={lang}>
                  <option value="vi">Vietnamese(Việt Nam)</option>
                  <option value="en">English(United States)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
