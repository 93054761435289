export const HTTP_REQUEST = {
  HEADERS: {
    AUTHORIZATION: "Authorization",

    CONTENT_TYPE: "Content-Type",
  },

  AUTHORIZATION: {
    BASIC_TYPE: "Basic ",

    BEARER_TYPE: "Bearer ",
  },

  CONTENT_TYPES: {
    JSON: "application/json",

    FORM: "multipart/form-data",
  },

  STATUS_CODES: {
    BAD_REQUEST: 400,

    UNAUTHORIZED: 401,

    FORBIDDEN: 403,

    NOT_FOUND: 404,

    SERVER_ERROR: 500,
  },
};

export const GOOGLE_MAP_API = {
  GEOCODE: "AIzaSyBbmMfOXbl4cKOPhYR2kFbHn8CTkVkUp2w",
  MAP: "AIzaSyBMGClvFxK4XRkWCZV7Csrh_UoYBtDp7hI",
  // MAP: "AIzaSyB8Ekc3I6-cf_jATtZOPNIe4edIpL5ioEY",
  // MAP: "AIzaSyCsmVmeeRzAHCoOeFAE6kc5vrhDXuFZDKw",
};

export const HTTP_ERROR_MESSAGE = {
  400: "Request data went wrong",
  401: "Endpoint requires authentication",
  403: "Forbidden endpoint",
  404: "Endpoint is not found",
  500: "Something went wrongs",
};

export const STATUS_TYPE = {
  SUCCESS: "success",
  ERROR: "error",
  INFO: "info",
  WARNING: "warning",
};

export const NOTIFICATION_COLOR = {
  [STATUS_TYPE.SUCCESS]: "notify-success",
  [STATUS_TYPE.ERROR]: "notify-error",
  [STATUS_TYPE.INFO]: "notify-info",
  [STATUS_TYPE.WARNING]: "notify-warning",
};

export const ASSET_STATUS = {
  S: "In Stock",
  U: "In Used",
};

export const ASSET_STATUS_TAG = {
  S: "magenta",
  U: "volcano",
};

export const PAGE = {
  DEFAULT_ITEM_PER_PAGE: 15,
  DEFAULT_PAGE: 1,
};
export const DATE_YYYYMMDDHHMMSS = "YYYY-MM-DD HH:mm:ss";
export const DATE_YYYYMMDDHHMMSS_FILE = "YYYY_MM_DD-HH_mm_ss";

export const DELETE_SUCCESS = "Delete successfully";
export const SAVE_SUCCESS = "Save successfully";
export const TOKEN = "token";
export const MAIN_PAGE = "Home";
export const KEY_LOCAL_STORE = "user";
export const REMEMBER = "remember";
export const DATA_LOGIN = "login";
export const LIST_MENU = "listMenu";
export const ERROR_API = "Internal Server Error.";
export const USER_INFO = "userInfo";
export const USER_CALL = "userCall";
export const TOKEN_CALL = "tokenCall";
