import { Modal, Button, Table, Form, message, Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { SelectCustom } from "components";
import tripRecordApi from "api/Call/tripRecord";
import { EditFilled, CloseOutlined, PhoneFilled, FileSearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setCallData } from "redux/callingSlice.js";
import TripMapPopup from "pages/TripMapPopup2/TripMapPopup";
import TokenService from "utils/TokenService";
import TripEditHisPopup from "pages/TripEditHisPopup/TripEditHisPopup";
import moment from "moment";

const admin = TokenService.getUserLocalStorage()?.coCd == "CLV";
import "moment/locale/vi";
const { confirm } = Modal;

function TaixePopup({ show, data, driverList, khungGioList, onclose }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [driverCombo, setDriverCombo] = useState([]);
  const [khungGioCombo, setKhungGioCombo] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [loading, setLoading] = useState(false);

  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [dataPopup, setDataPopup] = useState({});

  const [dataEditHis, setDataEditHis] = useState({});
  const [showEditHis, setShowEditHis] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    show && setOpen(show);
    setDataSource([]);
    if (show) {
      form.setFieldsValue({
        tripStatus: "Y",
        driverId: data.driverId,
        khungGioId: data.khungGioId,
      });
      getTripOrders(data);
    }
  }, [show]);

  useEffect(() => {
    if (driverList) {
      const dataParse = [];
      driverList.map((item) =>
        dataParse.push({
          label:
            item["driverName"] + " - " + item["carNumber"] + " - Xe " + item["carType"] + " chổ",
          value: item["driverId"],
        })
      );
      setDriverCombo(dataParse);
    }
  }, [driverList]);

  useEffect(() => {
    if (khungGioList) {
      const dataParse = [];
      khungGioList.map((item) =>
        dataParse.push({
          label: item["khungGioName"],
          value: item["khungGioId"],
        })
      );
      setKhungGioCombo(dataParse);
    }
  }, [khungGioList]);

  const callPhoneNumber = (param) => {
    window.omiSDK.makeCall(param);
  };

  const onCancelTrip = (param) => {
    confirm({
      title: "Bạn có muốn hủy chuyến đi này",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        await tripRecordApi.updateOrderStatus(param);
      },
      onCancel() {
        return;
      },
    });
  };

  const onEdit = (param) => {
    setDataPopup({ ...param, status: "EDIT" });
    setIsOpenDetail(true);
  };

  const onClose = () => {
    setIsOpenDetail(false);
  };
  const getTripOrders = async (params) => {
    setLoading(true);
    const result = await tripRecordApi.getTripOrders(params);

    if (result.success) {
      setTitleLeft(
        "Tổng cộng: " +
          result.data.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.soKhach;
          }, 0) +
          " khách"
      );
      setTitleRight(
        "Lái xe: " + data.driverName + " - Lúc: " + data.khungGioName + " - ngày: " + data.ngayPlan
      );
      setDataSource(result.data);
      setLoading(false);
    }
  };

  const handleClose = () => {
    onclose();
    setOpen(false);
  };

  const onSave = () => {
    setOpen(false);
  };

  const onEditHis = (param) => {
    setDataEditHis(param);
    setShowEditHis(true);
  };

  const onCloseEditHis = () => {
    setShowEditHis(false);
  };

  const onCopy = () => {
    let text = "";
    dataSource.map(
      (item, index) =>
        (text =
          text +
          "\n" +
          `${index + 1}` +
          ". " +
          item.name +
          " - " +
          item.cusId +
          " - SL " +
          item.soKhach +
          " - " +
          `${item.diemDonAdd ? item.diemDonAdd.split(",")[0].trim() : ""}` +
          " - " +
          `${item.diemTraAdd ? item.diemTraAdd.split(",")[0].trim() : ""}` +
          " - " +
          (item.tripType === 1
            ? `${item.soTien}K `
            : item.tripType === 2
            ? `${item.soTien}K `
            : item.tripType === 3
            ? `${item.soTien}K `
            : `${item.soTien * item.soKhach}K `) +
          `${item.tienFlg === 0 ? " - Đã thanh toán " : ""}` +
          `${item.ghiChu ? item.ghiChu : ""}`)
    );

    navigator.clipboard.writeText(text);
    messageApi.open({
      type: "success",
      content: "Sao chép thành công",
    });

    // setOpen(false);
  };

  const configColumn = [
    {
      title: "No.",
      dataIndex: "index",
      align: "center",
      width: "60px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Tên",
      dataIndex: "name",
      align: "left",
      width: "140px",
    },
    {
      title: "SL",
      dataIndex: "soKhach",
      align: "center",
      width: "80px",
      render: (text, row) =>
        row["tripType"] === 1
          ? "Hàng"
          : row["tripType"] === 2
          ? "Bao xe 7 chổ"
          : row["tripType"] === 3
          ? "Bao xe 4 chổ"
          : row["soKhach"],
    },
    {
      title: "Điểm đón",
      dataIndex: "diemDonAdd",
      align: "left",
      width: "350px",
    },
    {
      title: "Điểm trả",
      dataIndex: "diemTraAdd",
      align: "left",
      width: "350px",
    },
    {
      title: "Giá",
      dataIndex: "soTien",
      align: "center",
      width: "80px",
      render: (_, record) =>
        record.tripType === 1
          ? `${record.soTien}K`
          : record.tripType === 2
          ? `${record.soTien}K`
          : record.tripType === 3
          ? `${record.soTien}K`
          : `${record.soTien * record.soKhach}K`,
    },
    {
      title: "Ghi chú",
      dataIndex: "ghiChu",
      align: "left",
      render: (_, record) => (
        <div className="w-full h-full pl-2 p-1">
          <p className="text-red-500 mb-1 ">{record.tienFlg == 0 ? "Đã Thanh Toán" : ""}</p>
          <p className="mb-1">{record.ghiChu}</p>
        </div>
      ),
    },
  ];

  function transformColumns(columns) {
    return columns.map((column) => {
      if (column.dataIndex === "name") {
        return {
          ...column,
          render: (_, record) => (
            <div className="bg-blue-500 w-full h-full pl-2 p-1">
              <p className="text-white mb-1">{record.name}</p>
              <p className="text-white mb-1">{record.cusId}</p>
              {/* when hover */}
              <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center opacity-0 hover:opacity-100">
                <div className="flex justify-end w-full h-full">
                  <Tooltip title="Gọi" color={"#ffae42"} key={"ffae42"}>
                    <Button
                      icon={<PhoneFilled style={{ color: "#ffae42" }} />}
                      onClick={() => {
                        callPhoneNumber(record.cusId);
                      }}
                      size="small"
                      className="!ml-2 !rounded-md"
                    />
                  </Tooltip>
                  <Tooltip title="Lịch sử chỉnh sửa" color={"#47dded"} key={"47dded"}>
                    <Button
                      icon={<FileSearchOutlined style={{ color: "#47dded" }} />}
                      onClick={() => onEditHis(record)}
                      size="small"
                      className="!ml-2 !rounded-md"
                    />
                  </Tooltip>
                  <Tooltip title="Chỉnh sửa" color={"purple"} key={"47dded"}>
                    <Button
                      icon={
                        <EditFilled
                          style={
                            !moment()
                              .add(-90, "minutes")
                              .isAfter(
                                moment(record.ngayDi + record.khungGioName, "DD/MM/YYYYHH:mm")
                              ) || admin
                              ? { color: "purple" }
                              : { color: "gray" }
                          }
                        />
                      }
                      disabled={
                        moment()
                          .add(-90, "minutes")
                          .isAfter(
                            moment(record.ngayDi + record.khungGioName, "DD/MM/YYYYHH:mm")
                          ) && !admin
                      }
                      onClick={() => onEdit(record)}
                      size="small"
                      className="!ml-2 !rounded-md"
                    />
                  </Tooltip>
                  <Tooltip title="Hủy Chuyến" color={"red"} key={"47dded"}>
                    <Button
                      icon={
                        <CloseOutlined
                          style={
                            !moment()
                              .add(-90, "minutes")
                              .isAfter(
                                moment(record.ngayDi + record.khungGioName, "DD/MM/YYYYHH:mm")
                              ) || admin
                              ? { color: "red" }
                              : { color: "gray" }
                          }
                        />
                      }
                      onClick={() => onCancelTrip(record)}
                      disabled={
                        moment()
                          .add(-90, "minutes")
                          .isAfter(
                            moment(record.ngayDi + record.khungGioName, "DD/MM/YYYYHH:mm")
                          ) && !admin
                      }
                      size="small"
                      className="!ml-2 !rounded-md"
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          ),
        };
      } else {
        return column;
      }
    });
  }
  const onFinish = () => {
    // khungGioApi.saveKhungGio(form.getFieldValue());
  };

  return (
    <Modal
      destroyOnClose={true}
      onCancel={handleClose}
      // title={`Chuyến đi ngày  ${data.date}`}
      title={
        <div className="flex justify-between">
          Chuyến đi ngày {data.date}
          <div className="flex">
            <Button key="copy" type="primary" onClick={onCopy}>
              Sao chép
            </Button>
            <Button
              key="add"
              type="primary"
              className="!bg-orange-500 !border-orange-500 w-24"
              onClick={onSave}
            >
              Lưu
            </Button>
            <Button key="close" type="primary" danger className=" w-24" onClick={handleClose}>
              Đóng
            </Button>
          </div>
        </div>
      }
      style={{ top: 20 }}
      open={open}
      closable={false}
      footer={null}
      width={"65%"}
    >
      <Spin spinning={loading}>
        {contextHolder}
        <div>
          <Form
            colon={false}
            onFinish={onFinish}
            form={form}
            className="form-control-inventory-his"
          >
            <div className="mb-4">
              <Form.Item name="driverId">
                <SelectCustom
                  label="Lái xe"
                  require="true"
                  options={driverCombo}
                  showSearch
                  allowClear
                ></SelectCustom>
              </Form.Item>
            </div>
            <div>
              <Form.Item name="khungGioId">
                <SelectCustom
                  label="Giờ đi"
                  require="true"
                  options={khungGioCombo}
                  showSearch
                  allowClear
                ></SelectCustom>
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className="flex justify-between p-4">
          <div className="text-lg">{titleLeft}</div>
          <div className="text-lg">{titleRight}</div>
        </div>
        <div>
          <Table
            dataSource={dataSource}
            columns={transformColumns(configColumn)}
            rowKey={() => Math.floor(Math.random() * 1000000).toString()}
            pagination={false}
            size="small"
            scroll={{
              y: 560,
            }}
          ></Table>
        </div>
      </Spin>
      <TripMapPopup data={dataPopup} isOpen={isOpenDetail} onClose={onClose}></TripMapPopup>
      <TripEditHisPopup
        isOpen={showEditHis}
        data={dataEditHis}
        onClose={onCloseEditHis}
      ></TripEditHisPopup>
    </Modal>
  );
}

export default TaixePopup;
