import React, { useState } from "react";
import "./TripRecordList.scss";
import TripRecordListForm from "./TripRecordListForm";
import TripRecordListTable from "./TripRecordListTable";

function TripRecordList() {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);

  const onData = (value) => {
    setDataTable(value);
  };

  const onLoading = (value) => {
    setLoading(value);
  };

  return (
    <div>
      <TripRecordListForm onData={onData} onLoading={onLoading} />
      <TripRecordListTable loading={loading} dataTable={dataTable} />
    </div>
  );
}

export default TripRecordList;
