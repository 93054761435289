import axiosClient from "core/axiosClient";

const userApi = {
  login: (params) => {
    const url = "/api/auth/login";
    return axiosClient.post(url, params, { ignoreInterceptor: true });
  },

  refreshToken: (token) => {
    const url = "/api/auth/refreshToken";
    return axiosClient.post(url, token, { ignoreInterceptor: true });
  },

  test: (params) => {
    const url = "/";
    return axiosClient.get(url, { params });
  },
};

export default userApi;
