import { Tabs, Col, Button, Spin } from "antd";
import { FolderAddOutlined, DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableCheckDetailUI from "./TableCheckDetailUI";
import ItemPopup from "./popup/ItemPopup";
import { TableButtonDefineColumn, TableGeneralItmColumn, TableGridColumn } from "./DefineConst";
import { actionMenuMgt } from "redux/system/menuMgtSlice";

function ItemTabs() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [lsCheck, setLsCheck] = useState([]);
  const [configColumn, setConfigColumn] = useState(TableButtonDefineColumn);
  const [isShow, setIsShow] = useState(false);
  const [keyTab, setKeyTab] = useState("button");

  const {
    lsButton,
    lsGeneral,
    lsGrid,
    lsButtonAdd,
    lsGeneralAdd,
    lsGridAdd,
    menuVO,
    loadingDetail,
    loadingSave,
  } = useSelector((state) => state.menuMgt);

  useEffect(() => {
    if (menuVO.menuTp === 0) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  }, [menuVO]);

  const handleShowPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleRemoveItem = () => {
    let originalData = [];
    let newData = [];
    switch (keyTab) {
      case "button":
        originalData = lsButton;
        newData = lsButtonAdd;
        break;
      case "general":
        originalData = lsGeneral;
        newData = lsGeneralAdd;
        break;
      case "grid":
        originalData = lsGrid;
        newData = lsGridAdd;
        break;
      default:
        break;
    }

    const filteredNewData = newData.filter((val) => !lsCheck.includes(val));
    switch (keyTab) {
      case "button":
        dispatch(actionMenuMgt.storeLsButton(originalData));
        dispatch(actionMenuMgt.storeLsButtonAdd(filteredNewData));
        break;
      case "general":
        dispatch(actionMenuMgt.storeLsGeneral(originalData));
        dispatch(actionMenuMgt.storeLsGeneralAdd(filteredNewData));
        break;
      case "grid":
        dispatch(actionMenuMgt.storeLsGrid(originalData));
        dispatch(actionMenuMgt.storeLsGridAdd(filteredNewData));
        break;
      default:
        break;
    }
  };
  function handleDataChecked(checked, lsCheck, setLsCheck) {
    const newCheckedItems = [...lsCheck, ...checked];
    setLsCheck(newCheckedItems.filter((item) => checked.includes(item)));
  }
  const onChangeTabs = (key) => {
    setKeyTab(key);

    switch (key) {
      case "button":
        setConfigColumn(TableButtonDefineColumn);
        break;
      case "general":
        setConfigColumn(TableGeneralItmColumn);
        break;
      case "grid":
        setConfigColumn(TableGridColumn);
        break;
    }
  };
  return (
    <Col className="right-box item-tabs" flex="auto">
      <Spin spinning={loadingDetail || loadingSave}>
        <Tabs
          onChange={onChangeTabs}
          items={[
            {
              label: "Button",
              key: "button",
              children: (
                <TableCheckDetailUI
                  pData={lsButtonAdd}
                  pConfigColumn={TableButtonDefineColumn}
                  onDataChecked={(checked) => handleDataChecked(checked, lsCheck, setLsCheck)}
                  pKey="btnNo"
                />
              ),
            },
            {
              label: "General Itm",
              key: "general",
              children: (
                <TableCheckDetailUI
                  pData={lsGeneralAdd}
                  pConfigColumn={TableGeneralItmColumn}
                  onDataChecked={(checked) => handleDataChecked(checked, lsCheck, setLsCheck)}
                  pKey="itmNo"
                ></TableCheckDetailUI>
              ),
            },
            {
              label: "Grid",
              key: "grid",
              children: (
                <TableCheckDetailUI
                  pData={lsGridAdd}
                  pConfigColumn={TableGridColumn}
                  onDataChecked={(checked) => handleDataChecked(checked, lsCheck, setLsCheck)}
                  pKey="itmNo"
                ></TableCheckDetailUI>
              ),
            },
          ]}
        />
        <div className="control-itemTabs">
          <Button
            className="btn-left"
            type="primary"
            icon={<FolderAddOutlined />}
            hidden={isShow}
            onClick={handleShowPopup}
          >
            {t("buttons.add")}
          </Button>
          <Button
            type="primary"
            icon={<DeleteOutlined />}
            hidden={isShow}
            onClick={handleRemoveItem}
          >
            {t("buttons.remove")}
          </Button>
          <ItemPopup
            show={showPopup}
            keyTab={keyTab}
            configColumn={configColumn}
            onclose={handleClosePopup}
          ></ItemPopup>
        </div>
      </Spin>
    </Col>
  );
}

export default ItemTabs;
