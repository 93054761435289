import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

function SearchForm({ onChangeNameSearch }) {
  const { t } = useTranslation();
  const handleChange = (event) => {
    onChangeNameSearch(event.target.value);
  };

  return (
    <div className="search-box form-control-menu-mgt">
      <Form className="menu-form" colon={false}>
        <Form.Item name="menuName">
          <Input
            className="input"
            placeholder={t("Name")}
            style={{ width: 300, height: 40 }}
            onChange={handleChange}
          ></Input>
        </Form.Item>
      </Form>
    </div>
  );
}

export default SearchForm;
