import { Button, Col, Form, Row } from "antd";
import { SearchOutlined, ExportOutlined } from "@ant-design/icons";
import { InputCustom } from "components";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import DriverDetail from "./popup/DriverDetail";
import moment from "moment";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import driverApi from "api/Call/driverApi";
import "moment/locale/vi";

moment.locale("vi");
dayjs.extend(customParseFormat);

function DriverForm({ onData, onLoading }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loadingExport, setLoadingExport] = useState(false);
  const [isOpenDetail, setIsOpenDetail] = useState(false);

  useEffect(() => {
    onFinish();
  }, []);

  useEffect(() => {
    onFinish();
  }, [isOpenDetail]);

  const onFinish = (values) => {
    let data = {
      ...values,
    };
    loadDataInventoryHistory(data);
  };

  const loadDataInventoryHistory = async (param) => {
    try {
      onLoading(true);
      const result = await driverApi.getDriverManage(param);
      if (result.success) {
        onData(result.data);
      }
    } catch (error) {
      onLoading(false);
    } finally {
      onLoading(false);
    }
  };
  const onClose = () => {
    setIsOpenDetail(false);
  };

  const handleAdd = () => {
    setIsOpenDetail(true);
  };

  return (
    <>
      <Form colon={false} onFinish={onFinish} form={form} className="form-control-inventory-his">
        <div className="flex justify-end">
          <div className="mt-1">
            <Button
              type="primary"
              disabled={false}
              htmlType="submit"
              className="button btn_custom"
              icon={<SearchOutlined />}
            >
              Tìm
            </Button>
            <Button
              type="primary"
              className="button btn_custom"
              icon={<ExportOutlined />}
              onClick={handleAdd}
              loading={loadingExport}
            >
              Thêm mới
            </Button>
          </div>
        </div>
      </Form>
      <DriverDetail
        // data={record}
        isOpen={isOpenDetail}
        onClose={onClose}
      ></DriverDetail>
    </>
  );
}

export default DriverForm;
