import { Link } from "react-router-dom";
import _ from "lodash";
const imagesDefault = "./assets/images/clv-admin.png";

export const buildTreeDataMenu = (data) => {
  const { treeDataMenu } = data.reduce(
    (acc, curr) => {
      if (acc.parentMap[curr.prntId]) {
        (acc.parentMap[curr.prntId].children = acc.parentMap[curr.prntId].children || []).push(
          curr
        );
      } else {
        acc.treeDataMenu.push(curr);
      }
      acc.parentMap[curr.mnuPgmId] = curr;
      return acc;
    },
    { parentMap: {}, treeDataMenu: [] }
  );
  return treeDataMenu;
};

export const convertTreeDataMenuToTypeAntdMenu = (data) => {
  if (!data) return [];
  const antMenuType = data.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.mnuPgmNm]: {
          key: item.mnuPgmId,
          label:
            item.children && item.children.length > 0 ? (
              item.mnuPgmNm
            ) : (
              <Link to={item?.mnuPgmUrl}> {item.mnuPgmNm} </Link>
            ),
          icon:
            item.level == 1 ? (
              <img
                src={item.mnuIco ? "./assets/images/" + item.mnuIco + ".png" : imagesDefault}
                onError={(e) => (e.target.src = imagesDefault)}
                alt={item.mnuPgmNm}
              />
            ) : (
              "./assets/images/trip-plan.png"
            ),
          children:
            item.children && item.children.length > 0
              ? convertTreeDataMenuToTypeAntdMenu(item.children)
              : null,
        },
      }),
    {}
  );
  const items = Object.values(antMenuType);
  return items;
};

export const filterData = (value, data, field) => {
  if (!field) return;
  if (Array.isArray(data) && typeof field == "string" && typeof value == "string") {
    return data.filter((item) =>
      item[field].toString().toLowerCase().includes(value.toLowerCase())
    );
  } else return [];
};

export const parseDataCombobox = (data, labelField, valueField, initArray = []) => {
  if (Array.isArray(data)) {
    data.map((item) =>
      initArray.push({
        label: item[labelField],
        value: item[valueField],
      })
    );
  } else {
    return [];
  }
  return initArray;
};

export const parseDataSelectOptions = (data, labelField, valueField, initArray = []) => {
  if (Array.isArray(data)) {
    data.map((item, i) =>
      initArray.push({
        label: item[labelField],
        value: item[valueField],
        key: item[labelField] + " " + i,
      })
    );
  } else {
    return [];
  }
  return initArray;
};

export const _buildTreeData = (
  listDataTransfer,
  parentId,
  nameFiledParent, // = "prntId"
  nameFiledChild // "mnuPgmId"
) => {
  if (
    _.isNil(nameFiledParent) ||
    _.isElement(nameFiledParent) ||
    _.isNil(nameFiledChild) ||
    _.isElement(nameFiledChild)
  ) {
    throw Error(" Please input nameFiledParent ,nameFiledChild ");
  }
  const lsDataResult = [];
  for (const el of listDataTransfer) {
    if (el[nameFiledParent] == parentId) {
      const data = _buildTreeData(
        listDataTransfer,
        el[nameFiledChild],
        nameFiledParent,
        nameFiledChild
      );
      if (data.length) {
        el.children = data;
      }
      lsDataResult.push(el);
    }
  }
  return lsDataResult;
};

export const _getLsParentByChild = (
  treeData,
  nodeChild,
  filedFind = "mnuPgmId",
  nameFiledParent = "prntId"
) => {
  let lsDataResult = [];
  for (const el of treeData) {
    if (el[filedFind] == nodeChild[nameFiledParent]) {
      lsDataResult.push(el);
      let lsData = _getLsParentByChild(treeData, el, filedFind, nameFiledParent);
      if (!_.isEmpty(lsData)) {
        lsDataResult = [...lsDataResult, ...lsData];
      }
    }
  }
  return lsDataResult;
};

export const _getLsChild = (
  treeData,
  nodeParent,
  filedFind = "mnuPgmId",
  nameFiledParent = "prntId"
) => {
  let lsDataResult = [];
  for (const el of treeData) {
    if (el[nameFiledParent] == nodeParent[filedFind]) {
      lsDataResult.push(el);
      let lsData = _getLsChild(treeData, el, filedFind, nameFiledParent);
      if (!_.isEmpty(lsData)) {
        lsDataResult = [...lsDataResult, ...lsData];
      }
    }
  }
  return lsDataResult;
};

export const _filterTreeByTitle = (array, name) => {
  let arrayV = _.cloneDeep(array);
  const s = (r, { children, ...object }) => {
    if (_.upperCase(object.title).includes(_.upperCase(name))) {
      r.push({ ...object, children: children });
      return r;
    }
    if (children.length > 0) {
      children = children.reduce(s, []);
      if (children.length) r.push({ ...object, children });
    }
    return r;
  };
  return arrayV.reduce(s, []);
};

export const getMaxValueOfArray = (data, fieldCheck) => {
  if (!fieldCheck) throw Error("Please input field argument");
  if (!data) return;
  let tem = [];
  data.map((item) => tem.push(parseInt(item[fieldCheck])));
  return Math.max(...tem);
};

export const getChildByTitle = (array, name) => {
  let temp = [];
  const cb = (array, name) => {
    array.map((item) => {
      if (_.upperCase(item.title).includes(_.upperCase(name))) {
        temp.push(item.cateId.toString());
      }
      if (!_.isEmpty(item.children)) {
        cb(item.children, name);
      }
    });
  };
  cb(array, name);
  return temp;
};

export const getChildByMenuName = (array, name) => {
  let temp = [];
  const cb = (array, name) => {
    array.map((item) => {
      if (_.upperCase(item.mnuPgmNmEn).includes(_.upperCase(name))) {
        temp.push(item.key.toString());
      }
      if (!_.isEmpty(item.children)) {
        cb(item.children, name);
      }
    });
  };
  cb(array, name);
  return temp;
};

export const findNodeName = (array, name) => {
  const cb = (array, name) => {
    return array.map((item) => {
      if (_.upperCase(item.title) === _.upperCase(name)) {
        return true;
      }
      if (!_.isEmpty(item.children)) {
        cb(item.children, name);
      }
    });
  };
  cb(array, name);
};

export const convertDateTimeWithFormatYMD = (date) => {
  const parts = date.split("/");
  const convertedDate = new Date(parts[2], parts[1] - 1, parts[0]);

  const year = convertedDate.getFullYear();
  const month = convertedDate.getMonth() + 1;
  const day = convertedDate.getDate();

  // Tạo định dạng yyyy-MM-dd
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  return formattedDate;
};
