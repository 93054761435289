import { Col, Row, Table, Tooltip, Button, Modal, Tag } from "antd";
import { PAGE } from "core/Constants";
import React, { useEffect, useState } from "react";
import { CarOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";
import useResize from "hook/useResize";
import AddCarPopup from "./popup/AddCarPopup";
import DriverDetail from "./popup/DriverDetail";
import driverApi from "api/Call/driverApi";

const { confirm } = Modal;

function DriverTable({ dataTable, loading, onUpdate }) {
  const [pageIndex, setPageIndex] = useState(PAGE.DEFAULT_PAGE);
  const [indexSelect, setIndexSelect] = useState("");
  const [pageSize, setPageSize] = useState(14);
  const [width, height] = useResize();
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isOpenDriverDetail, setIsOpenDriverDetail] = useState(false);
  const [record, setRecord] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataDriver, setDataDriver] = useState();

  useEffect(() => {
    const resizeHandler = () => {
      const windowHeight = window.innerHeight;
      const tableHeight = windowHeight - 170; // You can adjust this value according to your needs
      const tableElement = document.querySelector(".ant-table-body");
      if (tableElement) {
        tableElement.style.maxHeight = `${tableHeight}px`;
      }
    };
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  useEffect(() => {
    setPageIndex(1);
    setCurrentPage(1);
  }, [dataTable]);

  const getRowIndex = (record, index) => {
    return (pageIndex - 1) * pageSize + index + 1;
  };

  function transformColumns(columns) {
    return columns.map((column) => {
      if (column.title === "No.") {
        return {
          ...column,
          render: (text, object, index) => getRowIndex(object, index),
        };
      } else {
        return column;
      }
    });
  }
  const onRow = (record, index) => {
    return {
      onClick: () => {
        setRecord(record);
        setIndexSelect(index);
      },
    };
  };

  const openAssignPopup = (params) => {
    setDataDriver(params);
    setIsOpenDetail(true);
  };

  const onClose = () => {
    setIsOpenDetail(false);
  };

  const onCloseDetail = () => {
    setIsOpenDriverDetail(false);
    onUpdate();
  };

  const onEdit = (param) => {
    setDataDriver({ ...param, newStatus: false });
    setIsOpenDriverDetail(true);
  };

  const onBlock = (param) => {
    confirm({
      title: "Bạn có chắc chắn muốn khóa tài xế này",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        const data = {
          ...param,
          driverStatus: "N",
        };
        await driverApi.updateDriverStaus(data);
        onUpdate();
      },
      onCancel() {
        return;
      },
    });
  };

  const defineInventoryHis = [
    {
      title: "#",
      dataIndex: "driverId",
      responsive: ["md"],
      width: "60px",
      align: "center",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Họ và Tên",
      dataIndex: "driverFullName",
      responsive: ["md"],
      width: "150px",
      align: "left",
    },
    {
      title: "Tên rút gọn",
      dataIndex: "driverName",
      responsive: ["md"],
      width: "150px",
      align: "left",
    },
    {
      title: "SĐT",
      dataIndex: "driverPhone",
      responsive: ["md"],
      width: "100px",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Địa chỉ email",
      dataIndex: "driverEmail",
      responsive: ["md"],
      width: "150px",
      ellipsis: true,
      align: "left",
    },
    {
      title: "Biển số xe",
      dataIndex: "carNumber",
      responsive: ["md"],
      width: "100px",
      ellipsis: true,
      align: "center",
    },
    {
      title: "CMND",
      dataIndex: "driverIdentify",
      responsive: ["md"],
      width: "100px",
      ellipsis: true,
      align: "left",
    },
    {
      title: "Ngày sinh",
      dataIndex: "driverBirthday",
      responsive: ["md"],
      width: "100px",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Trạng thái",
      dataIndex: "driverStatus",
      responsive: ["md"],
      width: "150px",
      ellipsis: true,
      align: "center",
      render: (text, record, index) =>
        record.driverStatus === "Y" ? (
          <Tag color={"green"} key="Y">
            Đang hoạt động
          </Tag>
        ) : (
          // <div className="text-blue-500">Đang hoạt động </div>
          <Tag color={"volcano"} key="Y">
            Dừng hoạt động
          </Tag>
          // <div className="text-red-500">Dừng hoạt động </div>
        ),
    },
    {
      title: "NVCT",
      dataIndex: "official",
      responsive: ["md"],
      width: "80px",
      ellipsis: true,
      align: "center",
      render: (text, record, index) =>
        record.official === "Y" ? (
          <Tag color={"green"} key="Y">
            Chính thức
          </Tag>
        ) : (
          // <div className="text-blue-500">Đang hoạt động </div>
          <Tag color={"volcano"} key="N">
            Thử việc
          </Tag>
          // <div className="text-red-500">Dừng hoạt động </div>
        ),
    },
    {
      title: "Thao Tác",
      dataIndex: "ttlQty",
      responsive: ["md"],
      width: "90px",
      inputType: "number",
      require: false,
      align: "center",

      render: (_, record) => (
        <div className="flex justify-center">
          <Tooltip title="Gán tài xế" color={"blue"} key={"blue"}>
            <Button
              icon={<CarOutlined style={{ fontSize: "20px", color: "blue" }} />}
              onClick={() => {
                openAssignPopup(record);
              }}
              type="text"
            />
          </Tooltip>
          <Tooltip title="Chỉnh sửa" color={"purple"} key={"purple"}>
            <Button
              icon={<EditOutlined style={{ fontSize: "20px", color: "purple" }} />}
              onClick={() => {
                onEdit(record);
              }}
              type="text"
            />
          </Tooltip>
          <Tooltip title="Khóa" color={"red"} key={"red"}>
            <Button
              icon={<CloseOutlined style={{ fontSize: "20px", color: "red" }} />}
              onClick={() => {
                onBlock(record);
              }}
              type="text"
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="table-driver-list">
        <Table
          columns={transformColumns(defineInventoryHis)}
          dataSource={dataTable}
          rowKey={(record) => record.driverId + Math.floor(Math.random() * 1000000).toString()}
          bordered
          scroll={{ y: 800 }}
          onRow={onRow}
          loading={loading}
          pagination={false}
        />
      </div>
      <AddCarPopup data={dataDriver} isOpen={isOpenDetail} onClose={onClose}></AddCarPopup>
      <DriverDetail
        data={dataDriver}
        isOpen={isOpenDriverDetail}
        onClose={onCloseDetail}
      ></DriverDetail>
    </>
  );
}

export default DriverTable;
