import { createSlice } from "@reduxjs/toolkit";
import { listMenu } from "./menuAction";
const initialState = {
  loading: false,
  error: null,
  success: false,
};
const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    logoutMenu: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: {
    [listMenu.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [listMenu.fulfilled]: (state) => {
      state.success = true;
      state.loading = false;
      state.error = null;
    },
    [listMenu.rejected]: (state, { payload }) => {
      state.error = payload.data;
      state.loading = false;
    },
  },
});
const { reducer, actions } = menuSlice;
export const { logoutMenu } = actions;
export default reducer;
