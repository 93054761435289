import React from "react";

class ThongKeFooter extends React.Component {
  render() {
    const { dataSource, columns } = this.props;
    return (
      <table className="ant-table">
        <colgroup>
          {columns.map((colModel, index) => {
            return (
              <col
                style={{
                  width: colModel.width,
                  minWidth: colModel.width,
                }}
                key={index}
              />
            );
          })}
        </colgroup>
        <tfoot>
          <tr>
            {columns.map((colum, idxCol) => {
              return (
                <td style={{ padding: "0px 0px" }} className={"text-" + colum.align} key={idxCol}>
                  <strong className="text-base">
                    {colum.footerContent
                      ? colum.footerContent
                      : colum.footerSum && dataSource
                      ? dataSource
                          .filter((obj) => {
                            return obj.tripStatus == "Y";
                          })
                          .reduce((sum, record) => sum + parseFloat(record[colum.dataIndex]), 0) +
                        " Ghế"
                      : colum.footerEndfix && dataSource
                      ? dataSource
                          .filter((obj) => {
                            return obj.tripStatus == "Y";
                          })
                          .reduce(
                            (sum, record) =>
                              sum + parseFloat(record[colum.dataIndex] * record["soKhach"]),
                            0
                          ) + "K"
                      : ""}
                  </strong>
                </td>
              );
            })}
          </tr>
        </tfoot>
      </table>
    );
  }
}
export default ThongKeFooter;
