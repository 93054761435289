import React, { useState } from "react";
import "./CallHistoryList.scss";
import CallHistoryListForm from "./CallHistoryListForm";
import CallTable from "components/callPopup/CallTable";

function CallHistoryList() {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);

  const onData = (value) => {
    setDataTable(value);
  };

  const onLoading = (value) => {
    setLoading(value);
  };

  return (
    <div>
      <CallHistoryListForm onData={onData} onLoading={onLoading} loading={loading} />
      <CallTable dataTable={dataTable} loading={loading} scroll={1000} />
    </div>
  );
}

export default CallHistoryList;
