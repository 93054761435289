import { Modal, Button, Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { InputCustom, SelectCustom } from "components";
import callApi from "api/Call/callApi";
import { PAGE } from "core/Constants";
import khungGioApi from "api/Call/khungGioApi";

function KhungGioDetail({ isOpen, onClose, data }) {
  const [pageIndex, setPageIndex] = useState(PAGE.DEFAULT_PAGE);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  const [khungGioList, setKhungGioList] = useState();

  const [form] = Form.useForm();

  function createFullDayArray() {
    const fullDayArray = [];

    // Loop through hours from 0 to 23
    for (let hour = 0; hour < 24; hour++) {
      // Loop through minutes 0 and 30
      for (let minute = 0; minute < 60; minute += 30) {
        // Convert hour and minute to string format "hh:mm"
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");

        // Create the value and label strings
        const value = `${formattedHour}:${formattedMinute}`;
        const label = `${formattedHour}:${formattedMinute}`;

        // Add the object to the array
        fullDayArray.push({ value, label });
      }
    }

    return fullDayArray;
  }

  useEffect(() => {
    if (isOpen) {
      setCurrentPage(1);
      setPageIndex(1);
      loadInventoryHistoryDetail(data);
    }
    setKhungGioList(createFullDayArray());
  }, [isOpen]);

  useEffect(() => {
    loadInventoryHistoryDetail(data);
  }, [data]);

  const loadInventoryHistoryDetail = async (param) => {
    if (param) {
      param.custOrdNo = param?.custOrdNo ? param?.custOrdNo.trim() : null;
      try {
        setLoading(true);
        const result = await callApi.getInventoryHistoryDetail(param);
        setDataSource(result.data);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleOk = () => {
    onFinish();
    onClose(true);
  };

  const handleClose = () => {
    onClose(true);
  };

  const onFinish = () => {
    khungGioApi.saveKhungGio(form.getFieldValue());
  };

  return (
    <>
      <Modal
        className="material-popup"
        onCancel={handleClose}
        onOk={handleOk}
        title="Thêm mới khung giờ"
        width={"30%"}
        open={isOpen}
      >
        <Form colon={false} onFinish={onFinish} form={form} className="form-control-inventory-his">
          <div className="mb-4">
            <Form.Item name="tuyen">
              <SelectCustom
                label="Tuyến"
                require="true"
                options={[
                  { value: "TKDN", label: "Tam Kỳ - Đà Nẵng" },
                  { value: "DNTK", label: "Đà Nẵng - Tam Kỳ" },
                  // { value: "PNDN", label: "Phú Ninh - Đà Nẵng" },
                  // { value: "DNPN", label: "Đà Nẵng - Phú Ninh" },
                ]}
                showSearch
                allowClear
              ></SelectCustom>
            </Form.Item>
          </div>
          <div className="mb-4">
            <Form.Item name="soLuongKhach">
              <InputCustom
                label="Số lương khách"
                placeholdercustom="Material"
                require="true"
                // style={{ width: "280px" }}
              ></InputCustom>
            </Form.Item>
          </div>

          <div className="mb-4">
            <Form.Item name="khungGioName" className="">
              <SelectCustom
                label="Khung giờ"
                require="true"
                options={khungGioList}
                showSearch
                allowClear
              ></SelectCustom>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default KhungGioDetail;
