import { createSlice } from "@reduxjs/toolkit";
import {
  searchButtonForMenu,
  searchDataForMenu,
  searchButtonList,
  searchGeneralList,
  searchGridList,
  createMenu,
  deleteMenu,
  updateMenu,
} from "./menuMgtAction";
const initialState = {
  loadingTree: false,
  errorTree: false,
  menuList: [],
  loadingPopup: false,
  errorPopup: false,
  loadingDetail: false,
  errorDetail: false,
  loadingSave: false,
  successSave: false,
  errorSave: false,
  loadingDelete: false,
  successDelete: false,
  errorDelete: false,

  menuVO: {
    mnuPgmId: "",
    menuTp: "",
    prntId: "",
    mnuPgmOrdNo: "",
    mnuPgmNmEn: "",
    mnuPgmNmVi: "",
    mnuPgmNmKo: "",
    mnuPgmUrl: "",
    mnuPgmRmk: "",
    mnuUsd: "",
  },
  menuVoNew: {
    mnuPgmId: "",
    menuTp: "",
    prntId: "",
    mnuPgmOrdNo: "",
    mnuPgmNmEn: "",
    mnuPgmNmVi: "",
    mnuPgmNmKo: "",
    mnuPgmUrl: "",
    mnuPgmRmk: "",
    mnuUsd: "",
  },
  lsButton: [],
  lsGeneral: [],
  lsGrid: [],
  lsButtonFull: [],
  lsGeneralFull: [],
  lsGridFull: [],
  lsButtonCheck: [],
  lsGeneralCheck: [],
  lsGridCheck: [],
  lsButtonAdd: [],
  lsGeneralAdd: [],
  lsGridAdd: [],
};

const menuMgtSlice = createSlice({
  name: "menuMgt",
  initialState,
  reducers: {
    storeMenuVO: (state, { payload }) => {
      state.menuVO = payload;
    },
    storeMenuVoNew: (state, { payload }) => {
      state.menuVoNew = payload;
    },
    storeLsButton: (state, { payload }) => {
      state.lsButton = payload;
    },
    storeLsGeneral: (state, { payload }) => {
      state.lsGeneral = payload;
    },
    storeLsGrid: (state, { payload }) => {
      state.lsGrid = payload;
    },
    storeLsButtonAdd: (state, { payload }) => {
      state.lsButtonAdd = payload;
    },

    storeLsGeneralAdd: (state, { payload }) => {
      state.lsGeneralAdd = payload;
    },

    storeLsGridAdd: (state, { payload }) => {
      state.lsGridAdd = payload;
    },
    storeLsButtonCheck: (state, { payload }) => {
      state.lsButtonCheck = payload;
    },

    storeLsGeneralCheck: (state, { payload }) => {
      state.lsGeneralCheck = payload;
    },

    storeLsGridCheck: (state, { payload }) => {
      state.lsGridCheck = payload;
    },
  },
  extraReducers: {
    [searchDataForMenu.pending]: (state) => {
      state.loadingTree = true;
      state.errorTree = null;
    },
    [searchDataForMenu.fulfilled]: (state, { payload }) => {
      state.menuList = payload.data;
      state.loadingTree = false;
      state.errorTree = null;
    },
    [searchDataForMenu.rejected]: (state, { payload }) => {
      state.loadingTree = true;
      state.error = payload;
    },
    [searchButtonForMenu.pending]: (state) => {
      state.loadingDetail = true;
      state.errorDetail = null;
    },
    [searchButtonForMenu.fulfilled]: (state, { payload }) => {
      state.lsButton = payload.data.listBnt;
      state.lsGeneral = payload.data.listGeneral;
      state.lsGrid = payload.data.listGrid;
      state.lsButtonAdd = payload.data.listBnt;
      state.lsGeneralAdd = payload.data.listGeneral;
      state.lsGridAdd = payload.data.listGrid;
      state.loadingDetail = false;
      state.errorDetail = null;
    },
    [searchButtonForMenu.rejected]: (state, { payload }) => {
      state.loadingDetail = true;
      state.errorDetail = payload;
    },
    [searchButtonList.pending]: (state) => {
      state.loadingPopup = true;
      state.errorPopup = null;
    },
    [searchButtonList.fulfilled]: (state, { payload }) => {
      state.lsButtonFull = payload.data;
      state.loadingPopup = false;
      state.errorPopup = true;
    },
    [searchButtonList.rejected]: (state, { payload }) => {
      state.loadingPopup = true;
      state.errorPopup = payload;
    },
    [searchGeneralList.pending]: (state) => {
      state.loadingPopup = true;
      state.errorPopup = null;
    },
    [searchGeneralList.fulfilled]: (state, { payload }) => {
      state.lsGeneralFull = payload.data;
      state.loadingPopup = false;
      state.errorPopup = true;
    },
    [searchGeneralList.rejected]: (state, { payload }) => {
      state.loadingPopup = true;
      state.errorPopup = payload;
    },
    [searchGridList.pending]: (state) => {
      state.loadingPopup = true;
      state.errorPopup = null;
    },
    [searchGridList.fulfilled]: (state, { payload }) => {
      state.lsGridFull = payload.data;
      state.loadingPopup = false;
      state.errorPopup = true;
    },
    [searchGridList.rejected]: (state, { payload }) => {
      state.loadingPopup = true;
      state.errorPopup = payload;
    },
    [createMenu.pending]: (state) => {
      state.loadingSave = true;
      state.errorSave = null;
    },
    [createMenu.fulfilled]: (state, { payload }) => {
      state.loadingSave = false;
      state.successSave = true;
      state.dataSave = payload.data;
      state.errorSave = null;
    },
    [createMenu.rejected]: (state) => {
      state.loadingSave = false;
      state.errorSave = true;
      state.successSave = false;
    },
    [updateMenu.pending]: (state) => {
      state.loadingSave = true;
      state.errorSave = null;
    },
    [updateMenu.fulfilled]: (state, { payload }) => {
      state.loadingSave = false;
      state.successSave = true;
      state.dataSave = payload.data;
    },
    [updateMenu.rejected]: (state) => {
      state.loadingSave = false;
      state.successSave = false;
      state.errorSave = true;
    },
    [deleteMenu.pending]: (state) => {
      state.loadingDelete = true;
      state.errorDelete = null;
    },
    [deleteMenu.fulfilled]: (state) => {
      state.loadingDelete = false;
      state.successDelete = true;
      state.errorDelete = null;
    },
    [deleteMenu.rejected]: (state, { payload }) => {
      state.errorDelete = payload.data;
      state.loadingDelete = false;
      state.errorDelete = null;
    },
  },
});

const { reducer, actions } = menuMgtSlice;
export { actions as actionMenuMgt };
export default reducer;
