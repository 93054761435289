import { createAsyncThunk } from "@reduxjs/toolkit";
import menuApi from "api/menuApi";
import { buildTreeDataMenu } from "utils/common";
import TokenService from "utils/TokenService";

const listMenu = createAsyncThunk("menu", async (params, { rejectWithValue }) => {
  try {
    const res = await menuApi.listMenu();
    const treeData = buildTreeDataMenu(res.data);
    TokenService.setListMenuLocalStorage(treeData);
    return treeData;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export { listMenu };
