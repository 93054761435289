import axiosClient from "core/axiosClient";
const callApi = {
  getCallHistory: (params) => {
    const url = "/api/call-history/getCallHistory";
    return axiosClient.post(url, params);
  },
  saveCallHistory: (params) => {
    const url = "/api/call-history/saveCallHistory";
    return axiosClient.post(url, params);
  },
};
export default callApi;
