import { Col, Row, Table, Button, Tooltip, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { EditOutlined, SnippetsOutlined, PieChartOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import tripRecordApi from "api/Call/tripRecord";
import TripHistoryPopup from "pages/TripHistoryPopup/TripHistoryPopup";
import ThongKePopup from "pages/ThongKePopup/ThongKePopup";
import "moment/locale/vi";

moment.locale("vi");

function VeThangListTable({ onDataEdit, dataTable, loading, setOnNewFunc }) {
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const [historyPopupData, setHistoryPopupData] = useState({});

  const [isOpenThongKe, setIsOpenThongKe] = useState(false);
  const [thongKePopupData, setThongKePopupData] = useState({});

  const [cusId, setCusId] = useState("");

  useEffect(() => {
    const resizeHandler = () => {
      const windowHeight = window.innerHeight;
      const tableHeight = windowHeight - 170; // You can adjust this value according to your needs
      const tableElement = document.querySelector(".ant-table-body");
      if (tableElement) {
        tableElement.style.maxHeight = `${tableHeight}px`;
      }
    };
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  const onEdit = (param) => {
    onDataEdit(param);
    setOnNewFunc(true);
  };

  const onCheckHistory = (param) => {
    setHistoryPopupData({ cusId: param.cusId });
    setCusId(param.cusId);
    setIsOpenHistory(true);
  };

  const onThongKe = (param) => {
    setThongKePopupData(param);
    setIsOpenThongKe(true);
  };

  const onCancelTrip = (param) => {
    tripRecordApi.updateOrderStatus(param);
  };

  const onCloseHistory = () => {
    setIsOpenHistory(false);
  };

  const onCloseThongKe = () => {
    setIsOpenThongKe(false);
  };

  const defineInventoryHis = [
    {
      title: "No.",
      dataIndex: "index",
      align: "center",
      width: "60px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Tên",
      dataIndex: "cusName",
      align: "left",
      width: "130px",
    },
    {
      title: "SĐT",
      dataIndex: "cusId",
      align: "left",
      width: "100px",
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "startDate",
      align: "center",
      width: "100px",
    },
    {
      title: "Ngày kết thúc",
      dataIndex: "endDate",
      align: "center",
      width: "100px",
      render: (_, record) => (record.endDate ? record.endDate : "-"),
    },
    {
      title: "Trạng thái",
      dataIndex: "tickStatus",
      responsive: ["md"],
      width: "150px",
      ellipsis: true,
      align: "center",
      render: (_, record) =>
        record.tickStatus === "Y" ? (
          <Tag color={"green"} key="Y">
            Đang hoạt động
          </Tag>
        ) : (
          <Tag color={"volcano"} key="Y">
            Dừng hoạt động
          </Tag>
        ),
    },

    {
      title: "Ghi chú",
      dataIndex: "remark",
      align: "left",
      width: "200px",
    },
    {
      title: "Thao Tác",
      dataIndex: "ttlQty",
      responsive: ["md"],
      width: "120px",
      inputType: "number",
      require: false,
      align: "center",
      render: (_, record) => (
        <div className="flex justify-between">
          <Tooltip title="Thống kê" color={"orange"} key={"orange"}>
            <Button
              icon={<PieChartOutlined style={{ fontSize: "20px", color: "orange" }} />}
              onClick={() => {
                onThongKe(record);
              }}
              type="text"
              size="small"
            />
          </Tooltip>
          <Tooltip title="Kiểm tra vé" color={"green"} key={"green2"}>
            <Button
              icon={<SnippetsOutlined style={{ fontSize: "20px", color: "#6aa84f" }} />}
              onClick={() => {
                onCheckHistory(record);
              }}
              type="text"
              size="small"
            />
          </Tooltip>
          <Tooltip title="Chỉnh sửa" color={"purple"} key={"purple"}>
            <Button
              icon={<EditOutlined style={{ fontSize: "20px", color: "purple" }} />}
              onClick={() => {
                onEdit(record);
              }}
              type="text"
              size="small"
            />
          </Tooltip>
          <Tooltip title="Hủy" color={"red"} key={"red"}>
            <Button
              icon={<CloseOutlined style={{ fontSize: "20px", color: "red" }} />}
              onClick={() => {
                onCancelTrip(record);
              }}
              type="text"
              size="small"
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col className="table-ve-thang">
          <Table
            columns={defineInventoryHis}
            dataSource={dataTable}
            rowKey={(record) => record.mtrlId + Math.floor(Math.random() * 1000000).toString()}
            bordered
            scroll={{ y: scroll }}
            size="small"
            loading={loading}
            pagination={false}
          />
        </Col>
      </Row>
      <TripHistoryPopup
        data={historyPopupData}
        isOpen={isOpenHistory}
        onClose={onCloseHistory}
        cusId={cusId}
      ></TripHistoryPopup>
      <ThongKePopup
        data={thongKePopupData}
        isOpen={isOpenThongKe}
        onClose={onCloseThongKe}
      ></ThongKePopup>
    </>
  );
}

export default VeThangListTable;
