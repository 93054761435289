import { Form, Modal, Button } from "antd";
import React, { useState, useEffect } from "react";
import "./ThongKePopup.scss";
import tripRecordApi from "api/Call/tripRecord";
import ThongKeTable from "./ThongKeTable";
import moment from "moment";
import { DatePickerCustom } from "components";

import "moment/locale/vi";

moment.locale("vi");

const { confirm } = Modal;

function ThongKePopup({ isOpen, onClose, data, cusId }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();
  const [dataSource, setDataSource] = useState();
  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        fromDt: moment().add(-10, "d"),
        toDt: moment(),
      });
      let param = {
        cusId: data.cusId,
        fromDt: moment().add(-10, "d").format("DD/MM/YYYY"),
        toDt: moment().format("DD/MM/YYYY"),
      };
      getTripOrders(param);
    }
  }, [isOpen]);

  const getTripOrders = async (params) => {
    try {
      setLoading(true);
      const result = await tripRecordApi.getTripOrders(params);
      if (result.success) {
        setDataSource(result.data);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = (values) => {
    let param = {
      cusId: data.cusId,
      fromDt: moment(values.fromDt).format("DD/MM/YYYY"),
      toDt: moment(values.toDt).format("DD/MM/YYYY"),
    };
    getTripOrders(param);
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        className="thongke-popup"
        title={
          <div className="flex justify-between">
            <div className="flex">
              <p className="text-lg mb-0 mt-1 flex">
                Thống kê vé tháng<span className="text-blue-500 ml-2">{data.cusName}</span>
                <span className="text-blue-500 ml-2">{data.cusId}</span>
              </p>
            </div>
            <Form
              colon={false}
              onFinish={onFinish}
              form={form}
              className="form-control-inventory-his"
            >
              <div className="flex">
                <Form.Item name="fromDt" className="!mr-2">
                  <DatePickerCustom
                    label="Từ ngày"
                    style={{ width: "200px" }}
                    format="DD/MM/YYYY"
                    // onChange={(date) => {
                    //   onChangeDate({ ngayPlan: moment(date).format("DD/MM/YYYY") });
                    // }}
                  ></DatePickerCustom>
                </Form.Item>
                <Form.Item name="toDt" className="!mr-2">
                  <DatePickerCustom
                    label="Đến ngày"
                    style={{ width: "200px" }}
                    format="DD/MM/YYYY"
                    // onChange={(date) => {
                    //   onChangeDate({ ngayPlan: moment(date).format("DD/MM/YYYY") });
                    // }}
                  ></DatePickerCustom>
                </Form.Item>
                <Button className="button btn_custom" type="primary" htmlType="submit" size="large">
                  Xem thống kê
                </Button>
                <Button
                  danger
                  className="button btn_custom w-20"
                  type="primary"
                  onClick={onClose}
                  size="large"
                >
                  Hủy
                </Button>
              </div>
            </Form>
          </div>
        }
        closable={false}
        footer={null}
        width={"80%"}
        open={isOpen}
      >
        <div className="flex h-full inventory-his">
          <ThongKeTable loading={loading} dataTable={dataSource} />
        </div>
      </Modal>
    </>
  );
}

export default ThongKePopup;
