import { Modal, Button, Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { InputCustom, SelectCustom, DatePickerCustom } from "components";
import callApi from "api/Call/callApi";
import { PAGE } from "core/Constants";
import driverApi from "api/Call/driverApi";
import moment from "moment";
import "moment/locale/vi";

moment.locale("vi");

function CarDetail({ isOpen, onClose, data }) {
  const [pageIndex, setPageIndex] = useState(PAGE.DEFAULT_PAGE);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataSource, setDataSource] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (isOpen) {
      setCurrentPage(1);
      setPageIndex(1);
      loadInventoryHistoryDetail(data);
      form.setFieldsValue({
        driverStatus: "Y",
      });
    }
  }, [isOpen]);

  useEffect(() => {
    loadInventoryHistoryDetail(data);
  }, [data]);

  const loadInventoryHistoryDetail = async (param) => {
    if (param) {
      param.custOrdNo = param?.custOrdNo ? param?.custOrdNo.trim() : null;
      try {
        setLoading(true);
        const result = await callApi.getInventoryHistoryDetail(param);
        setDataSource(result.data);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleOk = async () => {
    await onFinish();
  };

  const handleClose = () => {
    onClose(true);
  };

  const onFinish = async () => {
    await form.validateFields().then(() => {
      const params = form.getFieldValue();
      const data = {
        ...params,
        driverBirthday: params.driverBirthday
          ? moment(params.driverBirthday).format("DD/MM/YYYY")
          : undefined,
      };
      driverApi.saveDriver(data);
      onClose(true);
    });
  };

  return (
    <>
      <Modal
        className="material-popup"
        onCancel={handleClose}
        maskClosable={false}
        onOk={handleOk}
        okText="Thêm"
        cancelText="Hủy"
        title="Thêm mới tài xế"
        width={"30%"}
        open={isOpen}
      >
        <Form colon={false} onFinish={onFinish} form={form} className="form-control-inventory-his">
          <div className="mb-4">
            <Form.Item
              name="driverName"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập họ tên",
                },
              ]}
              className=""
            >
              <InputCustom label="Họ Tên" require="true"></InputCustom>
            </Form.Item>
          </div>
          <div className="mb-4">
            <Form.Item
              name="driverPhone"
              // rules={[
              //   {
              //     required: true,
              //     message: "Vui lòng nhập số điện thoại",
              //   },
              // ]}
            >
              <InputCustom label="Số điện thoại"></InputCustom>
            </Form.Item>
          </div>
          <div className="mb-4">
            <Form.Item
              name="driverEmail"
              // rules={[
              //   {
              //     required: true,
              //     message: "Vui lòng nhập biển số xe",
              //   },
              // ]}
            >
              <InputCustom label="Địa chỉ email" require="true"></InputCustom>
            </Form.Item>
          </div>
          <div className="mb-4">
            <Form.Item
              name="driverEmail"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập cmnd",
                },
              ]}
            >
              <InputCustom label="CMND" require="true"></InputCustom>
            </Form.Item>
          </div>
          <div className="mb-4">
            <Form.Item
              name="driverBirthday"
              // onChange={handleChangeDate}
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập ngày sinh",
                },
              ]}
            >
              <DatePickerCustom
                className="w-full"
                label={"Ngày sinh"}
                format="DD/MM/YYYY"
                placeholder=""
                // onChange={(date, dateString) => onChangeBrdyVal(date, dateString)}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="driverStatus">
              <SelectCustom
                label="Trạng thái"
                require="true"
                disabled={true}
                options={[
                  { value: "Y", label: "Đang hoạt động" },
                  { value: "N", label: "Dừng hoạt động" },
                ]}
                showSearch
                allowClear
              ></SelectCustom>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default CarDetail;
