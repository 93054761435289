import { createAsyncThunk } from "@reduxjs/toolkit";
import popupApi from "api/popupApi";

const searchAllUser = createAsyncThunk("/", async (params, { rejectWithValue }) => {
  try {
    const data = await popupApi.searchAllUserWithCd();
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export { searchAllUser };
