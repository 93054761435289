import { vPgeSize } from "./DefineConst";
import { Table } from "antd";
import PropTypes from "prop-types";
import { useState, memo } from "react";

TableCheckDetailUI.propTypes = {
  pData: PropTypes.array,
  pConfigColumn: PropTypes.array,
};

function TableCheckDetailUI({ pData, pConfigColumn, pKey, onDataChecked }) {
  const [alreadySelectedRowKeys, setAlreadySelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys: alreadySelectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setAlreadySelectedRowKeys(selectedRowKeys);
      onDataChecked(selectedRows);
    },
  };
  return (
    <Table
      rowSelection={{
        type: "checkbox",
        ...rowSelection,
      }}
      pagination={{ pageSizeOptions: ["10", "12", "20", "50", "100"], defaultPageSize: vPgeSize }}
      rowKey={(record) => record[pKey]}
      columns={pConfigColumn}
      dataSource={pData}
      scroll={screenY}
    />
  );
}

export default memo(TableCheckDetailUI);
