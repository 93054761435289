import { Button, Form, Input, Space, Dropdown, Badge } from "antd";
import { loginBroadcast, logoutBroadcast } from "core/Broadcast";
import {
  UserOutlined,
  PoweroffOutlined,
  SearchOutlined,
  DisconnectOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import "./styles/HeaderCommon.scss";
import { useDispatch } from "react-redux";
import { logout } from "redux/userSlide";
import { logoutMenu } from "redux/menuSlice";
import { useLocation } from "react-router-dom";
import menuApi from "api/menuApi";
import TokenService from "utils/TokenService";
import { setSearchPhone } from "redux/callingSlice.js";

function HeaderCommon() {
  const [form] = Form.useForm();
  const location = useLocation();
  const [menu, setMenu] = useState([]);
  const [connect, setConnect] = useState(true);
  const [title, setTitle] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    loginBroadcast.postMessage("login");
    loadDataMenu();
    const interval = setInterval(() => {
      if (window.omiSDK.getStatus() != "registered" && connect) {
        let extension = {
          domain: "votrquan", // Replace with your domain
          username: TokenService.getUserLocalStorage()?.line, // Replace with your username
          password: TokenService.getUserLocalStorage()?.passCall, // Replace with your password
        };
        window.omiSDK.register(extension, (error) => {
          if (error) {
            console.log("Register error:", error);
          } else {
            console.log("Register finish!");
          }
        });
      }
      if (!connect) {
        window.omiSDK.unregister();
      }
    }, 5000); // 5 minutes in milliseconds
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let arr = menu.find((item) => item.mnuPgmUrl === location.pathname.slice(1));
    if (location.pathname == "/") {
      setTitle("THỐNG KÊ");
    } else {
      if (arr) {
        setTitle(arr.mnuPgmNm);
      }
    }
  });

  const loadDataMenu = async () => {
    try {
      const data = await menuApi.loadAllMenu();
      if (data) {
        setMenu(data.data);
      }
    } catch (error) {
      throw Error();
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(logoutMenu());
    logoutBroadcast.postMessage("logout");
    window.location.reload();
  };

  const handleDisconnect = () => {
    if (connect) {
      setConnect(!connect);
      window.omiSDK.unregister();
    } else {
      setConnect(!connect);
      let extension = {
        domain: "votrquan", // Replace with your domain
        username: TokenService.getUserLocalStorage()?.line, // Replace with your username
        password: TokenService.getUserLocalStorage()?.passCall, // Replace with your password
      };
      window.omiSDK.register(extension, (error) => {
        if (error) {
          console.log("Register error:", error);
        } else {
          console.log("Register finish!");
        }
      });
    }
  };

  const onSearch = (params) => {
    dispatch(setSearchPhone(params.sdt.replace(/\s/g, "")));
  };

  const handleMenuClick = (e) => {
    switch (e.key) {
      case "disconnect":
        handleDisconnect();
        break;
      case "logout":
        handleLogout();
        break;

      default:
        break;
    }
  };
  const items = [
    {
      label: connect ? "Ngắt kết nối" : "Kết nối lại",
      key: "disconnect",
      icon: <DisconnectOutlined />,
      danger: connect,
    },
    {
      label: "Đăng xuất",
      key: "logout",
      icon: <PoweroffOutlined />,
      danger: true,
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div className="wrap-header">
      <div className="wrap-title">
        <h1 className="!text-black">{title}</h1>
      </div>
      <div className="wrap-user-profile">
        <div className="user-wrap">
          <Form
            form={form}
            name="menu-detail"
            colon={false}
            onFinish={onSearch}
            // onChange={onChangeAll}
          >
            <div className="flex mr-8 my-2">
              <Form.Item name="sdt">
                <Input className="!rounded-md" placeholder="Số điện thoại"></Input>
              </Form.Item>
              <Button
                // size="large"
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
                // onClick={setGhiChuFlgFunc}
                // danger={ghiChuFlg}
              >
                Tìm kiếm
              </Button>
            </div>
          </Form>
          <Space size={24}>
            <Dropdown.Button
              menu={menuProps}
              placement="bottomLeft"
              icon={<UserOutlined style={{ color: connect ? "#1890ff" : "#ffae42" }} />}
            >
              {TokenService.getUserLocalStorage()?.usrNm}
            </Dropdown.Button>
          </Space>
          <Badge status={connect ? "processing" : "error"} />
        </div>
      </div>
    </div>
  );
}

export default HeaderCommon;
