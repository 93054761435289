import store from "app/store";
import axios from "axios";
import queryString from "query-string";
import { userRefresh } from "redux/userAction";
import TokenService from "utils/TokenService";
import { HTTP_REQUEST } from "./Constants";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    [HTTP_REQUEST.HEADERS.CONTENT_TYPE]: HTTP_REQUEST.CONTENT_TYPES.JSON,
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(
  (config) => {
    const { ignoreInterceptor } = config;
    if (!ignoreInterceptor) {
      const token = TokenService.getTokenLocalStorage();
      config = {
        ...config,
        headers: {
          [HTTP_REQUEST.HEADERS
            .AUTHORIZATION]: `${HTTP_REQUEST.AUTHORIZATION.BEARER_TYPE} ${token}`,
        },
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    if (response && response.data) {
      if (response.data.token) {
        return response.data;
      }

      if (response.config.responseType == "arraybuffer") {
        return response;
      }

      if (response.data.success) {
        return response.data;
      } else {
        let dataError = new Error();
        dataError.data = response.data;
        throw dataError;
      }
    }
    return response;
  },
  (error) => {
    const originConfig = error.config;
    if (error.response) {
      if (error.response.status === 401 && !originConfig._retry) {
        originConfig._retry = true;
        try {
          const refreshToken = TokenService.getRefreshTokenLocalStorage();
          store.dispatch(userRefresh({ refreshToken }));
          return axiosClient(originConfig);
        } catch (error) {
          localStorage.removeItem("user");
          window.location.href = "/login";
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
