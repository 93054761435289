import { useEffect, useState } from "react";
import { SaveOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Checkbox, Radio, message, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { createMenu, updateMenu, searchDataForMenu } from "redux/system/menuMgtAction";
import { actionMenuMgt } from "redux/system/menuMgtSlice";

const { TextArea } = Input;

function MenuDetail({ checkDataChanged }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { menuList, menuVO, lsButtonAdd, lsGeneralAdd, lsGridAdd, loadingDetail, loadingSave } =
    useSelector((state) => state.menuMgt);
  const [messageApi, contextHolder] = message.useMessage();
  const [successSave, setsuccessSave] = useState(true);

  useEffect(() => {
    form.setFieldsValue(menuVO);
  }, [form, menuVO]);

  useEffect(() => {
    dispatch(searchDataForMenu());
  }, [successSave]);

  const onChangeAll = () => {
    dispatch(actionMenuMgt.storeMenuVoNew(form.getFieldValue()));
  };

  const onSave = async (values) => {
    if (checkDataChanged()) {
      let menuData = {
        prntId: values.prntId,
        mnuPgmId: values.mnuPgmId,
        mnuPgmOrdNo: values.mnuPgmOrdNo,
        mnuPgmNmEn: values.mnuPgmNmEn,
        mnuPgmNmVi: values.mnuPgmNmVi,
        mnuPgmNmKo: values.mnuPgmNmKo,
        mnuPgmUrl: values.mnuPgmUrl,
        mnuPgmRmk: values.mnuPgmRmk,
        mnuFlag: values.menuTp === 0 ? "Y" : "N",
        mnuUsd: values.mnuUsd ? "Y" : "N",
      };
      if (isDuplicate(menuData)) {
        messageApi.open({
          type: "warning",
          content: "The menu name has existed in the same level",
        });
        return;
      }
      let obj = {
        menuData: menuData,
        listBtn: lsButtonAdd,
        listGeneral: lsGeneralAdd,
        listGrid: lsGridAdd,
      };
      if (menuVO.key === "new") {
        await dispatch(createMenu(obj))
          .then(() => {
            messageApi.open({
              type: "success",
              content: "Saved successfully",
            });
            setsuccessSave(!successSave);
          })
          .catch(() => {
            messageApi.open({
              type: "failed",
              content: "Saved fail",
            });
          });
      } else {
        await dispatch(updateMenu(obj))
          .then(() => {
            messageApi.open({
              type: "success",
              content: "Saved successfully",
            });
            setsuccessSave(!successSave);
          })
          .catch(() => {
            messageApi.open({
              type: "failed",
              content: "Saved fail",
            });
          });
      }
      if (successSave) {
        dispatch(actionMenuMgt.storeMenuVO(form.getFieldValue()));
        dispatch(
          actionMenuMgt.storeMenuVoNew({
            mnuPgmId: "",
            menuTp: "",
            prntId: "",
            mnuPgmOrdNo: "",
            mnuPgmNmEn: "",
            mnuPgmNmVi: "",
            mnuPgmNmKo: "",
            mnuPgmUrl: "",
            mnuPgmRmk: "",
            mnuUsd: "",
          })
        );
        dispatch(actionMenuMgt.storeLsButton(lsButtonAdd));
        dispatch(actionMenuMgt.storeLsButtonAdd(lsButtonAdd));
        dispatch(actionMenuMgt.storeLsGeneral(lsGeneralAdd));
        dispatch(actionMenuMgt.storeLsGeneralAdd(lsGeneralAdd));
        dispatch(actionMenuMgt.storeLsGrid(lsGridAdd));
        dispatch(actionMenuMgt.storeLsGridAdd(lsGridAdd));
      }
    } else {
      messageApi.open({
        type: "warning",
        content: "Nothing change!",
      });
    }
  };

  const isDuplicate = (values) => {
    const result = menuList.filter((item) => {
      return (
        item.prntId === values.prntId &&
        (item.mnuPgmNmEn === values.mnuPgmNmEn ||
          item.mnuPgmNmVi === values.mnuPgmNmVi ||
          item.mnuPgmNmKo === values.mnuPgmNmKo)
      );
    });
    if (result.length > 1) return true;
    return false;
  };

  return (
    <Col className="middle-box menu-detail" span={7.5} offset={1}>
      <Spin spinning={loadingDetail || loadingSave}>
        {contextHolder}
        <Form
          form={form}
          name="menu-detail"
          colon={false}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="horizontal"
          onFinish={onSave}
          onChange={onChangeAll}
        >
          <div className="top-detail">
            <h3>{t("UI_ADM_006.title.detailInformation")}</h3>
          </div>
          <div className="form-detail">
            <Form.Item
              name="menuTp"
              label={t("UI_ADM_006.menuDetail.typeMenu")}
              rules={[
                {
                  required: true,
                  message: t("UI_ADM_006.menuDetail.typeMnReq"),
                },
              ]}
              disabled={menuVO.key !== "new"}
            >
              <Radio.Group>
                <Radio value={0}>{t("UI_ADM_006.menuDetail.folder")}</Radio>
                <Radio value={1}>{t("UI_ADM_006.menuDetail.pages")}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="mnuPgmId" hidden={true} label={t("UI_ADM_006.menuDetail.menuId")}>
              <Input
                style={{ width: "280px" }}
                placeholder={t("UI_ADM_006.menuDetail.parentMenu")}
              ></Input>
            </Form.Item>
            <Form.Item name="prntId" label={t("UI_ADM_006.menuDetail.parentMenu")}>
              <Input
                disabled={true}
                style={{ width: "280px" }}
                placeholder={t("UI_ADM_006.menuDetail.parentMenu")}
              ></Input>
            </Form.Item>
            <Form.Item
              name="mnuPgmNmEn"
              label={t("UI_ADM_006.menuDetail.englishName")}
              rules={[
                {
                  required: true,
                  message: t("UI_ADM_006.menuDetail.engNmReq"),
                },
              ]}
            >
              <Input
                style={{ width: "280px" }}
                placeholder={t("UI_ADM_006.menuDetail.englishName")}
              ></Input>
            </Form.Item>
            <Form.Item
              name="mnuPgmNmVi"
              label={t("UI_ADM_006.menuDetail.viName")}
              rules={[
                {
                  required: true,
                  message: t("UI_ADM_006.menuDetail.vieNmReq"),
                },
              ]}
            >
              <Input
                style={{ width: "280px" }}
                placeholder={t("UI_ADM_006.menuDetail.viName")}
              ></Input>
            </Form.Item>
            <Form.Item
              name="mnuPgmNmKo"
              label={t("UI_ADM_006.menuDetail.koreaName")}
              rules={[
                {
                  required: true,
                  message: t("UI_ADM_006.menuDetail.koNmReq"),
                },
              ]}
            >
              <Input
                style={{ width: "280px" }}
                placeholder={t("UI_ADM_006.menuDetail.koreaName")}
              ></Input>
            </Form.Item>
            <Form.Item
              name="mnuPgmUrl"
              label={t("UI_ADM_006.menuDetail.url")}
              defaultprops="unChecked"
            >
              <Input
                style={{ width: "280px" }}
                placeholder={t("UI_ADM_006.menuDetail.url")}
              ></Input>
            </Form.Item>
            <Form.Item name="mnuPgmRmk" label={t("UI_ADM_006.menuDetail.remark")}>
              <TextArea autoSize={{ minRows: 3, maxRows: 5 }}></TextArea>
            </Form.Item>
            <Form.Item name="mnuPgmOrdNo" label={t("UI_ADM_006.menuDetail.remark")} hidden>
              <TextArea autoSize={{ minRows: 3, maxRows: 5 }}></TextArea>
            </Form.Item>
            <Form.Item name="mnuUsd" valuePropName="checked" label={t("UI_ADM_006.menuDetail.use")}>
              <Checkbox></Checkbox>
            </Form.Item>
            <Form.Item className="button-save">
              <Button
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
                disabled={Object.keys("recordButton").length > 0 || "isNew" == true ? false : true}
              >
                {t("buttons.save")}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </Col>
  );
}
export default MenuDetail;
