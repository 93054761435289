import axiosClient from "core/axiosClient";
const carApi = {
  getCar: () => {
    const url = "/api/car/getCar";
    return axiosClient.get(url);
  },
  getCarAssign: () => {
    const url = "/api/car/getCarAssign";
    return axiosClient.get(url);
  },
  saveCar: (params) => {
    const url = "/api/car/saveCar";
    return axiosClient.post(url, params);
  },
  updateCar: (params) => {
    const url = "/api/car/updateCar";
    return axiosClient.post(url, params);
  },
  updateCarStatus: (params) => {
    const url = "/api/car/updateCarStatus";
    return axiosClient.post(url, params);
  },
};
export default carApi;
