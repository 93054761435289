import { Col, Row, Table, Modal, Tag } from "antd";
import React, { useEffect } from "react";

import moment from "moment";
import tripRecordApi from "api/Call/tripRecord";
import { useDispatch } from "react-redux";
import { setCallData } from "redux/callingSlice.js";
import "moment/locale/vi";
import TokenService from "utils/TokenService";
import ThongKeFooter from "components/ThongKeFooter";

// const admin = TokenService.getUserLocalStorage()?.coCd == "CLV";

moment.locale("vi");
const { confirm } = Modal;

function ThongKeTable({ dataTable, loading }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const resizeHandler = () => {
      const windowHeight = window.innerHeight;
      const tableHeight = windowHeight - 170; // You can adjust this value according to your needs
      const tableElement = document.querySelector(".ant-table-body");
      if (tableElement) {
        tableElement.style.maxHeight = `${tableHeight}px`;
      }
    };
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  const callPhoneNumber = (param) => {
    window.omiSDK.makeCall(param);
  };

  const onCancelTrip = (param) => {
    confirm({
      title: "Bạn có muốn hủy chuyến đi này",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        await tripRecordApi.updateOrderStatus(param);
      },
      onCancel() {
        return;
      },
    });
  };

  const defineThongKe = [
    {
      title: "No.",
      dataIndex: "index",
      align: "center",
      width: "60px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Tuyến",
      dataIndex: "tuyenName",
      align: "center",
      width: "120px",
      footerContent: "Tổng:",
    },
    {
      title: "Ngày",
      dataIndex: "ngayDi",
      align: "center",
      width: "100px",
      render: (text, row) => moment(moment(row["ngayDi"], "DD/MM/YYYY")).format("DD-MM (dddd)"),
    },
    {
      title: "Giờ",
      dataIndex: "khungGioName",
      align: "center",
      width: "60px",
    },
    {
      title: "Số ghế",
      dataIndex: "soKhach",
      width: "90px",
      align: "center",
      footerSum: true,
      render: (_, record) => (record.tripStatus === "Y" ? record.soKhach : 0),
    },
    {
      title: "Giá",
      dataIndex: "soTien",
      align: "center",
      width: "60px",
      footerEndfix: true,
      render: (_, record) =>
        record.tripType === 1
          ? `${record.soTien}K`
          : record.tripType === 2
          ? `${record.soTien}K`
          : record.tripType === 3
          ? `${record.soTien}K`
          : `${record.soTien * record.soKhach}K`,
    },
    {
      title: "Ng.Tạo",
      dataIndex: "createUser",
      width: "120px",
      align: "center",
    },
    {
      title: "Trạng thái",
      dataIndex: "tickStatus",
      responsive: ["md"],
      width: "150px",
      ellipsis: true,
      align: "center",
      render: (_, record) =>
        record.tripStatus === "Y" ? (
          <Tag color={"green"} key="Y">
            Đã đi
          </Tag>
        ) : (
          <Tag color={"volcano"} key="Y">
            Đã hủy
          </Tag>
        ),
    },
  ];

  return (
    <>
      <Row>
        <Col className="table-inventory-his">
          <Table
            columns={defineThongKe}
            dataSource={dataTable}
            rowKey={(record) => record.mtrlId + Math.floor(Math.random() * 1000000).toString()}
            footer={() => {
              return <ThongKeFooter dataSource={dataTable} columns={defineThongKe} />;
            }}
            bordered
            scroll={{ y: 750 }}
            size="small"
            loading={loading}
            pagination={false}
          />
        </Col>
      </Row>
    </>
  );
}

export default ThongKeTable;
