import { Checkbox, Col, Form, Radio, Select, Upload, Modal, message } from "antd";
import { t } from "i18next";
import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import userMgtApi from "api/userMgtApi";
import { userMgt } from "redux/system/userMgtSlice";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import InputCustom from "components/InputCustom";
import SelectCustom from "components/SelectCustom";
import DatePickerCustom from "components/DatePickerCustom";

UserMgtDetail.propTypes = {
  record: PropTypes.shape({
    usrId: PropTypes.string,
    usrNm: PropTypes.string,
    usrEml: PropTypes.string,
  }),
};

UserMgtDetail.defaultProps = {
  record: {},
};

function UserMgtDetail({ record, clickNew, clickDelete, clickSave, onRecord }) {
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const dateFormat = "YYYY-MM-DD";
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const { userList } = useSelector((state) => state.userMgt);

  const [formDisabled, setFormDisabled] = useState(true);
  const [idChekedGender, setIdChekedGender] = useState("");
  const [checked, setChecked] = useState(undefined);
  const [dataDetails, setDataDetails] = useState({});
  const [listCompany, setListCompany] = useState([]);
  const [listCountry, setListCountry] = useState([]);
  const [dataRole, setDataRole] = useState(undefined);
  const [listRole, setListRole] = useState([]);
  const [usrSexList, setUsrSexList] = useState([]);
  const [empTpList, setEmpTpList] = useState([]);

  const [isChekedUserNo, setIsChekedUserNo] = useState(false);
  const [isChekedUserId, setIsChekedUserId] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [dataDelete, setDataDelete] = useState(false);
  const [dataSave, setDataSave] = useState({});

  const [isNew, setIsNew] = useState(false);

  const searchUsrInfo = async (params) => {
    try {
      if (record.usrId != "" && record.usrId != undefined) {
        let { data: respObj } = await userMgtApi.searchUsrInfo(params);
        setDataDetails(respObj.userInfo.usrInfo);
        if (respObj.userInfo.usrSysRoleList.length > 0) {
          setDataRole(respObj.userInfo.usrSysRoleList[0].roleId);
        } else {
          setDataRole(undefined);
        }
      }
    } catch (error) {
      throw Error();
    }
  };

  const deleteUsrInfo = async (params) => {
    setLoadingDelete(true);
    try {
      const data = await userMgtApi.deleteUsrInfo(params);
      let d = [...userList];
      const idx = d.findIndex((obj) => obj.usrId === params.usrId);
      d[idx] = {
        ...d[idx],
        actFlg: "D",
        detailRole: "",
      };
      dispatch(userMgt.storeUserlist(d));
      setDataDelete(data);
    } catch (error) {
      throw Error();
    } finally {
      setLoadingDelete(false);
    }
  };

  const updateUsrInfo = async (params) => {
    setLoadingSave(true);
    try {
      const data = await userMgtApi.updateUsrInfo(params);

      let result = {
        ...data.data.usrData.usrInfo,
        empeNo: data.data.usrData.usrInfo.usrNo,
      };

      let d = [...userList];
      if (isNew) {
        d.push(result);
        const idx = d.findIndex((obj) => obj.usrId === result.usrId);
        d[idx] = {
          ...d[idx],
          detailRole: params.usrData.usrInfo.roleNm,
        };
      } else {
        const idx = d.findIndex((obj) => obj.usrId === result.usrId);
        d[idx] = {
          ...d[idx],
          actFlg: result.actFlg,
          coCd: result.actFlg,
          deltStsFlg: result.deltStsFlg,
          empeNo: result.empeNo,
          master: result.master,
          mode: result.mode,
          strStartDt: result.strStartDt,
          usrDurStr: result.usrDurStr,
          usrEml: result.usrEml,
          usrId: result.usrId,
          usrNm: result.usrNm,
          detailRole: params.usrData.usrInfo.roleNm,
        };
      }
      dispatch(userMgt.storeUserlist(d));

      setDataSave(data);
    } catch (error) {
      throw Error();
    } finally {
      setLoadingSave(false);
    }
  };

  const validUserCode = async (params) => {
    setIsChekedUserNo(false);
    try {
      const data = await userMgtApi.validUserCode(params);
      if (data.data.usrInfo.length > 0) {
        setIsChekedUserNo(true);
        return data;
      }
    } catch (error) {
      throw Error();
    }
  };

  const validUserId = async (params) => {
    setIsChekedUserId(false);
    try {
      const data = await userMgtApi.validUserId(params);
      if (data.data.usrInfo != undefined && isNew) {
        setIsChekedUserId(true);
        return data;
      }
    } catch (error) {
      throw Error();
    }
  };

  const loadDefaultCodeList = async () => {
    try {
      let { data: result } = await userMgtApi.searchDefaultCodeList();
      setEmpTpList(result.empTpList);
      setUsrSexList(result.usrSexList);
      setListCompany(result.lstCo);
      setListCountry(result.countryList);
    } catch (error) {
      throw Error();
    }
  };

  const loadListRole = async () => {
    try {
      const data = await userMgtApi.searchUserList({});
      setListRole(data.data.userRoleList);
    } catch (error) {
      throw Error();
    }
  };

  useEffect(() => {
    loadDefaultCodeList();
    loadListRole();
  }, []);

  useEffect(() => {
    if (Object.keys(dataDetails).length > 0) {
      form.resetFields();
      let data = {
        ...dataDetails,
        brdyVal: dataDetails.brdyVal ? moment(dataDetails.brdyVal, dateFormat) : "",
        strStartDt: dataDetails.strStartDt ? moment(dataDetails.strStartDt, dateFormat) : "",
        roleId: dataRole || "",
      };

      form.setFieldsValue(data);

      setIdChekedGender(data.comUsrSx);
      setChecked(data.actFlg === "Y" ? true : false);
      setIsNew(false);
      setIsChekedUserNo(false);
      setIsChekedUserId(false);

      if (dataDetails.actFlg === "D") {
        setFormDisabled(true);
      } else {
        setFormDisabled(false);
      }
    }
  }, [dataDetails]);

  useEffect(() => {
    searchUsrInfo({ usrId: record.usrId });
  }, [record]);

  useEffect(() => {
    if (clickNew !== null) {
      handleClickNew();
    }
  }, [clickNew]);

  useEffect(() => {
    if (clickDelete !== null) {
      handleClickDelete();
    }
  }, [clickDelete]);

  useEffect(() => {
    if (clickSave !== null) {
      const validation = form.validateFields();
      validation
        .then((values) => {
          onFinish(values);
        })
        .catch((error) => {});
    }
  }, [clickSave]);

  const handleClickNew = () => {
    setIsNew(true);
    setFormDisabled(false);
    form.resetFields();
    onRecord({
      usrId: "",
      usrNm: "",
      usrEml: "",
    });
  };

  const handleClickDelete = () => {
    const isSelected = !_.isEmpty(form.getFieldValue());
    const d = form.getFieldsValue();
    if (isSelected && d.actFlg !== "D") {
      confirm({
        title: "Confirm",
        icon: <ExclamationCircleFilled />,
        content: "Are you sure you want to delete selected data?",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          const record = form.getFieldsValue();
          deleteUsrInfo({ usrId: record.usrId });
          form.resetFields();
        },
        onCancel() {},
      });
    }
  };

  const onFinish = async (values) => {
    const roleNm = listRole.filter((role) => role.roleId === values.roleId);
    let newValues = {
      usrData: {
        usrInfo: {
          actFlg: checked ? "Y" : "N",
          usrId: values.usrId || "",
          usrNm: values.usrNm || "",
          fullNm: values.fullNm || "",
          usrSkypeId: values.usrSkypeId || "",
          age: null,
          hmAddr: values.hmAddr || "",
          cntCd: values.cntCd || "",
          ctyNm: values.ctyNm || "",
          mphnNo: values.mphnNo || "",
          usrEml: values.usrEml || "",
          comUsrSx: idChekedGender || "",
          brdyVal: form.getFieldValue("brdyVal")
            ? moment(form.getFieldValue("brdyVal")).format("DDMMYYYY")
            : "",
          strStartDt: form.getFieldValue("strStartDt")
            ? moment(form.getFieldValue("strStartDt")).format("DDMMYYYY")
            : "",
          imgUrl: "",
          ntlt: "",
          mrrStsCd: "",
          edu: "",
          hby: "",
          wrkExp: "",
          spct: "",
          engLvl: "",
          salyLvl: "",
          prjHis: "",
          deltStsFlg: "N",
          coCd: values.coCd || "",
          locCd: values.locCd || "",
          empeTpCd: values.empeTpCd || "",
          perfPntNo: 0.0,
          ctrbPntNo: 0.0,
          usrNo: values.usrNo || "",
          ofcCd: values.ofcCd || "",
          roleNm: roleNm.length > 0 ? roleNm[0].roleNm : "",
        },
        usrSysRoleList: [{ usrId: values.usrId, roleId: values.roleId }],
        strStartDt: form.getFieldValue("strStartDt")
          ? moment(form.getFieldValue("strStartDt")).format("DDMMYYYY")
          : "",
      },
      mode: isNew ? "insert" : "modify",
      curDt: moment().format("MM/DD/YYYY"),
    };
    const validUserIdresult = await validUserId({ usrId: newValues.usrData.usrInfo.usrId });
    const validUserCoderesult = await validUserCode({
      usrId: newValues.usrData.usrInfo.usrId,
      usrCd: newValues.usrData.usrInfo.usrNo,
      comCd: newValues.usrData.usrInfo.coCd,
    });

    if (isNew) {
      if (!validUserIdresult && !validUserCoderesult) {
        updateUsrInfo(newValues);
      }
    } else if (!validUserCoderesult) {
      updateUsrInfo(newValues);
    }
  };

  const onChangeBrdyVal = (date, dataString) => {
    if (dataString === "") {
      form.setFieldValue("brdyVal", null);
    } else {
      form.setFieldValue("brdyVal", moment(dataString, dateFormat));
    }
  };
  const onChangeStrStartDt = (date, dataString) => {
    if (dataString === "") {
      form.setFieldValue("strStartDt", null);
    } else {
      form.setFieldValue("strStartDt", moment(dataString, dateFormat));
    }
  };

  const onChange = (e) => {
    setChecked(e.target.checked);
  };

  useEffect(() => {
    if (!_.isEmpty(dataSave)) {
      if (dataSave.success) {
        messageApi.open({
          type: "success",
          content: `${t("Notification.saveSuccess")}`,
        });
        setIsNew(false);
      } else {
        messageApi.open({
          type: "error",
          content: `${t("Notification.saveFalse")}`,
        });
      }
      setDataSave([]);
    }
    if (!_.isEmpty(dataDelete)) {
      if (dataDelete.success) {
        messageApi.open({
          type: "success",
          content: `${t("Notification.deleteSuccess")}`,
        });
      } else {
        messageApi.open({
          type: "error",
          content: `${t("Notification.deleteFalse")}`,
        });
      }
      setDataDelete([]);
    }
  }, [dataSave, dataDelete]);

  return (
    <div>
      <Col className="company-detail" span={8} offset={16}>
        <div className="wrap-detail">
          <Form
            colon={false}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            onFinish={onFinish}
            form={form}
            layout="horizontal"
            style={{ maxWidth: 500 }}
            disabled={formDisabled}
          >
            <div className="top-detail">
              <div className="title-component">
                <h3>{t("User Information")}</h3>
              </div>
            </div>
            <div className="form-detail">
              <Form.Item
                name="usrId"
                rules={[
                  {
                    required: true,
                    message: "User ID is required!",
                  },
                ]}
                {...(isChekedUserId && {
                  // hasFeedback: true, // <--- you can set this only to true if result is success
                  help: isChekedUserId === true ? "User ID may be used already!" : "",
                  validateStatus: isChekedUserId === true ? "error" : "",
                })}
              >
                <InputCustom
                  style={{ width: "150%" }}
                  onChange={() => form.setFieldValue("usrEml", form.getFieldValue("usrId"))}
                  label={"User ID"}
                  disabled={!isNew}
                  placeholdercustom={"example@gmail.com"}
                ></InputCustom>
              </Form.Item>
              <Form.Item
                name="usrNm"
                rules={[
                  {
                    required: true,
                    message: "User Name is required!",
                  },
                ]}
              >
                <InputCustom style={{ width: "150%" }} label={"Username"}></InputCustom>
              </Form.Item>
              <Form.Item
                name="fullNm"
                rules={[{ required: true, message: "Local Name is required!" }]}
              >
                <InputCustom style={{ width: "150%" }} label={"Local Name"}></InputCustom>
              </Form.Item>

              <Form.Item name="actFlg">
                <Checkbox checked={checked} onChange={onChange}>
                  Active Flag
                </Checkbox>
              </Form.Item>
              <Form.Item name="gnDer" label="Gender">
                {usrSexList.map(({ cdNm, comCd }) => {
                  return (
                    <Radio
                      key={comCd}
                      onChange={() => setIdChekedGender(comCd)}
                      checked={comCd === idChekedGender}
                    >
                      {cdNm}
                    </Radio>
                  );
                })}
              </Form.Item>
              <Form.Item name="brdyVal">
                <DatePickerCustom
                  style={{ width: "150%" }}
                  label={t("Birthday")}
                  placeholder=""
                  onChange={(date, dateString) => onChangeBrdyVal(date, dateString)}
                />
              </Form.Item>
              <Form.Item name="hmAddr">
                <InputCustom
                  style={{ width: "150%" }}
                  label={t("UI_ADM_001.detail_form.address")}
                ></InputCustom>
              </Form.Item>
              <Form.Item name="ctyNm">
                <InputCustom
                  style={{ width: "150%" }}
                  label={t("UI_ADM_001.detail_form.city")}
                ></InputCustom>
              </Form.Item>
              <Form.Item name="cntCd">
                <SelectCustom
                  style={{ width: "150%" }}
                  label={t("UI_ADM_001.detail_form.country")}
                  placeholdercustom="Country"
                >
                  {listCountry.map((country) => (
                    <Select.Option key={country.cntCd} value={country.cntCd}>
                      {country.cntNm}
                    </Select.Option>
                  ))}
                </SelectCustom>
              </Form.Item>
              <Form.Item name="usrEml" rules={[{ required: true, message: "Email is required!" }]}>
                <InputCustom
                  style={{ width: "150%" }}
                  disabled={!isNew}
                  label={t("UI_ADM_001.detail_form.email")}
                ></InputCustom>
              </Form.Item>
              <Form.Item name="mphnNo">
                <InputCustom style={{ width: "150%" }} label={t("Mobile Phone")}></InputCustom>
              </Form.Item>
              <Form.Item name="usrSkypeId">
                <InputCustom style={{ width: "150%" }} label={t("Skype ID")}></InputCustom>
              </Form.Item>
              <Form.Item name="coCd" rules={[{ required: true, message: "Company is required!" }]}>
                <SelectCustom
                  style={{ width: "150%" }}
                  label={t("Company")}
                  placeholdercustom="Company"
                >
                  {listCompany.map((company) => (
                    <Select.Option key={company.coCd} value={company.coCd}>
                      {company.coNm}
                    </Select.Option>
                  ))}
                </SelectCustom>
              </Form.Item>
              <Form.Item
                name="usrNo"
                rules={[{ required: true, message: "Employee Code is required!" }]}
                {...(isChekedUserNo && {
                  // hasFeedback: true, // <--- you can set this only to true if result is success
                  help: isChekedUserNo === true ? "Employee Code may be used already!" : "",
                  validateStatus: isChekedUserNo === true ? "error" : "",
                })}
              >
                <InputCustom style={{ width: "150%" }} label={t("Employee Code")}></InputCustom>
              </Form.Item>
              <Form.Item
                name="empeTpCd"
                rules={[{ required: true, message: "Employee Type is required!" }]}
              >
                <SelectCustom
                  style={{ width: "150%" }}
                  label={t("Employee Type")}
                  placeholdercustom="Employee Type"
                >
                  {empTpList.map((emp) => (
                    <Select.Option key={emp.comCd} value={emp.comCd}>
                      {emp.cdNm}
                    </Select.Option>
                  ))}
                </SelectCustom>
              </Form.Item>
              <Form.Item
                name="strStartDt"
                rules={[{ required: true, message: "Employee Start Date is required!" }]}
              >
                <DatePickerCustom
                  style={{ width: "150%" }}
                  label={t("Emp Start Date")}
                  placeholder=""
                  onChange={(date, dataString) => onChangeStrStartDt(date, dataString)}
                />
              </Form.Item>
              <Form.Item
                name="roleId"
                rules={[{ required: true, message: "Employee Type is required!" }]}
              >
                <SelectCustom
                  style={{ width: "150%" }}
                  value={dataRole}
                  label={t("Company Role")}
                  placeholdercustom="Company Role"
                >
                  {listRole.map((role) => (
                    <Select.Option key={role.roleId} value={role.roleId}>
                      {role.roleNm}
                    </Select.Option>
                  ))}
                </SelectCustom>
              </Form.Item>
              <Form.Item name="ofcCd">
                <SelectCustom
                  style={{ width: "150%" }}
                  label={t("Office")}
                  placeholdercustom="Office"
                ></SelectCustom>
              </Form.Item>
              <Form.Item label="Upload" valuePropName="fileList">
                <Upload action="/upload.do" listType="picture-card">
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </Upload>
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>{contextHolder}</Form.Item>
            </div>
          </Form>
        </div>
      </Col>
    </div>
  );
}

export default UserMgtDetail;
