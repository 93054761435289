import axiosClient from "core/axiosClient";
const tripRecordApi = {
  getTripOrders: (params) => {
    const url = "/api/trip-order/getTripOrders";
    return axiosClient.post(url, params);
  },
  getTripOrdersSum: (params) => {
    const url = "/api/trip-order/getTripOrdersSum";
    return axiosClient.post(url, params);
  },
  getRevenueList: (params) => {
    const url = "/api/trip-order/getRevenueList";
    return axiosClient.post(url, params);
  },
  getSalaryList: (params) => {
    const url = "/api/trip-order/getSalaryList";
    return axiosClient.post(url, params);
  },
  saveTripRecord: (params) => {
    const url = "/api/trip-order/saveTripOrders";
    return axiosClient.post(url, params);
  },
  updateDriverOrder: (params) => {
    const url = "/api/trip-order/updateDriverOrder";
    return axiosClient.post(url, params);
  },
  updateOrder: (params) => {
    const url = "/api/trip-order/updateOrder";
    return axiosClient.post(url, params);
  },
  updateOrderStatus: (params) => {
    const url = "/api/trip-order/updateOrderStatus";
    return axiosClient.post(url, params);
  },
  exportExcelRevenue: (params) => {
    const url = "/api/trip-order/exportExcelRevenue";
    return axiosClient.post(url, params, { responseType: "arraybuffer" });
  },
  exportExcelSalary: (params) => {
    const url = "/api/trip-order/exportExcelSalary";
    return axiosClient.post(url, params, { responseType: "arraybuffer" });
  },
  getTripRecordHis: (params) => {
    const url = "/api/trip-order/getTripRecordHis";
    return axiosClient.get(url, { params });
  },
};
export default tripRecordApi;
