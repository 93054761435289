import React, { useState } from "react";
import { Input } from "antd";

import "./styles/Input.scss";

const InputCustom = ({ ...props }) => {
  const [focus, setFocus] = useState(false);
  let { label, value, placeholderCustom, type, require, ...restProps } = props;

  if (!placeholderCustom) placeholderCustom = label;

  const isOccupied = focus || (value && value.length !== 0) || value == "0";

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const requiredMark = require ? <span className="text-danger">*</span> : null;

  return (
    <div className="input-custom">
      <div className="float-label" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
        <Input {...props} />
        <label className={labelClass}>
          {isOccupied ? label : placeholderCustom} {requiredMark}
        </label>
      </div>
    </div>
  );
};

export default InputCustom;
