import React, { useState } from "react";
import { DatePicker } from "antd";

import "./styles/DatePicker.scss";

const DatePickerCustom = ({ ...props }) => {
  const [focus, setFocus] = useState(false);
  let { label, value, placeholderCustom, type, require, ...restProps } = props;

  if (!placeholderCustom) placeholderCustom = label;

  if (value && isNaN(new Date(value).getTime())) {
    value = undefined;
  }

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const requiredMark = require ? <span className="text-danger">*</span> : null;

  return (
    <div className="date-custom">
      <div className="float-label" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
        <DatePicker {...props} />
        <label className={labelClass}>
          {isOccupied ? label : placeholderCustom} {requiredMark}
        </label>
      </div>
    </div>
  );
};

export default DatePickerCustom;
