import { createAsyncThunk } from "@reduxjs/toolkit";
import userMgtApi from "../../api/userMgtApi";

const userMgtSearchAll = createAsyncThunk(
  "userMgt/searchUserList",
  async (params, { rejectWithValue }) => {
    try {
      const data = await userMgtApi.searchUserList(params);
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export { userMgtSearchAll };
