import { createSlice } from "@reduxjs/toolkit";
import TokenService from "utils/TokenService";
import { userLogin, userRefresh } from "./userAction";

const token = TokenService.getTokenLocalStorage();

const initialState = {
  loading: false,
  userInfo: null,
  token,
  error: null,
  success: false,
  isAuthenticated: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      TokenService.removeUserLocalStorage();
      TokenService.removeListMenuLocalStorage();
      state.loading = false;
      state.userInfo = null;
      state.error = null;
      state.token = null;
      state.isAuthenticated = false;
    },
    refresh: (state, { payload }) => {
      TokenService.removeListMenuLocalStorage();
      state.token = payload.token;
      state.userInfo = { ...state.userInfo, token: payload.token };
    },
  },
  extraReducers: {
    // Login Action

    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.userInfo = payload;
      state.token = payload.token;
      state.success = true;
      state.loading = false;
      state.isAuthenticated = true;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },

    [userRefresh.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userRefresh.fulfilled]: (state, { payload }) => {
      TokenService.removeListMenuLocalStorage();
      state.userInfo = { ...state.userInfo, token: payload.token };
      state.token = payload.token;
      state.success = true;
      state.loading = false;
    },
    [userRefresh.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

const { reducer, actions } = userSlice;

export const { logout, refresh } = actions;

export default reducer;
