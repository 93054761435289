import React from "react";
import { Table, Col } from "antd";
import { useTranslation } from "react-i18next";

function PopupList() {
  const { t } = useTranslation();
  const listColumn = [
    {
      title: "No",
      dataIndex: "coCd",
      width: "50px",
    },
    {
      title: "Grid Id",
      className: "column-money",
      dataIndex: "btnNm",
      width: "200",
      responsive: ["md"],
    },
    {
      title: "Column Id",
      className: "column-money",
      dataIndex: "btnNm",
      width: "200",
      responsive: ["md"],
    },
    {
      title: "Column Name",
      className: "column-money",
      dataIndex: "btnNm",
      width: "200",
      responsive: ["md"],
    },
  ];
  return (
    <Col className="popup-list" span={12} offset={1}>
      <div className="table-component">
        <div className="wrap-list">
          <div className="title-component">
            <h3>{t("UI_ADM_006.title.popupList")} </h3>
          </div>
          <Table
            title={t(listColumn.title)}
            pagination={{ pageSize: 8 }}
            columns={listColumn}
            bordered
          />
        </div>
      </div>
    </Col>
  );
}

export default PopupList;
