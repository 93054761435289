import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  callPopup: false,
  phoneNumber: "",
  phoneNumberOther: "",
  phoneNumberSave: "",
  searchFlg: false,
  nhapLieuStatus: false,
  nhapLieuStatusLocal: false,
  acceptCall: false,
  processCall: false,
  acceptCallByOther: false,
  userAccept: "",
  hangup: false,
  initCall: false,
  mtrlValue: "",
  status: "nocall",
  errorInit: false,
};

const callingSlice = createSlice({
  name: "calling",
  initialState,
  reducers: {
    openCallPopup: (state) => {
      state.callPopup = !state.callPopup;
    },
    okCallPopup: (state) => {
      state.callPopup = false;
    },
    setErrorInit: (state) => {
      state.errorInit = true;
    },
    setPhoneNumberSave: (state, { payload }) => {
      state.phoneNumberSave = payload;
    },
    initGateWay: (state, { payload }) => {
      state.initCall = payload;
    },
    setCallData: (state, { payload }) => {
      state.phoneNumber = payload.phoneNumber;
      state.acceptCall = payload.acceptCall;
      state.status = payload.status;
      state.hangup = payload.hangup;
      state.callPopup = payload.callPopup;
      // state.nhapLieuStatus = state.nhapLieuStatusLocal;
    },
    setSearchPhone: (state, { payload }) => {
      state.phoneNumber = payload;
      state.searchFlg = !state.searchFlg;
    },
    setNhapLieuStatus: (state, { payload }) => {
      if (state.status == "nocall" || state.status == "missed" || !payload) {
        state.nhapLieuStatus = payload;
      }
      state.nhapLieuStatusLocal = payload;
    },
    setOutCommingCall: (state, { payload }) => {
      if (!_.isNil(payload.phoneNumber)) {
        state.phoneNumber = payload.phoneNumber;
      }
      if (!_.isNil(payload.acceptCall)) {
        state.acceptCall = payload.acceptCall;
      }
      if (!_.isNil(payload.status)) {
        state.status = payload.status;
      }
    },
    acceptByAnother: (state, { payload }) => {
      state.acceptCallByOther = payload.acceptCallByOther;
      state.phoneNumberOther = payload.phoneNumberOther;
      state.userAccept = payload.userAccept;
    },

    resetCallData: (state) => {
      state.callPopup = false;
      state.phoneNumber = "";
      state.phoneNumberSave = "";
      state.searchFlg = false;
      state.nhapLieuStatus = false;
      state.acceptCall = false;
      state.processCall = false;
      state.hangup = false;
      state.initCall = false;
      state.mtrlValue = "";
      state.status = "nocall";
      state.nhapLieuStatusLocal = false;
      state.errorInit = false;
    },
  },
});

const { reducer, actions } = callingSlice;

export const {
  openCallPopup,
  closeCallPopup,
  okCallPopup,
  setPhoneNumberSave,
  initGateWay,
  setCallData,
  setSearchPhone,
  setOutCommingCall,
  acceptByAnother,
  setNhapLieuStatus,
  resetCallData,
  setErrorInit,
} = actions;

export default reducer;
