import { Modal, Button, Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { InputCustom, SelectCustom, InputNumberCustom } from "components";
import callApi from "api/Call/callApi";
import { PAGE } from "core/Constants";
import carApi from "api/Call/carApi";

function CarDetail({ isOpen, onClose, data }) {
  const [pageIndex, setPageIndex] = useState(PAGE.DEFAULT_PAGE);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  const [titleButton, setTitleButton] = useState("");
  const [title, setTitle] = useState("");

  const [form] = Form.useForm();

  useEffect(() => {
    if (isOpen) {
      setCurrentPage(1);
      setPageIndex(1);
      loadInventoryHistoryDetail(data);
      if (data) {
        form.setFieldsValue({
          carStatus: data.carStatus,
          carType: data.carType,
          carName: data.carName,
          carNumber: data.carNumber,
        });
        setTitleButton(data.newStatus ? "Thêm" : "Sửa");
        setTitle(data.newStatus ? "Thêm mới xe" : "Chỉnh sửa xe");
      }
    }
  }, [isOpen]);

  useEffect(() => {
    loadInventoryHistoryDetail(data);
  }, [data]);

  const loadInventoryHistoryDetail = async (param) => {
    if (param) {
      param.custOrdNo = param?.custOrdNo ? param?.custOrdNo.trim() : null;
      try {
        setLoading(true);
        const result = await callApi.getInventoryHistoryDetail(param);
        setDataSource(result.data);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleOk = () => {
    onFinish();
  };

  const handleClose = () => {
    onClose(true);
  };

  const onFinish = async () => {
    await form.validateFields().then(() => {
      if (data.newStatus) {
        carApi.saveCar(form.getFieldValue());
        onClose(true);
      } else {
        carApi.updateCar(form.getFieldValue());
        onClose(true);
      }
    });
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        className="material-popup"
        onCancel={handleClose}
        maskClosable={false}
        onOk={handleOk}
        okText={titleButton}
        cancelText="Hủy"
        title={title}
        width={"30%"}
        open={isOpen}
      >
        <Form colon={false} onFinish={onFinish} form={form} className="form-control-inventory-his">
          <div className="mb-4">
            <Form.Item
              name="carName"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tên xe",
                },
              ]}
              className=""
            >
              <InputCustom label="Tên xe" require="true"></InputCustom>
            </Form.Item>
          </div>
          <div className="mb-4">
            <Form.Item name="carType">
              <SelectCustom
                label="Loại xe"
                require="true"
                options={[
                  { value: 4, label: "Xe 4 chổ" },
                  { value: 6, label: "Xe 7 chổ" },
                ]}
                showSearch
              ></SelectCustom>
            </Form.Item>
          </div>
          <div className="mb-4">
            <Form.Item
              name="carNumber"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập biển số xe",
                },
              ]}
            >
              <InputCustom label="Biển số xe" require="true"></InputCustom>
            </Form.Item>
          </div>
          <div>
            <Form.Item name="carStatus">
              <SelectCustom
                label="Trạng thái"
                require="true"
                disabled={true}
                options={[
                  { value: "Y", label: "Đang hoạt động" },
                  { value: "N", label: "Dừng hoạt động" },
                ]}
                showSearch
                allowClear
              ></SelectCustom>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default CarDetail;
