import React, { useEffect, useState, useRef, useCallback } from "react";
import { AutoComplete, Form, Input } from "antd";
import Point from "ol/geom/Point.js";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { Vector as VectorSource } from "ol/source.js";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import Overlay from "ol/Overlay";
import { transform } from "ol/proj.js";
import OSM from "ol/source/OSM";
import Feature from "ol/Feature.js";
import { Icon, Style } from "ol/style";
import moment from "moment";
import "moment/locale/vi";
import Fill from "ol/style/Fill";
import Text from "ol/style/Text";
import { defaults as defaultInteractions, Modify } from "ol/interaction";
import { fromLonLat } from "ol/proj";
import debounce from "lodash/debounce";

import m01 from "./01-260707.png";
import m02 from "./02-da1313.png";
import m03 from "./03-1b2dd3.png";
import m04 from "./04-0de053.png";
import m05 from "./20-fd7100.png";
import m06 from "./21-567328.png";
import m07 from "./22-5cb5b6.png";
import m08 from "./23-6880e8.png";
import m09 from "./24-f16787.png";
import m00 from "./05-d9e00d.png";
import m10 from "./10-e71b85.png";
import m11 from "./11-e71b85.png";

function MapPopupDetail({ setTripInforFunc, tripOrder, tuyenId, driverList, dataCus, isOpen }) {
  const [diemDonAdd, setDiemDonAdd] = useState("");
  const [diemTraAdd, setDiemTraAdd] = useState("");
  const [diemDonLat, setDiemDonLat] = useState("");
  const [diemTraLat, setDiemTraLat] = useState("");
  const [diemDonLng, setDiemDonLng] = useState("");
  const [diemTraLng, setDiemTraLng] = useState("");
  const [centerLoc, setCenterLoc] = useState({
    lat: 16.047476382466968,
    lng: 108.19890504473366,
  });
  const [markers, setMarkers] = useState([]);
  const [newMarker, setNewMarker] = useState();
  const [tuyenIdLocal, setTuyenIdLocal] = useState();
  const [map, setMap] = useState(null);
  const [vectorSource, setVectorSource] = useState(new VectorSource());
  const [suggestionsDN, setSuggestionsDN] = useState([]);
  const [suggestionsTK, setSuggestionsTK] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (dataCus) {
      setDiemDonAdd(dataCus.diemDonAdd);
      setDiemDonLat(dataCus.diemDonLat);
      setDiemDonLng(dataCus.diemDonLng);

      setDiemTraAdd(dataCus.diemTraAdd);
      setDiemTraLat(dataCus.diemTraLat);
      setDiemTraLng(dataCus.diemTraLng);
    }
  }, [dataCus]);

  useEffect(() => {
    let listMarker = [];
    if (!dataCus) return;
    if (dataCus.status === "EDIT" || dataCus.status === "COPY") {
      listMarker = tripOrder.map((item) => {
        if (item.tuyen === "TKDN") {
          item.location = {
            lat: Number(item.diemTraLat),
            lng: Number(item.diemTraLng),
          };
        } else {
          item.location = {
            lat: Number(item.diemDonLat),
            lng: Number(item.diemDonLng),
          };
        }
        if (item.driverName) {
          driverList.map(function (o, index) {
            if (Number(o.driverId) === Number(item.driverId)) {
              if (index === 0) {
                item.icon = m01;
              } else if (index === 1) {
                item.icon = m02;
              } else if (index === 2) {
                item.icon = m03;
              } else if (index === 3) {
                item.icon = m04;
              } else if (index === 4) {
                item.icon = m05;
              } else if (index === 5) {
                item.icon = m06;
              } else if (index === 6) {
                item.icon = m07;
              } else if (index === 7) {
                item.icon = m08;
              } else if (index === 8) {
                item.icon = m09;
              }
            }
          });
        } else {
          item.icon = m11;
        }
        if (item.tripId === dataCus.tripId) {
          item.icon = m10;
          item.khungGioName = "VG";

          const marker = {
            newMarker: true,
            location: {
              lat: item.tuyen === "TKDN" ? dataCus.diemTraLat : dataCus.diemDonLat,
              lng: item.tuyen === "TKDN" ? dataCus.diemTraLng : dataCus.diemDonLng,
            },
            khungGioName: "VG",
            icon: m10,
          };
          setNewMarker(marker);
          item.newMarker = true;
        } else {
          item.newMarker = false;
          item.khungGioName = item.khungGioName.substring(0, 2) + "/" + item.soKhach;
        }

        item.selected = false;

        return item;
      });
    } else if (dataCus.status === "NEW") {
      listMarker = tripOrder.map((item) => {
        if (item.tuyen === "TKDN") {
          item.location = {
            lat: Number(item.diemTraLat),
            lng: Number(item.diemTraLng),
          };
        } else {
          item.location = {
            lat: Number(item.diemDonLat),
            lng: Number(item.diemDonLng),
          };
        }
        if (item.driverName) {
          driverList.map(function (o, index) {
            if (Number(o.driverId) === Number(item.driverId)) {
              if (index === 0) {
                item.icon = m01;
              } else if (index === 1) {
                item.icon = m02;
              } else if (index === 2) {
                item.icon = m03;
              } else if (index === 3) {
                item.icon = m04;
              } else if (index === 4) {
                item.icon = m05;
              } else if (index === 5) {
                item.icon = m06;
              } else if (index === 6) {
                item.icon = m07;
              } else if (index === 7) {
                item.icon = m08;
              } else if (index === 8) {
                item.icon = m09;
              }
            }
          });
        } else {
          item.icon = m11;
        }

        item.selected = false;
        item.newMarker = false;
        item.khungGioName = item.khungGioName.substring(0, 2) + "/" + item.soKhach;
        return item;
      });
    }
    if (newMarker) {
      setMarkers([...listMarker, newMarker]);
    } else {
      setMarkers(listMarker);
    }
    if (listMarker && map) {
      addMarkers(map, listMarker);
    }
  }, [tripOrder]);

  useEffect(() => {
    if (tuyenId && tuyenIdLocal && tuyenId != tuyenIdLocal) {
      const diemDonAdd1 = diemDonAdd;
      const diemDonLat1 = diemDonLat;
      const diemDonLng1 = diemDonLng;
      setDiemDonAdd(diemTraAdd);
      setDiemDonLat(diemTraLat);
      setDiemDonLng(diemTraLng);
      setDiemTraAdd(diemDonAdd1);
      setDiemTraLat(diemDonLat1);
      setDiemTraLng(diemDonLng1);
    }
    if (tuyenId) {
      setTuyenIdLocal(tuyenId);
    }
    // setTripInforFunc(diemDonAdd, diemDonLat, diemDonLng, diemTraAdd, diemTraLat, diemTraLng);
  }, [tuyenId]);

  useEffect(() => {
    setTripInforFunc(diemDonAdd, diemDonLat, diemDonLng, diemTraAdd, diemTraLat, diemTraLng);
  }, [diemDonLat, diemDonLng, diemTraLng, diemTraLat, diemDonAdd, diemTraAdd]);

  useEffect(() => {
    if (tuyenId) {
      const osmLayer = new TileLayer({
        preload: Infinity,
        source: new OSM(),
      });

      const mapIns = new Map({
        target: "mapPopup",
        layers: [osmLayer],
        view: new View({
          center: transform([centerLoc.lng, centerLoc.lat], "EPSG:4326", "EPSG:3857"),
          zoom: 13,
        }),
        interactions: defaultInteractions({
          doubleClickZoom: false, // Disable double-click zoom
        }),
      });

      // vectorSource = new VectorSource();
      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });
      mapIns.addLayer(vectorLayer);

      const container = document.getElementById("popup");

      const overlay = new Overlay({
        element: container,
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      });
      mapIns.addOverlay(overlay);

      setMap(mapIns);
      return () => {
        mapIns.setTarget(null); // Clean up the map instance on unmount
      };
    }
  }, [tuyenId]);

  // Debounced search function
  const debouncedSearchDN = useCallback(
    debounce((searchText) => {
      fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
          searchText + " Đà Nẵng"
        )}`
      )
        .then((response) => response.json())
        .then((data) => {
          const options = data.map((item) => ({
            value: item.display_name,
            lat: item.lat,
            lng: item.lon,
          }));
          setSuggestionsDN(options);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
        });
    }, 1300), // 2 seconds delay
    []
  );

  const debouncedSearchTK = useCallback(
    debounce((searchText) => {
      fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
          searchText + " Quảng Nam"
        )}`
      )
        .then((response) => response.json())
        .then((data) => {
          const options = data.map((item) => ({
            value: item.display_name,
            lat: item.lat,
            lng: item.lon,
          }));
          setSuggestionsTK(options);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
        });
    }, 1300), // 2 seconds delay
    []
  );

  useEffect(() => {
    if (map) {
      addMarkers(map, markers);
    }
  }, [markers, map]);

  const offsetDistance = 0.0001; // Adjust this distance as needed

  const offsetMarkers = (markers) => {
    const offset = (lng, lat, index) => {
      const angle = index * 45 * (Math.PI / 180); // 45 degrees increment
      const newLng = lng + offsetDistance * Math.cos(angle);
      const newLat = lat + offsetDistance * Math.sin(angle);
      return [newLng, newLat];
    };

    return markers.map((marker, index) => {
      const [newLng, newLat] = offset(marker.location.lng, marker.location.lat, index);
      return {
        ...marker,
        location: { lng: newLng, lat: newLat },
      };
    });
  };

  const addMarkers = (map1, markers) => {
    // const offsetMarkersList = offsetMarkers(markers);
    const layersToRemove = map
      .getLayers()
      .getArray()
      .filter((layer) => layer instanceof VectorLayer);
    layersToRemove.forEach((layer) => map.removeLayer(layer));

    const vectorSource = new VectorSource({
      features: markers.map((marker) => {
        const feature = new Feature({
          geometry: new Point(fromLonLat([marker.location.lng, marker.location.lat])),
        });
        feature.setStyle(
          new Style({
            image: new Icon({
              anchor: [0.5, 1],
              src: marker.icon ? marker.icon : m11,
            }),
            text: new Text({
              text: marker ? marker.khungGioName : "",
              offsetY: -25,
              font: "8px Calibri,sans-serif",
              fontWeight: "bold",
              scale: 1.4,
              fill: new Fill({
                color: "#000000",
              }),
            }),
          })
        );
        return feature;
      }),
    });
    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    const modify = new Modify({ source: vectorSource });
    map1.addInteraction(modify);

    map1.addLayer(vectorLayer);
  };

  const onDiemDonSelected = (value, place) => {
    const address = value,
      latValue = place.lat,
      lngValue = place.lng;
    if (tuyenId === "DNTK") {
      let marker = {
        newMarker: true,
        name: address,
        status: "diemtra",
        location: {
          lat: latValue,
          lng: lngValue,
        },
        khungGioName: "VG",
        icon: m10,
      };
      const filterListMarker = markers.filter((obj) => {
        return !obj.newMarker;
      });
      setNewMarker(marker);
      const newMarkers = [...filterListMarker, marker];
      setMarkers(newMarkers);
      addMarkers(map, newMarkers);
    }
    setDiemDonAdd(address);
    setDiemDonLat(latValue);
    setDiemDonLng(lngValue);
    form.setFieldsValue({
      diemDonForm: address,
    });
  };

  const onDiemTraSelected = (value, place) => {
    const address = value,
      latValue = place.lat,
      lngValue = place.lng;
    // Set these values in the state.
    if (tuyenId === "TKDN") {
      let marker = {
        newMarker: true,
        name: address,
        status: "diemtra",
        location: {
          lat: latValue,
          lng: lngValue,
        },
        khungGioName: "VG",
        icon: m10,
      };
      const filterListMarker = markers.filter((obj) => {
        return !obj.newMarker;
      });
      setNewMarker(marker);
      const newMarkers = [...filterListMarker, marker];
      setMarkers(newMarkers);
      addMarkers(map, newMarkers);
    }

    setDiemTraAdd(address);
    setDiemTraLat(latValue);
    setDiemTraLng(lngValue);
  };

  return (
    <div className="right-box-map">
      <Form colon={false} form={form}>
        <div className="mb-4 grid grid-cols-2">
          <div className="mr-4">
            <div className="flex">
              <p className="text-base font-bold">Điểm đón</p>
              <p className="text-base font-bold ml-2 text-red-500">
                {tuyenId == "DNTK" ? "Đà Nẵng" : "Tam Kỳ"}
              </p>
            </div>
            <AutoComplete
              style={{
                width: "100%",
              }}
              options={tuyenId == "DNTK" ? suggestionsDN : suggestionsTK}
              // onSelect={handleSelect}
              onSelect={onDiemDonSelected}
              className="w-full !h-10 "
              value={diemDonAdd}
              // onChange={setValue}
            >
              <input
                type="text"
                placeholder="Nhập điểm đón"
                className="w-full !h-10 text-base p-4 !border-2 !rounded-md !border-slate-300"
                onChange={(e) => {
                  setDiemDonAdd(e.target.value);
                  tuyenId == "DNTK"
                    ? debouncedSearchDN(e.target.value)
                    : debouncedSearchTK(e.target.value);
                }}
                // onKeyDown={handleSearch}
              />
            </AutoComplete>
          </div>
          <div className="mr-4">
            <div className="flex">
              <p className="text-base font-bold">Điểm trả</p>
              <p className="text-base font-bold ml-2 text-red-500">
                {tuyenId == "DNTK" ? "Tam Kỳ" : "Đà Nẵng"}
              </p>
            </div>
            <AutoComplete
              style={{
                width: "100%",
              }}
              options={tuyenId == "DNTK" ? suggestionsTK : suggestionsDN}
              // onSelect={handleSelect}
              onSelect={onDiemTraSelected}
              className="w-full !h-10 "
              value={diemTraAdd}
              // onChange={setValue}
            >
              <input
                type="text"
                placeholder="Nhập điểm trả"
                className="w-full !h-10 text-base p-4 !border-2 !rounded-md !border-slate-300"
                value={diemTraAdd}
                onChange={(e) => {
                  setDiemTraAdd(e.target.value);
                  tuyenId == "DNTK"
                    ? debouncedSearchTK(e.target.value)
                    : debouncedSearchDN(e.target.value);
                }}
                // onKeyDown={handleSearch}
              />
            </AutoComplete>
          </div>
        </div>
      </Form>
      <div className="h-full">
        <div style={{ height: "100%", width: "100%" }} id="mapPopup" className="map-container" />
      </div>
    </div>
  );
}

export default MapPopupDetail;
