import axiosClient from "core/axiosClient";
const ticketApi = {
  getTicket: (params) => {
    const url = "/api/ticket/getTicket";
    return axiosClient.post(url, params);
  },
  saveTicket: (params) => {
    const url = "/api/ticket/saveTicket";
    return axiosClient.post(url, params);
  },

  updateTicketStatus: (params) => {
    const url = "/api/ticket/updateTicketStatus";
    return axiosClient.post(url, params);
  },

  updateTicket: (params) => {
    const url = "/api/ticket/updateTicket";
    return axiosClient.post(url, params);
  },
};
export default ticketApi;
