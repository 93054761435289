import { Col, Row, Table, Tooltip, Button, Modal, Tag } from "antd";
import { PAGE } from "core/Constants";
import React, { useEffect, useState } from "react";
import { EditOutlined, CloseOutlined } from "@ant-design/icons";
import CarDetail from "./popup/CarDetail";
import carApi from "api/Call/carApi";

const { confirm } = Modal;

function CarTable({ dataTable, loading }) {
  const [pageIndex, setPageIndex] = useState(PAGE.DEFAULT_PAGE);
  const [indexSelect, setIndexSelect] = useState("");
  const [pageSize, setPageSize] = useState(14);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [record, setRecord] = useState();
  const [dataCar, setDataCar] = useState({ newStatus: true });

  const onTableChange = (pagination) => {
    setPageIndex(pagination.current);
    setPageSize(pagination.pageSize);
  };
  const onRowClassName = (record, index) => {
    return (
      (index % 2 === 0 ? "table-row-light " : "table-row-dark") +
      (index === indexSelect ? " ant-table-row-selected" : "")
    );
  };

  const onRow = (record, index) => {
    return {
      onClick: () => {
        setRecord(record);
        setIndexSelect(index);
      },
    };
  };

  const onClose = () => {
    setIsOpenDetail(false);
  };

  const onEdit = (param) => {
    setDataCar({ ...param, newStatus: false });
    setIsOpenDetail(true);
  };

  const onBlock = (param) => {
    confirm({
      title: "Bạn có chắc chắn muốn khóa xe này",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        const data = {
          ...param,
          carStatus: "N",
        };
        await carApi.updateCarStatus(data);
      },
      onCancel() {
        return;
      },
    });
  };

  const defineInventoryHis = [
    {
      title: "#",
      dataIndex: "carId",
      responsive: ["md"],
      width: "60px",
      align: "center",
      render: (text, object, index) => index + 1,
    },

    {
      title: "Tên xe",
      dataIndex: "carName",
      responsive: ["md"],
      align: "left",
    },
    {
      title: "Loại xe",
      dataIndex: "carType",
      responsive: ["md"],
      width: "100px",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Biển số xe",
      dataIndex: "carNumber",
      responsive: ["md"],
      width: "150px",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Trạng thái",
      dataIndex: "carStatus",
      responsive: ["md"],
      width: "150px",
      ellipsis: true,
      align: "center",
      render: (text, record, index) =>
        record.carStatus === "Y" ? (
          <Tag color={"green"} key="Y">
            Đang hoạt động
          </Tag>
        ) : (
          <Tag color={"volcano"} key="Y">
            Dừng hoạt động
          </Tag>
        ),
    },
    {
      title: "Thao Tác",
      dataIndex: "ttlQty",
      responsive: ["md"],
      width: "120px",
      inputType: "number",
      require: false,
      align: "center",
      render: (_, record) => (
        <div className="flex justify-center">
          <Tooltip title="Chỉnh sửa" color={"purple"} key={"purple"}>
            <Button
              icon={<EditOutlined style={{ fontSize: "20px", color: "purple" }} />}
              onClick={() => {
                onEdit(record);
              }}
              type="text"
            />
          </Tooltip>
          <Tooltip title="Khóa" color={"red"} key={"red"}>
            <Button
              icon={<CloseOutlined style={{ fontSize: "20px", color: "red" }} />}
              onClick={() => {
                onBlock(record);
              }}
              type="text"
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="table-car-list">
        <Table
          columns={defineInventoryHis}
          dataSource={dataTable}
          rowKey={(record) => record.carId}
          rowClassName={onRowClassName}
          // pagination={{
          //   pageSize: Math.floor((height * 0.68) / 43),
          //   showSizeChanger: false,
          //   onChange: handlePageChange,
          //   current: currentPage,
          // }}
          bordered
          onChange={onTableChange}
          scroll={{ y: 1100 }}
          onRow={onRow}
          pagination={false}
          loading={loading}
        />
      </div>
      <CarDetail data={dataCar} isOpen={isOpenDetail} onClose={onClose}></CarDetail>
    </>
  );
}

export default CarTable;
