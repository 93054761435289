import axiosClient from "core/axiosClient";
const menuApi = {
  listMenu: () => {
    const url = "/api/auth/listMenu";
    return axiosClient.get(url);
  },
  loadAllMenu: () => {
    const url = "/api/search-data-for-menu";
    return axiosClient.get(url);
  },
};
export default menuApi;
