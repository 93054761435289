import { configureStore } from "@reduxjs/toolkit";
import userReducer from "redux/userSlide";
import menuReducer from "redux/menuSlice";
import userPopupReducer from "redux/popup/userPopupSlice";
import menuMgtReducer from "redux/system/menuMgtSlice";
import callingSlice from "redux/callingSlice";
import userMgtReducer from "redux/system/userMgtSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    menu: menuReducer,
    userPopup: userPopupReducer,
    menuMgt: menuMgtReducer,
    calling: callingSlice,
    userMgt: userMgtReducer,
  },
});

export default store;
