import { Col, Tree, Button, Modal, Spin, message } from "antd";
import { StopOutlined, FolderAddOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchDataForMenu, searchButtonForMenu, deleteMenu } from "redux/system/menuMgtAction";
import { actionMenuMgt } from "redux/system/menuMgtSlice";
import { useTranslation } from "react-i18next";
import { getChildByMenuName, _filterTreeByTitle, _buildTreeData } from "../../../../utils/common";

const { confirm } = Modal;

function MenuTreeView({ nameSearch, checkDataChanged }) {
  const [dataTreeMenu, setDataTreeMenu] = useState([]);
  const [selectNodeMenu, setSelectNodeMenu] = useState([]);
  const [parentId, setParentId] = useState([]);
  const [selectedKey, setSelectedKey] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [autoExpand, setAutoExpand] = useState(false);
  const [expandKey, setExpandKey] = useState([]);
  const [successDelete, setsuccessDelete] = useState(true);
  const [height, setHeight] = useState(window.innerHeight - 360);
  const [messageApi, contextHolder] = message.useMessage();
  const { menuList, loadingTree } = useSelector((state) => state.menuMgt);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight - 360);
    }
    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => {
    convertDataMenu(menuList);
  }, [menuList]);

  useEffect(() => {
    dispatch(searchDataForMenu());
  }, [successDelete]);

  useEffect(() => {
    setDataFilter(_filterTreeByTitle(dataTreeMenu, nameSearch));
    if (nameSearch === "") {
      setAutoExpand(false);
    }
  }, [nameSearch, dataTreeMenu]);

  useEffect(() => {
    if (nameSearch != "") {
      const arr = getChildByMenuName(dataFilter, nameSearch);
      setExpandKey(arr);
      setAutoExpand(true);
    }
  }, [dataFilter, dataTreeMenu]);

  const convertDataMenu = (pLsMenu) => {
    const lsData = (pLsMenu || []).map((obj) => ({
      title: obj.mnuPgmNm,
      key: obj.mnuPgmId,
      level: obj.level,
      prntId: obj.prntId,
      children: [],
      mnuPgmId: obj.mnuPgmId,
      menuTp: obj.mnuFlag === "Y" ? 0 : 1,
      mnuPgmNmEn: obj.mnuPgmNmEn,
      mnuPgmNmVi: obj.mnuPgmNmVi,
      mnuPgmNmKo: obj.mnuPgmNmKo,
      mnuPgmUrl: obj.mnuPgmUrl,
      mnuPgmRmk: obj.mnuPgmRmk,
      mnuUsd: obj.mnuUsd === "Y" ? true : false,
      icon: obj.mnuUsd === "N" ? <StopOutlined style={{ color: "red", marginRight: "5px" }} /> : "",
    }));
    setDataTreeMenu(_buildTreeData(lsData, "0", "prntId", "key"));
  };

  const addNode = () => {
    let newNode = {
      title: "New folder",
      key: "new",
      isDirectory: true,
      children: [],
      prntId: parentId,
      mnuUsd: "Y",
      use: true,
      popupFlg: "N",
      menuFlg: "N",
      mnuPgmNmEn: "",
      mnuPgmNmVi: "",
      mnuPgmNmKo: "",
      mnuPgmUrl: "",
      mnuPgmRmk: "",
    };
    const newTreeData = [...dataTreeMenu];
    if (!selectNodeMenu.pos) {
      newNode = {
        ...newNode,
        mnuPgmOrdNo: dataTreeMenu.length + 1,
        menuTp: 0,
      };
      newTreeData.push(newNode);
    } else {
      const { pos } = selectNodeMenu;
      const posArr = pos.split("-").map(Number);
      switch (posArr.length) {
        case 2:
          newNode = { ...newNode, mnuPgmOrdNo: posArr[1] + 1, menuTp: 1 };
          newTreeData[posArr[1]].children.push(newNode);
          break;
        case 3:
          newNode = { ...newNode, mnuPgmOrdNo: posArr[2] + 1, menuTp: 1 };
          newTreeData[posArr[1]].children[posArr[2]].children.push(newNode);
          break;
        case 4:
          newNode = { ...newNode, mnuPgmOrdNo: posArr[3] + 1, menuTp: 1 };
          newTreeData[posArr[1]].children[posArr[2]].children[posArr[3]].children.push(newNode);
          break;
        default:
          newTreeData.push(newNode);
          break;
      }
    }
    setDataTreeMenu(newTreeData);
    setSelectedKey([newNode.key]);
    dispatch(actionMenuMgt.storeMenuVO(newNode));
  };

  const handleNew = () => {
    if (selectedKey !== "" && checkDataChanged()) {
      confirm({
        title: "Select Other?",
        content: "Changes you made may not be saved",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          addNode();
        },
        onCancel() {},
      });
    } else {
      addNode();
    }
  };

  const onSelect = (selectedKeys, e) => {
    if (
      (selectedKey !== "" && selectedKeys !== selectedKey && checkDataChanged()) ||
      selectedKey[0] === "new"
    ) {
      confirm({
        title: "Select Other?",
        content: "Changes you made may not be saved",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          convertDataMenu(menuList);
          setSelectedKey(selectedKeys);
          let data = {
            mnuPgmId: e.node.key,
            menuTp: e.node.menuTp,
            prntId: e.node.prntId,
            mnuPgmNmEn: e.node.mnuPgmNmEn,
            mnuPgmNmVi: e.node.mnuPgmNmVi,
            mnuPgmNmKo: e.node.mnuPgmNmKo,
            mnuPgmUrl: e.node.mnuPgmUrl,
            mnuPgmRmk: e.node.mnuPgmRmk,
            mnuUsd: e.node.mnuUsd,
          };
          dispatch(actionMenuMgt.storeMenuVO(data));
          dispatch(searchButtonForMenu({ mnuPgmId: selectedKeys[0] }));
          setSelectNodeMenu(e.node);
          setParentId(selectedKeys[0]);
          dispatch(
            actionMenuMgt.storeMenuVoNew({
              mnuPgmId: "",
              menuTp: "",
              prntId: "",
              mnuPgmOrdNo: "",
              mnuPgmNmEn: "",
              mnuPgmNmVi: "",
              mnuPgmNmKo: "",
              mnuPgmUrl: "",
              mnuPgmRmk: "",
              mnuUsd: "",
            })
          );
        },
        onCancel() {},
      });
    } else {
      setSelectedKey(selectedKeys);
      let data = {
        mnuPgmId: e.node.key,
        menuTp: e.node.menuTp,
        prntId: e.node.prntId,
        mnuPgmNmEn: e.node.mnuPgmNmEn,
        mnuPgmNmVi: e.node.mnuPgmNmVi,
        mnuPgmNmKo: e.node.mnuPgmNmKo,
        mnuPgmUrl: e.node.mnuPgmUrl,
        mnuPgmRmk: e.node.mnuPgmRmk,
        mnuUsd: e.node.mnuUsd,
      };
      dispatch(actionMenuMgt.storeMenuVO(data));
      dispatch(searchButtonForMenu({ mnuPgmId: selectedKeys[0] }));
      setSelectNodeMenu(e.node);
      setParentId(selectedKeys[0]);
    }
  };
  const onDelete = () => {
    if (selectedKey) {
      confirm({
        title: "Do you want to delete this item?",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          let obj = { mnuPgmId: selectedKey[0] };
          dispatch(deleteMenu(obj))
            .then(() => {
              messageApi.open({
                type: "success",
                content: "Deleted successfully!",
              });
              setsuccessDelete(!successDelete);
            })
            .catch(() => {
              messageApi.open({
                type: "failed",
                content: "Deleted fail!",
              });
            });
        },
        onCancel() {
          return;
        },
      });
    }
  };
  const renderTreeNodes = (data) => {
    return (
      <div>
        {data.icon}
        {data.title}
      </div>
    );
  };

  const onExpand = (values) => {
    setExpandKey(values);
  };

  return (
    <Col className="left-box menu-tree" span={6} offset={1}>
      {contextHolder}
      <Spin spinning={loadingTree}>
        <Tree
          defaultExpandParent
          showLine
          onExpand={onExpand}
          autoExpandParent={autoExpand}
          expandedKeys={expandKey}
          height={height}
          onSelect={onSelect}
          treeData={nameSearch == "" || nameSearch == undefined ? dataTreeMenu : dataFilter}
          titleRender={renderTreeNodes}
          selectedKeys={selectedKey}
          switcherIcon={<DownOutlined />}
        />
      </Spin>
      <div className="control-tree">
        <Button
          className="btn-left"
          type="primary"
          htmlType="submit"
          icon={<FolderAddOutlined />}
          onClick={handleNew}
          disabled={selectedKey[0] === "new" || selectNodeMenu.menuTp === 1}
        >
          {t("buttons.new")}
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          onClick={onDelete}
          icon={<DeleteOutlined />}
          disabled={Object.keys("recordButton").length > 0 || "isNew" == true ? false : true}
        >
          {t("buttons.delete")}
        </Button>
      </div>
    </Col>
  );
}

export default MenuTreeView;
