import React, { useEffect } from "react";
import Stopwatch from "./StopWatch";
import { PhoneFilled } from "@ant-design/icons";
import { Button } from "antd";
import { useSelector } from "react-redux";
const Background = () => {
  // state to store time
  const { status, acceptCall, phoneNumber, hangup } = useSelector((state) => state.calling);

  useEffect(() => {}, [phoneNumber, acceptCall, status]);

  const endCall = () => {
    window.omiSDK.stopCall();
  };

  return (
    <div
      className={
        ((!hangup && acceptCall) || status === "outbound" ? "" : "hidden ") +
        "pb-2 shadow-xl rounded-md h-28 bg-gradient-to-b from-blue-500 to-cyan-500"
      }
    >
      <div className="flex justify-center py-8 px-8">
        <div className="h-12">
          <p className="m-0 text-xl font-semibold text-white">{phoneNumber}</p>
          <Stopwatch show={true} run={acceptCall} />
        </div>

        <Button
          onClick={() => {
            endCall();
          }}
          shape="circle"
          className="!bg-[#dc4848] !ml-4 !h-12 !w-12"
          icon={<PhoneFilled rotate={225} style={{ fontSize: "20px", color: "#ffffff" }} />}
          size="large"
        />
      </div>
    </div>
  );
};

export default Background;
