import React from "react";
import { Button, Layout, Result } from "antd";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  const handClick = () => {
    navigate("/");
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry this page not found"
        extra={
          <Button type="primary" onClick={handClick}>
            Back Home
          </Button>
        }
      />
    </Layout>
  );
}

export default NotFound;
