import axiosClient from "core/axiosClient";
const tripPlanApi = {
  getTripPlan: () => {
    const url = "/api/trip-plan/getTripPlan";
    return axiosClient.get(url);
  },
  getDriverPlan: (params) => {
    const url = "/api/trip-plan/getDriverPlan";
    return axiosClient.get(url, { params });
  },
  saveTripPlan: (params) => {
    const url = "/api/trip-plan/saveTripPlan";
    return axiosClient.post(url, params);
  },
};
export default tripPlanApi;
