const { default: axiosClient } = require("core/axiosClient");

const menuMgtApi = {
  searchDataForMenu: () => {
    const url = "/api/search-data-for-menu";
    return axiosClient.get(url);
  },
  searchButtonForMenu: (params) => {
    const url = "/api/search-button-for-menu";
    return axiosClient.get(url, { params: params });
  },
  searchDataForPopup: (params) => {
    const url = "/api/search-data-for-popup";
    return axiosClient.get(url, params);
  },
  updateMenu: (params) => {
    const url = "/api/update-menu";
    return axiosClient.put(url, params);
  },
  savePopup: (params) => {
    const url = "/api/update-popup";
    return axiosClient.put(url, params);
  },
  createMenu: (params) => {
    const url = "/api/create-menu";
    return axiosClient.post(url, params);
  },
  createPopup: (params) => {
    const url = "/api/create-popup";
    return axiosClient.post(url, params);
  },
  deleteMenu: (params) => {
    const url = "/api/delete-menu";
    return axiosClient.put(url, params);
  },
  saveOrderMenu: (params) => {
    const url = "/api/save-order-menu";
    return axiosClient.put(url, params);
  },

  searchButtonList: () => {
    const url = "/api/search-button-list";
    return axiosClient.get(url);
  },

  searchItemList: (params) => {
    const url = "/api/search-itm-list";
    return axiosClient.get(url, params);
  },
};

export default menuMgtApi;
