import React, { useEffect, useState } from "react";
import { Button, Col, Form, Radio, message, Spin, Space } from "antd";
import { SelectCustom, DatePickerCustom } from "components";
import { RetweetOutlined, RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import khungGioApi from "api/Call/khungGioApi";
import tripPlanApi from "api/Call/tripPlanApi";
import tripRecordApi from "api/Call/tripRecord";
import moment from "moment";
import { useLocation } from "react-router-dom";
import TaixePopup from "./popup/TaixePopup";
import _ from "lodash";
import "moment/locale/vi";

moment.locale("vi");

function TripControl({
  setTripOrderFunc,
  setAddFlgFunc,
  setGhiChuFlgFunc,
  setGheFlgFunc,
  setReloadFlgFunc,
  addFlg,
  ghiChuFlg,
  gheFlg,
  reloadFlg,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [listKhungGio, setListKhungGio] = useState([]);
  const [loading, setLoading] = useState(false);
  const [driverPlan, setDriverPlan] = useState([]);
  const [driverOrders, setDriverOrders] = useState([]);
  const [khungGioId, setKhungGioId] = useState();
  const [date, setDate] = useState(
    moment().isAfter(moment(moment().format("DD/MM/YYYY") + "19:30", "DD/MM/YYYYHH:mm"))
      ? moment().add(1, "d").format("DD/MM/YYYY")
      : moment().format("DD/MM/YYYY")
  );
  const [waringBox, setWaringBox] = useState({ status: false, soKhach: "", khungGioName: "" });
  const [dataPopup, setDataPopup] = useState({
    date: "",
    driverId: "",
    khungGioId: "",
    ngayPlan: "",
    driverName: "",
    khungGioName: "",
    tripStatus: "",
  });
  const [showPopup, setShowPopup] = useState(false);
  const [tuyenId, setTuyenId] = useState("TKDN");

  useEffect(() => {
    form.setFieldsValue({
      ngayDi: moment(date, "DD/MM/YYYY"),
      tuyen: "TKDN",
      ngayDiView: moment(),
      tuyenView: "TKDN",
      soGhe: "1",
    });
    getDriverPlan({ ngayPlan: date });

    // if (moment().isAfter(moment(moment().format("DD/MM/YYYY") + "19:30", "DD/MM/YYYYHH:mm"))) {
    //   setWaringBox({ status: true, soKhach: "", khungGioName: "" });
    // }
  }, []);
  // set warning message
  useEffect(() => {
    if (listKhungGio.length > 0) {
      const warn = listKhungGio.find((item) => {
        return item.khungGioName.substring(0, 2) == moment().add(1, "h").format("HH");
      });
      if (warn) {
        checkWarningBox(warn);
      }
    }
  }, [listKhungGio]);

  const checkWarningBox = async (warn) => {
    const result = await tripRecordApi.getTripOrders({
      tripStatus: "Y",
      ngayPlan: date,
      khungGioId: warn.khungGioId,
    });

    if (result.success) {
      const warnKhach = result.data
        .filter((item) => {
          return item.tripPlanId == 0;
        })
        .reduce((n, { soKhach }) => n + soKhach, 0);
      if (warnKhach > 0) {
        setWaringBox({ status: true, soKhach: warnKhach, khungGioName: warn.khungGioName });
      } else {
        setWaringBox({ status: false, soKhach: "", khungGioName: "" });
      }
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      khungGioId: null,
    });
    setTripOrderFunc([], [], listKhungGio);
    onReload(khungGioId);
  }, [date]);
  useEffect(() => {
    form.setFieldsValue({
      khungGioId: null,
    });
    setTripOrderFunc([], [], listKhungGio);
    setKhungGioId(null);
    onReload("");
  }, [tuyenId]);

  useEffect(() => {
    onReload(khungGioId);
  }, [reloadFlg]);

  const onReload = async (reloadValue) => {
    setLoading(true);
    await setDriverOrders([]);
    const resultDriverPlan = await tripPlanApi.getDriverPlan({ ngayPlan: date });
    const resultKhungGio = await khungGioApi.getKhungGio({
      ngayPlan: date,
      tuyen: tuyenId,
    });

    if (resultKhungGio.success && resultDriverPlan.success) {
      setDriverPlan(resultDriverPlan.data);
      if (resultKhungGio.data.length > 0) {
        const ls = resultKhungGio.data.map((e) => {
          e.soKhachChuyen = 0;
          resultDriverPlan.data.map((o) => {
            if (o.khungGioId === e.khungGioId) {
              e.soKhachChuyen = e.soKhachChuyen + Number(o.carType);
            }
          });
          if (
            moment()
              .add(-90, "minutes")
              .isAfter(moment(date + e.khungGioName, "DD/MM/YYYYHH:mm"))
          ) {
            if (e.soKhach == 0 && e.soKhachChuyen == 0) {
              e.color = "text-gray-400 ";
            } else if (e.soKhach > e.soKhachChuyen) {
              e.color = "text-red-300 ";
            } else if (e.soKhach < e.soKhachChuyen) {
              e.color = "text-blue-300 ";
            } else {
              e.color = "text-green-300 ";
            }
          } else {
            if (e.soKhach == 0 && e.soKhachChuyen == 0) {
              e.color = "";
            } else if (e.soKhach > e.soKhachChuyen) {
              e.color = "text-red-500 ";
            } else if (e.soKhach < e.soKhachChuyen) {
              e.color = "text-blue-500 ";
            } else {
              e.color = "text-green-500 ";
            }
          }
          return e;
        });
        setLoading(false);
        setListKhungGio(ls);
      }
      const data = resultDriverPlan.data.filter((item) => {
        return Number(item.khungGioId) == Number(khungGioId);
      });
      if (_.isNumber(reloadValue)) {
        const result = await tripRecordApi.getTripOrders({
          tripStatus: "Y",
          ngayPlan: date,
          khungGioId: Number(khungGioId),
        });
        setTripOrderFunc(result.data, data, listKhungGio);
        setDriverOrders(data);
      }
    } else {
      message.error("Lấy khung giờ lỗi");
    }
  };

  const getDriverPlan = async (param) => {
    setLoading(true);
    const resultDriverPlan = await tripPlanApi.getDriverPlan(param);
    const resultKhungGio = await khungGioApi.getKhungGio({
      ngayPlan: date,
      tuyen: tuyenId,
    });

    if (resultKhungGio.success && resultDriverPlan.success) {
      setDriverPlan(resultDriverPlan.data);
      if (resultKhungGio.data.length > 0) {
        const ls = resultKhungGio.data.map((e) => {
          e.soKhachChuyen = 0;
          resultDriverPlan.data.map((o) => {
            if (o.khungGioId === e.khungGioId) {
              e.soKhachChuyen = e.soKhachChuyen + o.carType;
            }
          });
          if (
            moment()
              .add(-90, "minutes")
              .isAfter(moment(date + e.khungGioName, "DD/MM/YYYYHH:mm"))
          ) {
            e.color = "text-gray-400 ";
          } else if (e.soKhach == 0) {
            e.color = "";
          } else if (e.soKhach > e.soKhachChuyen) {
            e.color = "text-red-500 ";
          } else if (e.soKhach < e.soKhachChuyen) {
            e.color = "text-blue-500 ";
          } else {
            e.color = "text-green-500 ";
          }
          return e;
        });
        setLoading(false);
        setListKhungGio(ls);
      }
    } else {
      message.error("Lấy khung giờ lỗi");
    }
  };

  const handleChangeKhungGio = async (value) => {
    const data = await driverPlan.filter((item) => {
      return Number(item.khungGioId) == Number(value);
    });
    if (_.isNumber(value)) {
      setKhungGioId(value);
      const result = await tripRecordApi.getTripOrders({
        tripStatus: "Y",
        ngayPlan: date,
        khungGioId: Number(value),
      });
      setTripOrderFunc(result.data, data, listKhungGio);
      setDriverOrders(data);
    }
  };

  const handleClickTaixe = (item) => {
    let data = {
      date: date,
      driverId: item.driverId,
      khungGioId: item.khungGioId,
      ngayPlan: item.ngayPlan,
      driverName: item.driverName,
      khungGioName: item.khungGioName,
      tripStatus: "Y",
    };
    setDataPopup(data);
    handleShowPopup();
  };

  const handleShowPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const toggleTuyen = () => {
    if (tuyenId === "TKDN") {
      form.setFieldsValue({
        tuyen: "DNTK",
      });
      setTuyenId("DNTK");
    } else {
      form.setFieldsValue({
        tuyen: "TKDN",
      });
      setTuyenId("TKDN");
    }
  };

  const handleNext = () => {
    form.setFieldsValue({
      ngayDi: moment(date, "DD/MM/YYYY").add(1, "d"),
    });
    setDate(moment(moment(date, "DD/MM/YYYY").add(1, "d")).format("DD/MM/YYYY"));
  };

  return (
    <Col className="left-box menu-detail">
      <Spin spinning={loading}>
        <Form
          form={form}
          name="menu-detail"
          colon={false}
          layout="horizontal"
          // onFinish={onSave}
          // onChange={onChangeAll}
        >
          <div className="form-detail">
            <div>
              <div className="flex items-center pb-4 m-0">
                <Button
                  className="w-18 !ml-0"
                  type="primary"
                  onClick={setAddFlgFunc}
                  danger={addFlg}
                >
                  Địa chỉ
                </Button>
                <Button
                  className="w-18 !ml-2"
                  type="primary"
                  // icon={<SaveOutlined />}
                  onClick={setGhiChuFlgFunc}
                  danger={ghiChuFlg}
                >
                  Ghi chú
                </Button>
                <Button
                  className="!w-20 !ml-2"
                  type="primary"
                  // icon={<SaveOutlined />}
                  onClick={setGheFlgFunc}
                  danger={gheFlg}
                >
                  Ghế
                </Button>
                <Button
                  className="w-18 !ml-2 !bg-green-500 !text-white"
                  onClick={setReloadFlgFunc}
                  disabled={
                    Object.keys("recordButton").length > 0 || "isNew" == true ? false : true
                  }
                  // danger={reloadFlg}
                >
                  Tải lại
                </Button>
              </div>
              <div className="flex">
                <Form.Item name="tuyen">
                  <SelectCustom
                    onChange={(value) => setTuyenId(value)}
                    allowClear
                    options={[
                      { value: "TKDN", label: "Tam Kỳ - Đà Nẵng" },
                      { value: "DNTK", label: "Đà Nẵng - Tam Kỳ" },
                    ]}
                    label="Tuyến"
                    placeholdercustom="Tuyến"
                    // style={{ width: "288px" }}
                    // loading={loadingSelect}
                    // showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                  ></SelectCustom>
                </Form.Item>
                <Button
                  className="!rounded-md ml-2"
                  size="large"
                  icon={<RetweetOutlined />}
                  onClick={toggleTuyen}
                />
              </div>
              <div className="flex">
                <Form.Item
                  name="ngayDi"
                  rules={[
                    {
                      required: true,
                      message: t("UI_ADM_006.menuDetail.engNmReq"),
                    },
                  ]}
                >
                  <DatePickerCustom
                    className="w-full"
                    label={t("Ngày đi")}
                    format="DD-MM (dddd)"
                    onChange={(date, dateString) => setDate(moment(date).format("DD/MM/YYYY"))}
                    placeholder=""
                  />
                </Form.Item>
                <Button
                  icon={<RightOutlined />}
                  onClick={handleNext}
                  size="large"
                  className="ml-2 !rounded-md"
                />
              </div>
            </div>
            <div className="mt-2 border-slate-400 border-b-2 border-gray-500">
              <Form.Item
                name="khungGioId"
                className="!mb-2"
                rules={[
                  {
                    required: true,
                    message: t("UI_ADM_006.menuDetail.typeMnReq"),
                  },
                ]}
              >
                <Radio.Group
                  className="w-96 "
                  onChange={(e) => {
                    handleChangeKhungGio(e.target.value);
                  }}
                >
                  {listKhungGio.map((khunggio, index) => {
                    return (
                      <Radio
                        className={
                          index === 0 ||
                          index === 1 ||
                          index === 4 ||
                          index === 5 ||
                          index === 8 ||
                          index === 9 ||
                          index === 12 ||
                          index === 13 ||
                          index === 16 ||
                          index === 17 ||
                          index === 20 ||
                          index === 21 ||
                          index === 24 ||
                          index === 25 ||
                          index === 28
                            ? "bg-slate-200 w-40 !pl-2 !m-0 !pt-2 !pb-0 !pb-0"
                            : "w-40 !pl-2 !mr-0 !pt-2"
                        }
                        key={khunggio.khungGioId}
                        value={khunggio.khungGioId}
                      >
                        <p className={khunggio.color + "text-base font-bold mb-1"}>
                          {khunggio.khungGioName} - {khunggio.soKhach}/{khunggio.soKhachChuyen}
                        </p>
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>

              {waringBox.status && (
                <div className="bg-red-500 p-4 mb-1 rounded-md flex">
                  <p className="text-white font-normal text-xl m-0 mr-2">{waringBox.soKhach}</p>
                  <p className="text-white font-light text-base m-0 mr-2 mt-0.5">khách</p>
                  <p className="text-white font-normal text-lg m-0 mr-2">
                    {waringBox.khungGioName}
                  </p>
                  <p className="text-white font-light text-base m-0 mt-0.5">chưa được gán</p>
                </div>
              )}
            </div>
            <div className="pt-2 w-48">
              {driverOrders.map((item, index) => {
                return (
                  <Button
                    key={index}
                    className="w-25 !ml-0 !mb-3 ! pl-0"
                    type="text"
                    onClick={() => handleClickTaixe(item)}
                  >
                    <div className="flex">
                      <p
                        className={
                          index === 0
                            ? "bg-[#260707] p-1.5 rounded-full text-white w-8 h-8 text-center text-sm font-bold !mb-0"
                            : index === 1
                            ? "bg-[#da1313] p-1.5 rounded-full text-white w-8 h-8 text-center text-sm font-bold !mb-0"
                            : index === 2
                            ? "bg-[#1b2dd3] p-1.5 rounded-full text-white w-8 h-8 text-center text-sm font-bold !mb-0"
                            : index === 3
                            ? "bg-[#0de053] p-1.5 rounded-full text-white w-8 h-8 text-center text-sm font-bold !mb-0"
                            : index === 4
                            ? "bg-[#fd7100] p-1.5 rounded-full text-white w-8 h-8 text-center text-sm font-bold !mb-0"
                            : index === 5
                            ? "bg-[#567328] p-1.5 rounded-full text-white w-8 h-8 text-center text-sm font-bold !mb-0"
                            : index === 6
                            ? "bg-[#5cb5b6] p-1.5 rounded-full text-white w-8 h-8 text-center text-sm font-bold !mb-0"
                            : index === 7
                            ? "bg-[#6880e8] p-1.5 rounded-full text-white w-8 h-8 text-center text-sm font-bold !mb-0"
                            : index === 8
                            ? "bg-[#f16787] p-1.5 rounded-full text-white w-8 h-8 text-center text-sm font-bold !mb-0"
                            : "" +
                              "bg-gray-40 p-1.5 rounded-full w-8 h-8 text-center text-sm font-bold !mb-0"
                        }
                      >
                        {item.soDriverKhach}
                      </p>
                      <p className="text-center ml-1 p-1 rounded-full h-8 text-base font-medium !mb-0">
                        {item.driverName}
                      </p>
                    </div>
                  </Button>
                );
              })}
            </div>
          </div>
        </Form>
      </Spin>
      <TaixePopup
        show={showPopup}
        data={dataPopup}
        driverList={driverOrders}
        khungGioList={listKhungGio}
        onclose={handleClosePopup}
      ></TaixePopup>
    </Col>
  );
}
export default TripControl;
