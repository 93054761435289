import React, { Suspense, useState } from "react";
import { Layout, Spin, Affix, Button } from "antd";
import { MenuOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import HeaderCommon from "./HeaderCommon.jsx";
import MenuApp from "./MenuApp.jsx";
import "./styles/SiteLayout.scss";
import bgSidebar from "../assets/images/sidebar/bgSidebar.png";
import CallPopup from "./callPopup/CallPopup.jsx";
import InComingCall from "./callPopup/IncomingCall.jsx";
import { useDispatch, useSelector } from "react-redux";
import Draggable from "react-draggable";
import TripRecord from "./tripRecord/TripRecord.jsx";
import Background from "./callPopup/Background.jsx";
import AnotherCall from "./callPopup/AnotherCall.jsx";
import { openCallPopup } from "redux/callingSlice.js";

const { Sider } = Layout;

function SiteLayout({ children }) {
  const dispatch = useDispatch();
  const { callPopup } = useSelector((state) => state.calling);

  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const [theme, setTheme] = useState("dark");
  const changeTheme = () => {
    setTheme(theme == "dark" ? "light" : "dark");
  };

  return (
    <Suspense fallback={<Spin />}>
      <Layout
        style={{
          minHeight: "100vh",
          backgroundColor: "#f4f5fa",
        }}
      >
        <Affix
          className="icon-call-global"
          style={{ position: "absoluted", bottom: 20, right: 20, zIndex: 1000000 }}
        >
          <Button
            type="primary"
            onClick={() => {
              dispatch(openCallPopup());
            }}
            shape="circle"
            icon={callPopup ? <MenuFoldOutlined /> : <MenuOutlined />}
            size="large"
          />
        </Affix>
        <Draggable>
          <Affix
            className="icon-call-global"
            style={{ position: "absoluted", bottom: 20, right: 80, zIndex: 1000000 }}
          >
            <CallPopup />
          </Affix>
        </Draggable>
        <Draggable>
          <Affix
            className="icon-call-global"
            style={{
              position: "absoluted",
              top: "40%",
              left: "40%",
              zIndex: 1000000,
            }}
          >
            <Draggable>
              <InComingCall />
            </Draggable>
          </Affix>
        </Draggable>
        <Draggable>
          <Affix
            className="icon-call-global"
            style={{
              position: "absoluted",
              bottom: 20,
              right: 80,
              zIndex: 1000000,
            }}
          >
            <Draggable>
              <Background />
            </Draggable>
          </Affix>
        </Draggable>
        <Draggable>
          <Affix
            className="icon-call-global"
            style={{
              position: "absoluted",
              bottom: 20,
              right: 80,
              zIndex: 1000000,
            }}
          >
            <Draggable>
              <AnotherCall />
            </Draggable>
          </Affix>
        </Draggable>
        <TripRecord />
        <Sider
          trigger={null}
          className={"menu-side-bar custom-themes-" + theme}
          collapsed={collapsed}
        >
          <div className="pt-8 pb-4 px-0 text-center">
            {collapsed ? (
              <MenuOutlined onClick={toggleCollapsed} />
            ) : (
              <div className="flex justify-between">
                <div className="w-0"></div>
                <div className="w-full">
                  <Link className="w-full" to="/">
                    <p className="text-center w-full text-lg align-middle">Vũ Gia</p>
                  </Link>
                </div>
                <div onClick={toggleCollapsed} className="btn_toggle">
                  {!collapsed && (
                    <MenuFoldOutlined
                      style={{ fontSize: "24px", color: "#FFF" }}
                      onClick={toggleCollapsed}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          {/* <Switch className="btn-switch" onChange={changeTheme} size="small" /> */}

          <div className="img-bottom">
            <img src={bgSidebar} alt="BG sidebar" />
          </div>
          <MenuApp theme={theme} />
        </Sider>
        <Layout className="site-layout">
          <HeaderCommon />
          <Content className="site-layout-background">
            <div className="content-grid">
              <div className="wrap-content-common">{children}</div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </Suspense>
  );
}

export default SiteLayout;
