import { Modal, Form } from "antd";
import React, { useEffect, useState } from "react";
import { InputCustom, SelectCustom, DatePickerCustom } from "components";
import callApi from "api/Call/callApi";
import { PAGE } from "core/Constants";
import driverApi from "api/Call/driverApi";
import moment from "moment";
import "moment/locale/vi";

moment.locale("vi");

function DriverDetail({ isOpen, onClose, data }) {
  const [pageIndex, setPageIndex] = useState(PAGE.DEFAULT_PAGE);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  const [title, setTitle] = useState("Thêm");

  const [form] = Form.useForm();

  useEffect(() => {
    if (isOpen) {
      setCurrentPage(1);
      setPageIndex(1);
      if (data) {
        form.setFieldsValue({
          driverStatus: data.driverStatus,
          driverFullName: data.driverFullName,
          driverName: data.driverName,
          driverEmail: data.driverEmail,
          driverAddress: data.driverAddress,
          driverPhone: data.driverPhone,
          driverIdentify: data.driverIdentify,
          official: data.official,
          driverBirthday: moment(data.driverBirthday),
        });
        setTitle("Sửa");
      } else {
        form.setFieldsValue({
          driverStatus: "Y",
          driverBirthday: moment().add(-24, "y"),
        });
      }
    }
  }, [isOpen]);

  useEffect(() => {
    loadInventoryHistoryDetail(data);
  }, [data]);

  const loadInventoryHistoryDetail = async (param) => {
    if (param) {
      param.custOrdNo = param?.custOrdNo ? param?.custOrdNo.trim() : null;
      try {
        setLoading(true);
        const result = await callApi.getInventoryHistoryDetail(param);
        setDataSource(result.data);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleOk = async () => {
    await onFinish();
  };

  const handleClose = () => {
    onClose(true);
  };

  const onFinish = async () => {
    await form.validateFields().then(() => {
      const params = form.getFieldValue();

      if (data) {
        const param = {
          ...params,
          driverId: data.driverId,
          driverBirthday: params.driverBirthday
            ? moment(params.driverBirthday).format("DD/MM/YYYY")
            : undefined,
        };
        driverApi.updateDriver(param);
      } else {
        const param = {
          ...params,
          driverBirthday: params.driverBirthday
            ? moment(params.driverBirthday).format("DD/MM/YYYY")
            : undefined,
        };
        driverApi.saveDriver(param);
      }

      onClose(true);
    });
  };

  return (
    <>
      <Modal
        className="material-popup"
        onCancel={handleClose}
        maskClosable={false}
        onOk={handleOk}
        okText={title}
        cancelText="Hủy"
        title="Thêm mới tài xế"
        width={"30%"}
        open={isOpen}
      >
        <Form colon={false} onFinish={onFinish} form={form} className="form-control-inventory-his">
          <div className="mb-4">
            <Form.Item
              name="driverFullName"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập họ tên",
                },
              ]}
              className=""
            >
              <InputCustom label="Họ Và Tên" require="true"></InputCustom>
            </Form.Item>
          </div>
          <div className="mb-4">
            <Form.Item
              name="driverName"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập họ tên",
                },
              ]}
              className=""
            >
              <InputCustom label="Tên Gọn" require="true"></InputCustom>
            </Form.Item>
          </div>
          <div className="mb-4">
            <Form.Item
              name="driverPhone"
              // rules={[
              //   {
              //     required: true,
              //     message: "Vui lòng nhập số điện thoại",
              //   },
              // ]}
            >
              <InputCustom label="Số điện thoại"></InputCustom>
            </Form.Item>
          </div>
          <div className="mb-4">
            <Form.Item
              name="driverEmail"
              // rules={[
              //   {
              //     required: true,
              //     message: "Vui lòng nhập biển số xe",
              //   },
              // ]}
            >
              <InputCustom label="Địa chỉ email" require="true"></InputCustom>
            </Form.Item>
          </div>
          <div className="mb-4">
            <Form.Item
              name="driverIdentify"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập cmnd",
                },
              ]}
            >
              <InputCustom label="CMND" require="true"></InputCustom>
            </Form.Item>
          </div>
          <div className="mb-4">
            <Form.Item
              name="driverBirthday"
              // onChange={handleChangeDate}
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập ngày sinh",
                },
              ]}
            >
              <DatePickerCustom
                className="w-full"
                label={"Ngày sinh"}
                format="DD/MM/YYYY"
                placeholder=""
                // onChange={(date, dateString) => onChangeBrdyVal(date, dateString)}
              />
            </Form.Item>
          </div>
          <div className="mb-4">
            <Form.Item name="driverStatus">
              <SelectCustom
                label="Trạng thái"
                require="true"
                disabled={true}
                options={[
                  { value: "Y", label: "Đang hoạt động" },
                  { value: "N", label: "Dừng hoạt động" },
                ]}
                showSearch
                allowClear
              ></SelectCustom>
            </Form.Item>
          </div>
          <div>
            <Form.Item name="official">
              <SelectCustom
                label="NVCT"
                require="true"
                disabled={false}
                options={[
                  { value: "Y", label: "Chính thức" },
                  { value: "N", label: "Thử việc" },
                ]}
                showSearch
                allowClear
              ></SelectCustom>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default DriverDetail;
