import { Avatar, Card, Col, Pagination, Row } from "antd";
import { useState, useEffect } from "react";
import { userMgtSearchAll } from "redux/system/userMgtAction";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

UserMgtList.propTypes = {
  paramSearch: PropTypes.object,
  onRecord: PropTypes.func,
};

UserMgtList.defaultProps = {
  paramSearch: {},
  onRecord: null,
};

function UserMgtList({ paramSearch, onRecord }) {
  const dispatch = useDispatch();
  const { loading, userList } = useSelector((state) => state.userMgt);
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);

  useEffect(() => {
    dispatch(userMgtSearchAll(paramSearch));
  }, [paramSearch]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return userList.slice(startIndex, endIndex);
  };

  const paginatedData = getPaginatedData();

  return (
    <Col className="user-list" span={16} offset={0}>
      <div className="wrap-list">
        <div className="title-component">
          <h3>User List </h3>
        </div>
        <div className="table-component">
          <Row>
            {paginatedData?.map((user) => (
              <Col
                span={12}
                key={user.usrEml}
                onClick={() => {
                  onRecord(user);
                }}
              >
                <Card hoverable={true} loading={loading}>
                  <Card.Meta
                    avatar={<Avatar src={user.imgUrl} />}
                    title={user.usrNm}
                    description={
                      <>
                        <div className="info">
                          <div className="row col">
                            <div className="start-date">{user.detailRole}</div>
                            <div className={"number"}>{user.empeNo}</div>
                          </div>
                          <div>
                            {user.actFlg === "N"
                              ? "Unactivated"
                              : user.actFlg === "Y"
                              ? "Activated"
                              : user.actFlg === undefined
                              ? "Activated"
                              : "Deleted"}
                          </div>
                        </div>
                      </>
                    }
                  />
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        <Pagination
          pageSizeOptions={["10", "20", "50", "100"]}
          defaultPageSize={10}
          showSizeChanger={true}
          style={{ textAlign: "right" }}
          current={currentPage}
          pageSize={pageSize}
          total={userList.length}
          onChange={handlePageChange}
        />
      </div>
    </Col>
  );
}

export default UserMgtList;
