import React, { useState } from "react";
import { Spin } from "antd";
import "./RevenueList.scss";
import RevenueListForm from "./RevenueListForm";
import RevenueListTable from "./RevenueListTable";
import tripRecordApi from "api/Call/tripRecord";

function RevenueList() {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);

  const onData = (value) => {
    setDataTable(value);
  };

  const onLoading = (value) => {
    setLoading(value);
  };

  const handleExport = async (values) => {
    if (dataTable.length > 0) {
      try {
        setLoadingExport(true);
        const response = await tripRecordApi.exportExcelRevenue({
          lsRevenue: dataTable,
          fromDt: values.fromDt,
          toDt: values.toDt,
        });
        const blob = new Blob([response.data], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `DoanhThuTungXe-${values.fromDt}-${values.toDt}.xlsx`;
        link.click();
      } catch (error) {
        throw Error;
      } finally {
        setLoadingExport(false);
      }
    }
  };

  return (
    <div className="inventory-his">
      <Spin spinning={loadingExport}>
        <RevenueListForm onData={onData} handleExport={handleExport} onLoading={onLoading} />
        <RevenueListTable dataTable={dataTable} loading={loading} />
      </Spin>
    </div>
  );
}

export default RevenueList;
