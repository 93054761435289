import { Button, Col, Form, Row } from "antd";
import { SearchOutlined, ExportOutlined } from "@ant-design/icons";
import { InputCustom, SelectCustom, DatePickerCustom } from "components";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import MaterialDetailPopup from "./popup/DriverDetail";
import moment from "moment";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import tripRecordApi from "api/Call/tripRecord";
import khungGioApi from "api/Call/khungGioApi";
dayjs.extend(customParseFormat);
import "moment/locale/vi";

moment.locale("vi");

function TripRecordListForm({ onData, onLoading }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState();
  const [khungGioCombo, setKhungGioCombo] = useState();
  const [isOpenDetail, setIsOpenDetail] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      date: moment(),
    });
    setKhungGio();
    onFinish({ ngayPlan: moment().format("DD/MM/YYYY") });
  }, []);

  const setKhungGio = async () => {
    const resultKhungGio = await khungGioApi.getKhungGioList({
      tuyen: "TKDN",
    });
    if (resultKhungGio.success) {
      const dataParse = [];
      resultKhungGio.data.map((item) =>
        dataParse.push({
          label: item["khungGioName"],
          value: item["khungGioName"],
        })
      );
      setKhungGioCombo(dataParse);
    }
  };

  const onFinish = (values) => {
    getTripOrders(values);
  };

  const onChangeForm = () => {
    const param = form.getFieldValue();

    const ls = dataSource.filter((item) => {
      return (
        (!param.tuyen || item.tuyen === param.tuyen) &&
        (!param.khungGioName || item.khungGioName === param.khungGioName) &&
        (!param.tripStatus || item.tripStatus === param.tripStatus) &&
        (!param.tienFlg || item.tienFlg == param.tienFlg)
      );
    });
    onData(ls);
  };

  const onChangeDate = (param) => {
    getTripOrders(param);
  };

  const getTripOrders = async (params) => {
    try {
      onLoading(true);
      const result = await tripRecordApi.getTripOrders(params);
      if (result.success) {
        setDataSource(result.data);
        onData(result.data);
      }
    } catch (error) {
      onLoading(false);
    } finally {
      onLoading(false);
    }
  };
  const onClose = () => {
    setIsOpenDetail(false);
  };

  return (
    <>
      <Form colon={false} onFinish={onFinish} form={form} className="form-control-inventory-his">
        <div className="flex justify-between">
          <div className="mt-1">
            {/* <Button
              type="primary"
              disabled={false}
              htmlType="submit"
              className="button btn_custom"
              icon={<SearchOutlined />}
            >
              Tìm
            </Button> */}
            {/* <Button
              type="primary"
              className="button btn_custom"
              icon={<ExportOutlined />} 
              onClick={handleAdd}
              loading={loadingExport}
            >
              Thêm mới
            </Button> */}
          </div>
          <div className="flex justify-start">
            <Form.Item name="date" className="!mr-2">
              <DatePickerCustom
                label="Ngày chạy"
                style={{ width: "200px" }}
                format="DD/MM/YYYY"
                onChange={(date) => {
                  onChangeDate({ ngayPlan: moment(date).format("DD/MM/YYYY") });
                }}
              ></DatePickerCustom>
            </Form.Item>
            <Form.Item name="tuyen" className="!mr-2">
              <SelectCustom
                onChange={() => onChangeForm()}
                style={{ width: "180px" }}
                allowClear
                options={[
                  { value: "TKDN", label: "Tam Kỳ - Đà Nẵng" },
                  { value: "DNTK", label: "Đà Nẵng - Tam Kỳ" },
                ]}
                label="Tuyến"
              ></SelectCustom>
            </Form.Item>
            <Form.Item name="khungGioName" className="!mr-2">
              <SelectCustom
                onChange={() => onChangeForm()}
                allowClear
                style={{ width: "180px" }}
                options={khungGioCombo}
                label="Giờ đi"
              ></SelectCustom>
            </Form.Item>
            <Form.Item name="tripStatus" className="!mr-2">
              <SelectCustom
                onChange={() => onChangeForm()}
                style={{ width: "120px" }}
                allowClear
                options={[
                  { value: "Y", label: "Hoạt động" },
                  { value: "N", label: "Đã hủy" },
                ]}
                label="Trạng thái"
              ></SelectCustom>
            </Form.Item>
            <Form.Item name="tienFlg">
              <SelectCustom
                onChange={() => onChangeForm()}
                style={{ width: "120px" }}
                allowClear
                options={[
                  { value: "0", label: "Đã thanh toán" },
                  { value: "1", label: "Chưa thanh toán" },
                ]}
                label="Thanh toán"
              ></SelectCustom>
            </Form.Item>
          </div>
        </div>
      </Form>
      <MaterialDetailPopup
        // data={record}
        isOpen={isOpenDetail}
        onClose={onClose}
      ></MaterialDetailPopup>
    </>
  );
}

export default TripRecordListForm;
