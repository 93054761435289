import "App.scss";
import { ConfigProvider } from "antd";
import Route from "routes/route";
import React, { useEffect, useState, useCallback } from "react";
import { logoutBroadcast } from "core/Broadcast";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "redux/userSlide";
import { setCallData, acceptByAnother } from "redux/callingSlice.js";
import _ from "lodash";
import TokenService from "utils/TokenService";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import callApi from "api/Call/callApi";
import moment from "moment";
import exApi from "api/Extenal/exApi";

ConfigProvider.config({
  theme: {
    primaryColor: "#4c78ae",
  },
});

function App() {
  const { token } = useSelector((state) => state.user);
  const { phoneNumber } = useSelector((state) => state.calling);
  const [recordingFile, setRecordingFile] = useState("");
  const [callId, setCallId] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (recordingFile && callId) {
      const param = {
        callId: callId,
        recordFile: recordingFile,
        callNumber: phoneNumber,
      };
      callApi.saveCallHistory(param);
      setCallId("");
    }
  }, [recordingFile]);

  useEffect(() => {
    if (token) {
      const initOmiSDK = () => {
        let config = {
          theme: "default", // sử dụng UI mặc định của sdk
          debug: false, // cho phép console.log các event call và trạng thái trong sdk
          busy: false, // nếu true, sẽ auto reject các cuộc gọi đến, nhưng vẫn có thể thực hiện cuộc gọi ra
          language: "vi", // Ngôn ngữ giao diện dialog,
          ringtoneVolume: 1,
          options: {
            showNoteInput: false, // hiển thị input note mặc định để lưu vào Omi, sẽ submit qua callback "saveCallInfo" khi cuộc gọi đã kết thúc và dialog call được đóng
            hideCallButton: false, // ẩn hiển thị nút toggle dialog nhập số để gọi ra mặc định
            showContactLoading: false, // hiển thị loading ở dialog gọi khi có cuộc gọi đến, dùng kết hợp với function omiSDK.updateContactInfo để hiển thị avatar và tên của số điện thoại gọi đến
          },
          // ],
          callbacks: {
            register: (data) => {
              // Sự kiện xảy ra khi trạng thái kết nối tổng đài thay đổi
              // console.log("register:", data);
            },
            connecting: (data) => {
              // // Sự kiện xảy ra khi bắt đầu thực hiện cuộc gọi ra
              let dataCall = {
                phoneNumber: data.phone,
                status: data.direction,
                hangup: false,
                acceptCall: false,
                callPopup: false,
              };
              dispatch(setCallData(dataCall));
              const param = {
                callId: data.id,
                callDate: moment(data.startTime).format("YYYY-MM-DD HH:mm:ss"),
                callNumber: data.remoteNumber,
                callStatus: "NO ANSWER",
                callType: "outbound",
                line: data.line,
                recordTime: data.duration,
                did: data.sipNumber,
              };
              callApi.saveCallHistory(param);
            },
            invite: (data) => {
              // Sự kiện xảy ra khi có cuộc gọi tới
              let dataCall = {
                phoneNumber: data.remoteNumber,
                status: data.direction,
                hangup: false,
                acceptCall: false,
                callPopup: false,
              };
              dispatch(setCallData(dataCall));
              const param = {
                callId: data.uuid,
                callDate: moment(data.startTime).format("YYYY-MM-DD HH:mm:ss"),
                callNumber: data.remoteNumber,
                callStatus: "NO ANSWER",
                callType: "inbound",
                line: data.line,
                did: data.sipNumber,
              };
              callApi.saveCallHistory(param);
              // console.log("invite:", data);
            },
            inviteRejected: (data) => {
              // Sự kiện xảy ra khi có cuộc gọi tới, nhưng bị tự động từ chối
              // trong khi đang diễn ra một cuộc gọi khác
              // console.log("inviteRejected:", data);
            },
            ringing: (data) => {
              // Sự kiện xảy ra khi cuộc gọi ra bắt đầu đổ chuông
              // console.log("ringing:", data);
            },
            accepted: (data) => {
              // Sự kiện xảy ra khi cuộc gọi vừa được chấp nhận
              let dataCall = {
                phoneNumber: data.phone,
                status: data.direction,
                hangup: false,
                acceptCall: true,
                callPopup: false,
              };
              dispatch(setCallData(dataCall));
              setCallId(data.direction == "inbound" ? data.uuid : data.id);
              // console.log("accepted:", data);
            },
            incall: (data) => {
              // Sự kiện xảy ra mỗi 1 giây sau khi cuộc gọi đã được chấp nhận
              // console.log("incall:", data);
            },
            acceptedByOther: (data) => {
              // Sự kiện dùng để kiểm tra xem cuộc gọi bị kết thúc
              // đã được chấp nhận ở thiết bị khác hay không
              // console.log("acceptedByOther:", data);
            },
            ended: (data) => {
              // Sự kiện xảy ra khi cuộc gọi kết thúc
              // Sự kiện xảy ra khi có cuộc gọi tới
              let dataCall = {
                phoneNumber: "",
                status: "nocall",
                hangup: true,
                acceptCall: false,
                callPopup: false,
              };
              dispatch(setCallData(dataCall));
              const param = {
                callId: data.direction == "inbound" ? data.uuid : data.id,
                callDate: data.startTime,
                callNumber: data.remoteNumber,
                callStatus: data.isAccepted ? "ANSWERED" : "NO ANSWER",
                recordTime: data.duration,
                line: data.sipUser,
                did: data.sipNumber,
              };
              callApi.saveCallHistory(param);

              const timestampMilliseconds = data.startTime.getTime();
              const fromTime = timestampMilliseconds - 3000;
              const toTime = timestampMilliseconds + 3000;
              const paramRec = {
                // eslint-disable-next-line camelcase
                from_date: fromTime,
                // eslint-disable-next-line camelcase
                to_date: toTime,
                keyword: data.remoteNumber,
                direction: data.direction,
              };
              handleSaveCallHistory(paramRec);
            },
            holdChanged: (status) => {
              // Sự kiện xảy ra khi trạng thái giữ cuộc gọi thay đổi
              // console.log("on hold:", status);
            },
            saveCallInfo: (data) => {
              // data = { callId, note, ...formData };
              // Sự kiện xảy ra khi cuộc gọi đã có đổ chuông hoặc cuộc gọi tới, khi user có nhập note input mặc định hoặc form input custom
              // console.log("on save call info:", data);
            },
          },
        };

        window.omiSDK.init(config, () => {
          let extension = {
            domain: "votrquan", // Replace with your domain
            username: TokenService.getUserLocalStorage()?.line, // Replace with your username
            password: TokenService.getUserLocalStorage()?.passCall, // Replace with your password
          };

          window.omiSDK.register(extension, (error) => {
            if (error) {
              console.log("Register error:", error);
            } else {
              console.log("Register finish!");
            }
          });
        });
      };

      // Ensure omiSDK is loaded before calling initOmiSDK
      if (window.omiSDK) {
        initOmiSDK();
      } else {
        console.error("omiSDK is not loaded");
      }

      var sock = new SockJS("https://ad.vugiataxi.com/ws");
      // var sock = new SockJS(process.env.REACT_APP_WS);
      let stompClient = Stomp.over(sock);
      stompClient.debug = null;
      sock.onopen = function () {
        // console.log("open");
      };

      stompClient.connect({}, function (frame) {
        stompClient.subscribe("/topic/reply", function (greeting) {
          const jsonObject = JSON.parse(greeting.body);
          if (jsonObject.state == "cdr") {
            setRecordingFile(jsonObject.recordingFile);
          }
          if (
            jsonObject.direction == "inbound" &&
            TokenService.getUserLocalStorage()?.line != jsonObject.line &&
            TokenService.getUserLocalStorage()?.fromNumber != jsonObject.line &&
            !phoneNumber
          ) {
            if (jsonObject.state == "answered") {
              let param = {
                acceptCallByOther: true,
                phoneNumberOther: jsonObject.fromNumber,
                userAccept:
                  jsonObject.line == 100
                    ? "Thanh Hoa"
                    : jsonObject.line == 101
                    ? "Kim Tuyến"
                    : jsonObject.line == 102
                    ? "Ngọc Vỹ"
                    : jsonObject.line == 103
                    ? "Ngọc Anh"
                    : jsonObject.line == 104
                    ? "Văn Hải"
                    : "Thanh Hoa",
              };
              dispatch(acceptByAnother(param));
            } else {
              let param = {
                acceptCallByOther: false,
                phoneNumberOther: "",
                userAccept: "",
              };
              dispatch(acceptByAnother(param));
            }
          }
        });
      });
    }
  }, [token]);

  const saveCallHistoryFinal = async (paramHis) => {
    try {
      const result = await exApi.getCallHistoryList(paramHis);
      if (result) {
        const param = {
          callNumber: paramHis.keyword,
          recordFile: result.recording_file,
          recordTime: result.record_seconds,
          correctRecord: "Y",
          frmDt: moment(paramHis.from_date).format("YYYY-MM-DD HH:mm:ss"),
          toDt: moment(paramHis.to_date).format("YYYY-MM-DD HH:mm:ss"),
        };
        // If there's another asynchronous function to call after getting the result
        callApi.saveCallHistory(param);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Queue and timer setup
  const timers = new Map(); // To store timers for each unique parameter set

  const processCall = (params) => {
    saveCallHistoryFinal(params);
  };

  // Function to add parameters to the queue
  const handleSaveCallHistory = (params) => {
    const key = JSON.stringify(params); // Unique key for each parameter set

    if (timers.has(key)) {
      clearTimeout(timers.get(key)); // Clear existing timer if already set
    }

    // Set a new timer for 30 seconds
    const timer = setTimeout(() => {
      processCall(params);
      timers.delete(key); // Remove timer after execution
    }, 30000); // 30 seconds delay

    timers.set(key, timer); // Store the timer
  };

  useEffect(() => {
    logoutBroadcast.onmessage = () => {
      dispatch(logout());
      window.location.href = window.location.origin + "/login";
    };
  }, [dispatch]);

  return (
    <>
      <Route />
    </>
  );
}

export default App;
