import { Tabs, Row } from "antd";
import React, { useState } from "react";
import "./MenuMgt.scss";
import SearchForm from "./Components/SearchForm";
import MenuTreeView from "./Components/MenuTreeView";
import MenuDetail from "./Components/MenuDetail";
import ItemTabs from "./Components/ItemTabs";
import PopupList from "./Components/PopupList";
import { useSelector } from "react-redux";

function MenuMgt() {
  const [nameSearch, setNameSearch] = useState("");

  const onChangeNameSearch = (values) => {
    setNameSearch(values);
  };
  const { lsButton, lsButtonAdd, lsGeneral, lsGeneralAdd, lsGrid, lsGridAdd, menuVO, menuVoNew } =
    useSelector((state) => state.menuMgt);

  const checkDataChanged = () => {
    if (menuVoNew.mnuPgmNmEn !== "") {
      if (menuVO.mnuPgmNmEn !== menuVoNew.mnuPgmNmEn || menuVoNew.mnuPgmNmEn.trim() === "")
        return true;
      if (menuVO.mnuPgmNmVi !== menuVoNew.mnuPgmNmVi || menuVoNew.mnuPgmNmVi.trim() === "")
        return true;
      if (menuVO.mnuPgmNmKo !== menuVoNew.mnuPgmNmKo || menuVoNew.mnuPgmNmKo.trim() === "")
        return true;
      if (
        menuVO.mnuPgmUrl !== menuVoNew.mnuPgmUrl &&
        (menuVO.mnuPgmUrl !== undefined || menuVoNew.mnuPgmUrl !== "")
      )
        return true;
      if (
        menuVO.mnuPgmRmk !== menuVoNew.mnuPgmRmk &&
        (menuVO.mnuPgmRmk !== undefined || menuVoNew.mnuPgmRmk !== "")
      )
        return true;
      if (menuVO.menuTp !== menuVoNew.menuTp) return true;
      if (menuVO.mnuUsd !== menuVoNew.mnuUsd) return true;
    }
    if (menuVO.menuTp === 1 && lsButton.length !== lsButtonAdd.length) return true;
    if (menuVO.menuTp === 1 && lsGeneral.length !== lsGeneralAdd.length) return true;
    if (menuVO.menuTp === 1 && lsGrid.length !== lsGridAdd.length) return true;
    for (let i = 0; i < lsButton.length; i++) {
      if (lsButton[i].btnNm !== lsButtonAdd[i].btnNm) return true;
    }
    for (let i = 0; i < lsGeneral.length; i++) {
      if (lsGeneral[i].itmNo !== lsGeneralAdd[i].itmNo) return true;
    }
    for (let i = 0; i < lsGrid.length; i++) {
      if (lsGrid[i].itmNo !== lsGridAdd[i].itmNo) return true;
    }
    return false;
  };

  return (
    <>
      <SearchForm onChangeNameSearch={onChangeNameSearch} />
      <div className="content-box">
        <Tabs
          items={[
            {
              label: "Button",
              key: "button",
              children: (
                <Row>
                  <MenuTreeView nameSearch={nameSearch} checkDataChanged={checkDataChanged} />
                  <MenuDetail checkDataChanged={checkDataChanged} />
                  <ItemTabs />
                </Row>
              ),
            },
            {
              label: "Popup",
              key: "popup",
              children: (
                <Row>
                  <PopupList />
                  <ItemTabs />
                </Row>
              ),
            },
          ]}
        />
      </div>
    </>
  );
}

export default MenuMgt;
