import { Col, Row, Table, Tooltip, Button, Modal, Tag } from "antd";
import { PAGE } from "core/Constants";
import React, { useEffect, useState } from "react";
import { EditOutlined, CloseOutlined } from "@ant-design/icons";
import useResize from "hook/useResize";
import KhungGioDetail from "./popup/KhungGioDetail";
import khungGioApi from "api/Call/khungGioApi";

const { confirm } = Modal;

function KhungGioTable({ dataTable, loading }) {
  const [pageIndex, setPageIndex] = useState(PAGE.DEFAULT_PAGE);
  const [indexSelect, setIndexSelect] = useState("");
  const [pageSize, setPageSize] = useState(14);
  const [width, height] = useResize();
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [record, setRecord] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const resizeHandler = () => {
      const windowHeight = window.innerHeight;
      const tableHeight = windowHeight - 170; // You can adjust this value according to your needs
      const tableElement = document.querySelector(".ant-table-body");
      if (tableElement) {
        tableElement.style.maxHeight = `${tableHeight}px`;
      }
    };
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  useEffect(() => {
    setPageIndex(1);
    setCurrentPage(1);
  }, [dataTable]);

  const getRowIndex = (record, index) => {
    return (pageIndex - 1) * pageSize + index + 1;
  };

  const onTableChange = (pagination) => {
    setPageIndex(pagination.current);
    setPageSize(pagination.pageSize);
  };
  const onRowClassName = (record, index) => {
    return (
      (index % 2 === 0 ? "table-row-light " : "table-row-dark") +
      (index === indexSelect ? " ant-table-row-selected" : "")
    );
  };

  function transformColumns(columns) {
    return columns.map((column) => {
      if (column.title === "No.") {
        return {
          ...column,
          render: (text, object, index) => getRowIndex(object, index),
        };
      } else {
        return column;
      }
    });
  }
  const onRow = (record, index) => {
    return {
      onClick: () => {
        setRecord(record);
        setIndexSelect(index);
      },
    };
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const onClose = () => {
    setIsOpenDetail(false);
  };

  const onEdit = (param) => {
    // setDataCar({ ...param, newStatus: false });
    setIsOpenDetail(true);
  };

  const onBlock = (param) => {
    confirm({
      title: "Bạn có chắc chắn muốn khóa khung giờ này",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        const data = {
          ...param,
          status: "N",
        };
        await khungGioApi.updateKhungGioStatus(data);
      },
      onCancel() {
        return;
      },
    });
  };

  const defineInventoryHis = [
    {
      title: "#",
      dataIndex: "khungGioId",
      responsive: ["md"],
      width: "60px",
      align: "center",
      render: (text, object, index) => index + 1,
    },

    {
      title: "Thời gian",
      dataIndex: "khungGioName",
      responsive: ["md"],
      width: "150px",
      align: "center",
    },
    {
      title: "Số lượng khách",
      dataIndex: "soLuongKhach",
      responsive: ["md"],
      width: "200px",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Tuyến",
      dataIndex: "tuyen",
      responsive: ["md"],
      width: "150px",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Tuyến",
      dataIndex: "tuyenName",
      responsive: ["md"],
      width: "150px",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Trạng thái",
      dataIndex: "carStatus",
      responsive: ["md"],
      width: "150px",
      ellipsis: true,
      align: "center",
      render: (text, record) =>
        record.status === "Y" ? (
          <Tag color={"green"} key="Y">
            Đang hoạt động
          </Tag>
        ) : (
          <Tag color={"volcano"} key="Y">
            Dừng hoạt động
          </Tag>
        ),
    },
    {
      title: "Thao Tác",
      dataIndex: "ttlQty",
      responsive: ["md"],
      width: "90px",
      inputType: "number",
      require: false,
      align: "center",
      render: (_, record) => (
        <div className="flex justify-center">
          <Tooltip title="Chỉnh sửa" color={"purple"} key={"purple"}>
            <Button
              icon={<EditOutlined style={{ fontSize: "20px", color: "purple" }} />}
              onClick={() => {
                onEdit(record);
              }}
              type="text"
            />
          </Tooltip>
          <Tooltip title="Khóa" color={"red"} key={"red"}>
            <Button
              icon={<CloseOutlined style={{ fontSize: "20px", color: "red" }} />}
              onClick={() => {
                onBlock(record);
              }}
              type="text"
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="table-khung-gio">
        <Table
          columns={transformColumns(defineInventoryHis)}
          dataSource={dataTable}
          rowKey={(record) => record.mtrlId + Math.floor(Math.random() * 1000000).toString()}
          rowClassName={onRowClassName}
          bordered
          onChange={onTableChange}
          scroll={{ y: 1100 }}
          onRow={onRow}
          loading={loading}
          pagination={false}
        />
      </div>
      <KhungGioDetail data={record} isOpen={isOpenDetail} onClose={onClose}></KhungGioDetail>
    </>
  );
}

export default KhungGioTable;
