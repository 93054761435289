import { Button, Form } from "antd";
import { SearchOutlined, FileTextOutlined } from "@ant-design/icons";
import { DatePickerCustom } from "components";
import React, { useEffect } from "react";
import moment from "moment";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import tripRecordApi from "api/Call/tripRecord";
dayjs.extend(customParseFormat);
import "moment/locale/vi";

moment.locale("vi");

function DriverSalaryListForm({ onData, onLoading, handleExport }) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      fromDt: moment(),
      toDt: moment(),
    });
  }, []);

  const onFinish = (values) => {
    let param = {
      fromDt: moment(values.fromDt).format("DD/MM/YYYY"),
      toDt: moment(values.toDt).format("DD/MM/YYYY"),
    };
    getRevenueList(param);
  };

  const getRevenueList = async (params) => {
    try {
      onLoading(true);
      const result = await tripRecordApi.getSalaryList(params);
      if (result.success) {
        const lsRevenue = result.data.lsRevenue;
        const lsRevenueCar = result.data.lsRevenueCar;
        const lsFinalData = lsRevenue.map((rev) => {
          lsRevenueCar.map((revCar) => {
            if (revCar.driverId == rev.driverId) {
              rev.tongChuyen = revCar.tongChuyen;
              rev.soTrong = revCar.soTrong;
              rev.typeFour = revCar.typeFour;
              rev.typeSeven = revCar.typeSeven;
              rev.salHD = 0;
              rev.salKL = revCar.tongChuyen * 80;
              rev.totalSalary =
                rev.official == "Y"
                  ? revCar.tongChuyen * 80 + (rev.revHang + rev.revKhach + rev.revBx) * 0.025
                  : revCar.tongChuyen * 80;
            }
          });
          rev.totalRev = rev.revHang + rev.revKhach + rev.revBx;
          return rev;
        });
        onData(lsFinalData);
      }
    } catch (error) {
      onLoading(false);
    } finally {
      onLoading(false);
    }
  };

  const onExport = () => {
    const param = form.getFieldValue();
    handleExport({
      fromDt: moment(param.fromDt).format("DD/MM/YYYY"),
      toDt: moment(param.toDt).format("DD/MM/YYYY"),
    });
  };

  return (
    <>
      <Form colon={false} onFinish={onFinish} form={form} className="form-control-inventory-his">
        <div className="flex justify-between">
          <div className="mt-1">
            {/* <Button
              type="primary"
              disabled={false}
              htmlType="submit"
              className="button btn_custom"
              icon={<SearchOutlined />}
            >
              Tìm
            </Button> */}
            {/* <Button
              type="primary"
              className="button btn_custom"
              icon={<ExportOutlined />} 
              onClick={handleAdd}
              loading={loadingExport}
            >
              Thêm mới
            </Button> */}
          </div>
          <div className="flex justify-start">
            <Form.Item name="fromDt" className="!mr-2">
              <DatePickerCustom
                label="Từ ngày"
                style={{ width: "200px" }}
                format="DD/MM/YYYY"
                // onChange={(date) => {
                //   onChangeDate({ ngayPlan: moment(date).format("DD/MM/YYYY") });
                // }}
              ></DatePickerCustom>
            </Form.Item>
            <Form.Item name="toDt" className="!mr-2">
              <DatePickerCustom
                label="Đến ngày"
                style={{ width: "200px" }}
                format="DD/MM/YYYY"
                // onChange={(date) => {
                //   onChangeDate({ ngayPlan: moment(date).format("DD/MM/YYYY") });
                // }}
              ></DatePickerCustom>
            </Form.Item>
            <Button
              type="primary"
              disabled={false}
              size="large"
              htmlType="submit"
              className="button btn_custom"
              icon={<SearchOutlined />}
            >
              Tìm
            </Button>
            <Button
              type="primary"
              disabled={false}
              size="large"
              onClick={onExport}
              className="!bg-[#4ec375]"
              icon={<FileTextOutlined />}
            >
              Xuất file
            </Button>
          </div>
        </div>
      </Form>
      {/* <MaterialDetailPopup
        // data={record}
        isOpen={isOpenDetail}
        onClose={onClose}
      ></MaterialDetailPopup> */}
    </>
  );
}

export default DriverSalaryListForm;
