import { Col, Button, message, Tooltip, Modal } from "antd";
import { GoogleMap, Marker, useJsApiLoader, InfoWindow } from "@react-google-maps/api";
import Geocode from "react-geocode";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { setCallData } from "redux/callingSlice.js";
import TripHistoryPopup from "pages/TripHistoryPopup/TripHistoryPopup";
import TripMapPopup from "pages/TripMapPopup2/TripMapPopup";
import tripRecordApi from "api/Call/tripRecord";
import CallHistoryPopup from "pages/CallHistoryPopup/CallHistoryPopup";
import moment from "moment";
import "moment/locale/vi";
import TokenService from "utils/TokenService";

const { confirm } = Modal;

const admin = TokenService.getUserLocalStorage()?.coCd == "CLV";

moment.locale("vi");
import {
  CopyOutlined,
  PhoneOutlined,
  SoundOutlined,
  FileSearchOutlined,
  RightOutlined,
} from "@ant-design/icons";
import m01 from "./01-260707.png";
import m02 from "./02-da1313.png";
import m03 from "./03-1b2dd3.png";
import m04 from "./04-0de053.png";
import m05 from "./20-fd7100.png";
import m06 from "./21-567328.png";
import m07 from "./22-5cb5b6.png";
import m08 from "./23-6880e8.png";
import m09 from "./24-f16787.png";
import m00 from "./05-d9e00d.png";
import m10 from "./10-e71b85.png";
import m11 from "./11-e71b85.png";
import ThayDoiTaiXePopup from "./popup/ThayDoiTaiXePopup";

const libraries = ["drawing", "places"];

function MapDetail({
  tripOrder,
  addFlg,
  ghiChuFlg,
  gheFlg,
  driverList,
  khungGioList,
  setReloadFlgFunc,
}) {
  Geocode.enableDebug();

  const dispatch = useDispatch();

  const [markers, setMarkers] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const [selectMarker, setSelectMarker] = useState(false);

  const [selectMarkerList, setSelectMarkerList] = useState([]);

  const [textSelectMarker, setTextSelectMarker] = useState("");
  const [dataPopup, setDataPopup] = useState([]);
  const [centerLoc, setCenterLoc] = useState({
    lat: 16.047476382466968,
    lng: 108.19890504473366,
  });
  const [popupId, setPopupId] = useState();
  const [tripIdList, setTripIdList] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  const [historyPopupData, setHistoryPopupData] = useState({});
  const [isOpenHistory, setIsOpenHistory] = useState(false);

  const [tripMapPopupData, setTripMapPopupData] = useState(false);
  const [isOpenTripMapPopup, setIsOpenTripMapPopup] = useState(false);

  const [callHistoryPopupData, setCallHistoryPopupData] = useState(false);
  const [isOpenCallHistoryPopup, setIsOpenCallHistoryPopup] = useState(false);
  const [cusId, setCusId] = useState("");

  useEffect(() => {
    const listMarker = tripOrder.map((item) => {
      if (item.tuyen === "TKDN") {
        item.location = {
          lat: Number(item.diemTraLat),
          lng: Number(item.diemTraLng),
        };
      } else {
        item.location = {
          lat: Number(item.diemDonLat),
          lng: Number(item.diemDonLng),
        };
      }
      if (item.tripPlanId) {
        driverList.map(function (o, index) {
          if (o.tripPlanId == item.tripPlanId) {
            if (index === 0) {
              item.icon = m01;
            } else if (index === 1) {
              item.icon = m02;
            } else if (index === 2) {
              item.icon = m03;
            } else if (index === 3) {
              item.icon = m04;
            } else if (index === 4) {
              item.icon = m05;
            } else if (index === 5) {
              item.icon = m06;
            } else if (index === 6) {
              item.icon = m07;
            } else if (index === 7) {
              item.icon = m08;
            } else if (index === 8) {
              item.icon = m09;
            }
          }
        });
      } else {
        item.icon = m11;
      }

      item.selected = false;
      return item;
    });
    setMarkers(listMarker);
  }, [tripOrder]);

  const containerStyle = {
    // width: "50vw",
    height: "100%",
  };
  const mapRef = useRef(null);

  const onMapLoad = (mapInstance) => {
    mapRef.current = mapInstance;
  };

  const onClickMarker = (params) => {
    // setCenterLoc(params.location);
    setSelectMarkerList([...selectMarkerList, params]);
    const newSelectList = markers.map((item) => {
      if (!params.selected && item.tripId === params.tripId) {
        item.icon = m00;
        item.selected = !params.selected;
      } else if (params.selected && item.tripId === params.tripId) {
        if (item.tripPlanId) {
          driverList.map(function (o, index) {
            if (o.tripPlanId == item.tripPlanId) {
              if (index === 0) {
                item.icon = m01;
              } else if (index === 1) {
                item.icon = m02;
              } else if (index === 2) {
                item.icon = m03;
              } else if (index === 3) {
                item.icon = m04;
              } else if (index === 4) {
                item.icon = m05;
              } else if (index === 5) {
                item.icon = m06;
              } else if (index === 6) {
                item.icon = m07;
              } else if (index === 7) {
                item.icon = m08;
              } else if (index === 8) {
                item.icon = m09;
              }
            }
          });
        } else {
          item.icon = m11;
        }
        item.selected = !params.selected;
      }
      return item;
    });
    const lsMarkerSelect = newSelectList.filter((obj) => {
      return obj.selected;
    });
    const soGheSelect = lsMarkerSelect.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.soKhach;
    }, 0);

    setSelectMarkerList(lsMarkerSelect);
    setMarkers(newSelectList);
    setTextSelectMarker("Đang chọn " + soGheSelect + " ghế / " + lsMarkerSelect.length + " vị trí");
    if (lsMarkerSelect.length > 0) {
      setSelectMarker(true);
    } else {
      setSelectMarker(false);
    }
  };

  const handleShowPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleOkPopup = () => {
    clickBoChon();
    setReloadFlgFunc();
    setShowPopup(false);
  };

  const clickTaoChuyen = () => {
    setDataPopup(selectMarkerList);
    setPopupId(0);
    handleShowPopup();
  };
  const clickThayDoiChuyen = () => {
    setPopupId(1);
    setDataPopup(selectMarkerList);
    handleShowPopup();
  };
  const clickBoChon = () => {
    const newSelectList = markers.map((item) => {
      if (item.driverName) {
        driverList.map(function (o, index) {
          if (Number(o.driverId) === Number(item.driverId)) {
            if (index === 0) {
              item.icon = m01;
            } else if (index === 1) {
              item.icon = m02;
            } else if (index === 2) {
              item.icon = m03;
            } else if (index === 3) {
              item.icon = m04;
            }
          }
        });
      } else {
        item.icon = m11;
      }
      return item;
    });
    setSelectMarker(false);
    setSelectMarkerList([]);
    setMarkers(newSelectList);
  };

  const handleMarkerDblClick = (e) => {
    if (tripIdList.indexOf(parseInt(e.tripId)) < 0) {
      setTripIdList([...tripIdList, e.tripId]);
    }
  };

  const infoClose = (value) => {
    const ls = tripIdList.filter((e) => e !== value.tripId);
    setTripIdList(ls);
  };

  const onCopy = (value) => {
    // let text = "";
    // value.map(
    //   (item, index) =>
    //     (text =
    //       text +
    //       "\n" +
    //       `${index + 1}` +
    //       ". " +
    //       item.name +
    //       " - " +
    //       item.cusId +
    //       " - SL " +
    //       item.soKhach +
    //       " - " +
    //       `${item.diemDonAdd ? item.diemDonAdd.split(",")[0].trim() : ""}` +
    //       " - " +
    //       `${item.diemTraAdd ? item.diemTraAdd.split(",")[0].trim() : ""}` +
    //       " - " +
    //       item.soTien +
    //       "K " +
    //       `${item.tienFlg === 0 ? " - Đã thanh toán " : ""}` +
    //       `${item.ghiChu ? item.ghiChu : ""}`)
    // );
    // navigator.clipboard.writeText(text);
    // messageApi.open({
    //   type: "success",
    //   content: "Sao chép thành công",
    // });
  };
  const onCall = () => {};
  const onCallHistory = (param) => {
    setCusId(param);
    setCallHistoryPopupData({ cusId: param });
    setIsOpenCallHistoryPopup(true);
  };

  const onCancelTrip = (param) => {
    confirm({
      title: "Bạn có muốn hủy chuyến đi này",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        await tripRecordApi.updateOrderStatus(param);
        setReloadFlgFunc();
      },
      onCancel() {
        return;
      },
    });
  };

  const onTripHistory = (param) => {
    setCusId(param);
    setHistoryPopupData({ cusId: param });
    setIsOpenHistory(true);
  };

  const onEditTripMapPopup = (param) => {
    setTripMapPopupData({ ...param, status: "EDIT" });
    setIsOpenTripMapPopup(true);
  };

  const onCloseHistory = () => {
    setIsOpenHistory(false);
  };

  const onCloseTripMapPopup = () => {
    setIsOpenTripMapPopup(false);
  };

  const onCloseCallHistoryPopup = () => {
    setIsOpenCallHistoryPopup(false);
  };

  const callPhoneNumber = (param) => {
    window.omiSDK.makeCall(param);
  };

  return (
    <Col className="right-box item-tabs " flex="auto">
      {contextHolder}
      <div className="relative h-full">
        <div className="static h-full">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={centerLoc}
            zoom={12}
            onLoad={onMapLoad}
          >
            {markers.map((marker, index) => {
              return (
                <div key={index}>
                  <Marker
                    position={marker.location}
                    draggable={true} // Enable marker dragging
                    onDragEnd={null} // Handle drag end event
                    options={{
                      icon: marker.icon,
                    }}
                    label={{
                      text: marker.khungGioName.substring(0, 2) + "/" + marker.soKhach,
                      color: "#000000",
                      fontSize: "11px",
                      fontWeight: "bold",
                      className: "map-label",
                    }}
                    onClick={() => {
                      onClickMarker(marker);
                    }}
                    onDblClick={() => {
                      handleMarkerDblClick(marker);
                    }}
                    // dblclick={handleClick}
                  />
                  {addFlg && (
                    <InfoWindow
                      position={marker.location}
                      options={{
                        pixelOffset: new window.google.maps.Size(0, -40),
                      }}
                    >
                      <div>
                        <h1 className="mb-0">
                          {marker.tuyen === "TKDN" ? marker.diemTraAdd : marker.diemDonAdd}
                        </h1>
                        {/* <button onClick={() => setSelectedMarker("")}>close</button> */}
                      </div>
                    </InfoWindow>
                  )}
                  {ghiChuFlg && marker.ghiChu && (
                    <InfoWindow
                      position={marker.location}
                      options={{
                        pixelOffset: new window.google.maps.Size(0, -40),
                      }}
                    >
                      <div>
                        <h1 className="mb-0">{marker.ghiChu}</h1>
                      </div>
                    </InfoWindow>
                  )}
                  {tripIdList.indexOf(parseInt(marker.tripId)) > -1 && (
                    <InfoWindow
                      position={marker.location}
                      onCloseClick={() => {
                        infoClose(marker);
                      }}
                      options={{
                        pixelOffset: new window.google.maps.Size(0, -40),
                      }}
                    >
                      <div>
                        <div className="flex">
                          <p className="pt-1 mr-1 font-medium">SĐT: </p>
                          <p className="pt-1 mr-12">{marker.cusId}</p>
                          <Tooltip title="Sao chép" color={"#6aa84f"} key={"6aa84f"}>
                            <Button
                              icon={<CopyOutlined style={{ color: "#6aa84f" }} />}
                              onClick={() => {
                                onCopy(marker);
                              }}
                              size="small"
                              className="!ml-3 !rounded-md"
                            />
                          </Tooltip>
                          <Tooltip title="Gọi" color={"#ffae42"} key={"ffae42"}>
                            <Button
                              icon={<PhoneOutlined style={{ color: "#ffae42" }} />}
                              onClick={() => {
                                callPhoneNumber(marker.cusId);
                              }}
                              size="small"
                              className="!ml-3 !rounded-md"
                            />
                          </Tooltip>
                          <Tooltip title="Tìm ghi âm" color={"#2986cc"} key={"2986cc"}>
                            <Button
                              icon={<SoundOutlined style={{ color: "#2986cc" }} />}
                              onClick={() => onCallHistory(marker.cusId)}
                              size="small"
                              className="!ml-3 !rounded-md"
                            />
                          </Tooltip>
                          <Tooltip title="Lịch sử đặt vé" color={"#47dded"} key={"47dded"}>
                            <Button
                              icon={<FileSearchOutlined style={{ color: "#47dded" }} />}
                              onClick={() => onTripHistory(marker.cusId)}
                              size="small"
                              className="!ml-3 !rounded-md"
                            />
                          </Tooltip>
                        </div>
                        <div className="flex">
                          <p className="font-medium mr-1">Tên: </p>
                          <p>{marker.name}</p>
                        </div>
                        <div className="flex">
                          <p className="font-medium mr-1">Điểm đi: </p>
                          <p>{marker.diemDonAdd}</p>
                        </div>
                        <div className="flex">
                          <p className="font-medium mr-1">Điểm đến: </p>
                          <p>{marker.diemTraAdd}</p>
                        </div>
                        <div className="flex">
                          <p className="font-medium mr-1">Thời gian: </p>
                          <p>
                            {marker.khungGioName} ngày {marker.ngayDi}
                          </p>
                        </div>
                        <div className="flex">
                          <p className="font-medium mr-1">Loại xe: </p>
                          <p>{marker.carType}</p>
                        </div>
                        <div className="flex">
                          <p className="font-medium mr-1">Số lượng: </p>
                          <p>
                            {marker.tripType === 1
                              ? "Hàng"
                              : marker.tripType === 2
                              ? "Bao xe 7 chổ"
                              : marker.tripType === 3
                              ? "Bao xe 4 chổ"
                              : marker.soKhach}
                          </p>
                        </div>
                        <div className="flex">
                          <p className="font-medium mr-1">Ghi Chú: </p>
                          <p className="text-red-500 mb-1 mr-2">
                            {marker.tienFlg === 0 ? "Đã Thanh Toán" : ""}
                          </p>
                          <p className="mb-1">{marker.ghiChu}</p>
                        </div>
                        <div
                          className={
                            moment()
                              .add(-90, "minutes")
                              .isBefore(
                                moment(marker.ngayDi + marker.khungGioName, "DD/MM/YYYYHH:mm")
                              ) || admin
                              ? "flex justify-center"
                              : "hidden flex justify-center"
                          }
                        >
                          <Button
                            className="!w-20 !ml-2"
                            type="primary"
                            // icon={<SaveOutlined />}
                            onClick={() => {
                              onEditTripMapPopup(marker);
                            }}
                            danger={gheFlg}
                          >
                            Sửa
                          </Button>
                          <Button
                            className="!w-20 !ml-2"
                            type="primary"
                            // icon={<SaveOutlined />}
                            onClick={() => {
                              onCancelTrip(marker);
                            }}
                            danger
                          >
                            Hủy
                          </Button>
                        </div>
                      </div>
                    </InfoWindow>
                  )}
                </div>
              );
            })}
          </GoogleMap>
        </div>
        <div className="w-20 absolute top-0 left-0 flex">
          <Button
            type="primary"
            htmlType="submit"
            hidden={!selectMarker}
            onClick={clickTaoChuyen}
            disabled={Object.keys("recordButton").length > 0 || "isNew" == true ? false : true}
          >
            Tạo chuyến
          </Button>
          <Button
            danger
            type="primary"
            htmlType="submit"
            hidden={!selectMarker}
            onClick={clickThayDoiChuyen}
            disabled={Object.keys("recordButton").length > 0 || "isNew" == true ? false : true}
          >
            Thay đổi chuyến
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            hidden={!selectMarker}
            onClick={clickBoChon}
            disabled={Object.keys("recordButton").length > 0 || "isNew" == true ? false : true}
          >
            Bỏ chọn
          </Button>
        </div>
        <div
          className={
            !selectMarker
              ? "hidden"
              : "" + " w-54 p-2 px-4 bg-red-500 absolute bottom-0 left-0 flex"
          }
        >
          <p className="text-base text-white mb-0">{textSelectMarker}</p>
        </div>
      </div>
      <ThayDoiTaiXePopup
        show={showPopup}
        popupId={popupId}
        data={dataPopup}
        driverList={driverList}
        khungGioList={khungGioList}
        onclose={handleClosePopup}
        onOk={handleOkPopup}
      ></ThayDoiTaiXePopup>
      <TripHistoryPopup
        data={historyPopupData}
        isOpen={isOpenHistory}
        onClose={onCloseHistory}
        cusId={cusId}
      ></TripHistoryPopup>
      <TripMapPopup
        data={tripMapPopupData}
        isOpen={isOpenTripMapPopup}
        onClose={onCloseTripMapPopup}
      ></TripMapPopup>
      <CallHistoryPopup
        data={callHistoryPopupData}
        isOpen={isOpenCallHistoryPopup}
        onClose={onCloseCallHistoryPopup}
      ></CallHistoryPopup>
    </Col>
  );
}

export default MapDetail;
