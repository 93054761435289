import React, { useState } from "react";
import "./Car.scss";
import DriverForm from "./DriverForm";
import DriverTable from "./DriverTable";
import driverApi from "api/Call/driverApi";

function Driver() {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);

  const onData = (value) => {
    setDataTable(value);
  };

  const onLoading = (value) => {
    setLoading(value);
  };

  const onUpdate = () => {
    loadDataInventoryHistory();
  };

  const loadDataInventoryHistory = async () => {
    try {
      onLoading(true);
      const result = await driverApi.getDriverManage();
      if (result.success) {
        onData(result.data);
      }
    } catch (error) {
      onLoading(false);
    } finally {
      onLoading(false);
    }
  };

  return (
    <div>
      <DriverForm onData={onData} onLoading={onLoading} />
      <DriverTable loading={loading} dataTable={dataTable} onUpdate={onUpdate} />
    </div>
  );
}

export default Driver;
