import { Button, Form, Select, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FolderOpenOutlined, DeleteOutlined, VerticalAlignBottomOutlined } from "@ant-design/icons";
import userMgtApi from "api/userMgtApi";
import PropTypes from "prop-types";
import InputCustom from "components/InputCustom";
import SelectCustom from "components/SelectCustom";

UserMgtForm.propTypes = {
  onSearch: PropTypes.func,
};

UserMgtForm.defaultProps = {
  onSearch: null,
};

function UserMgtForm({ onSearch, onClickNew, onClickDelete, onClickSave }) {
  const [form] = Form.useForm();
  const [listCompany, setListCompany] = useState([]);
  const [listRole, setListRole] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    loadDefaultCodeList();
  }, []);

  useEffect(() => {
    loadListRole();
  }, []);

  const onFinish = (values) => {
    onSearch(values);
    form.setFieldValue();
  };

  const loadDefaultCodeList = async () => {
    try {
      let { data: result } = await userMgtApi.searchDefaultCodeList();
      setListCompany(result.lstCo);
    } catch (error) {
      throw Error();
    }
  };

  const loadListRole = async () => {
    try {
      const data = await userMgtApi.searchUserList({});
      setListRole(data.data.userRoleList);
    } catch (error) {
      throw Error();
    }
  };

  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "coCd") {
      setSelectedCompanyId(changedValues[formFieldName]);
      form.setFieldsValue({ companyRole: undefined });
    }
    const formValues = form.getFieldsValue();
    onSearch(formValues);
  };

  const options = [
    { value: "", label: "All" },
    { value: "ACT", label: "Activated" },
    { value: "DACT", label: "Unactivated" },
    { value: "DELT", label: "Deleted" },
  ];

  return (
    <div>
      <Form
        form={form}
        colon={false}
        className="user-form"
        name="companyForm"
        onFinish={onFinish}
        onValuesChange={handleFormValuesChange}
      >
        <Row justify="end">
          <Button type="primary" onClick={onClickNew} icon={<FolderOpenOutlined />}>
            {t("buttons.new")}
          </Button>
          <Button type="primary" onClick={onClickSave} icon={<VerticalAlignBottomOutlined />}>
            {t("buttons.save")}
          </Button>
          <Button
            type="primary"
            // disabled={openDelete}
            onClick={onClickDelete}
            icon={<DeleteOutlined />}
          >
            {t("buttons.delete")}
          </Button>
        </Row>
        <Row className="block-control">
          <Form.Item name="coCd">
            <SelectCustom style={{ width: 300 }} label="Company" placeholdercustom="Company">
              {listCompany.map((company) => (
                <Select.Option key={company.coCd} value={company.coCd}>
                  {company.coNm}
                </Select.Option>
              ))}
            </SelectCustom>
          </Form.Item>
          <Form.Item name="usrNm">
            <InputCustom
              style={{ width: 300 }}
              label="Employee Name/Code"
              placeholdercustom="Employee Name/Code"
            ></InputCustom>
          </Form.Item>

          <Form.Item name="roleId">
            <SelectCustom
              style={{ width: 300 }}
              label="Company Role"
              placeholdercustom="Company Role"
            >
              {listRole
                .filter((role) => role.coCd === selectedCompanyId)
                .map((role) => (
                  <Select.Option key={role.roleId} value={role.id}>
                    {role.roleNm}
                  </Select.Option>
                ))}
            </SelectCustom>
          </Form.Item>
          <Form.Item name="usrTpCd">
            <SelectCustom
              options={options}
              optionFilterProp="children"
              style={{ width: 300 }}
              label="Status"
              placeholdercustom="Status"
            />
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}

export default UserMgtForm;
