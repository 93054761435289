import { Modal, Button, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchButtonList, searchGeneralList, searchGridList } from "redux/system/menuMgtAction";
import { actionMenuMgt } from "redux/system/menuMgtSlice";

function ItemPopup({ show, keyTab, configColumn, onclose }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [key, setKey] = useState([]);
  const [alreadySelectedRowKeys, setAlreadySelectedRowKeys] = useState([]);
  const {
    lsButtonAdd,
    lsGeneralAdd,
    lsGridAdd,
    lsButtonFull,
    lsGeneralFull,
    lsGridFull,
    loadingPopup,
  } = useSelector((state) => state.menuMgt);

  useEffect(() => {
    show && setOpen(show);
    if (show) {
      switch (keyTab) {
        case "button":
          dispatch(searchButtonList());
          setKey("btnNo");
          break;
        case "general":
          dispatch(searchGeneralList());
          setKey("itmNo");
          break;
        case "grid":
          dispatch(searchGridList());
          setKey("itmNo");
          break;
      }
    }
  }, [show]);

  useEffect(() => {
    if (!loadingPopup) {
      switch (keyTab) {
        case "button":
          setDataSource(
            lsButtonFull.filter((ad) => lsButtonAdd.every((fd) => fd.btnNo !== ad.btnNo))
          );
          break;
        case "general":
          setDataSource(
            lsGeneralFull.filter((ad) => lsGeneralAdd.every((fd) => fd.itmNo !== ad.itmNo))
          );
          break;
        case "grid":
          setDataSource(lsGridFull.filter((ad) => lsGridAdd.every((fd) => fd.itmNo !== ad.itmNo)));
          break;
      }
    }
  }, [loadingPopup]);

  const handleClose = () => {
    onclose();
    setOpen(false);
  };

  const addOnclick = () => {
    let dataCheck = dataSource.filter((item) => alreadySelectedRowKeys.includes(item[key]));
    switch (keyTab) {
      case "button":
        dispatch(actionMenuMgt.storeLsButtonAdd(lsButtonAdd.concat(...dataCheck)));
        break;
      case "general":
        dispatch(actionMenuMgt.storeLsGeneralAdd(lsGeneralAdd.concat(...dataCheck)));
        break;
      case "grid":
        dispatch(actionMenuMgt.storeLsGridAdd(lsGridAdd.concat(...dataCheck)));
        break;
    }
    setAlreadySelectedRowKeys([]); // update state to empty array
    setOpen(false);
  };

  const rowSelection = {
    selectedRowKeys: alreadySelectedRowKeys,
    onChange: (selectedRowKeys) => {
      setAlreadySelectedRowKeys(selectedRowKeys);
    },
  };

  return (
    <Modal
      title="Role Management"
      centered
      open={open}
      onCancel={handleClose}
      afterClose={() => {
        onclose();
      }}
      width={800}
      height={800}
      footer={[
        <Button key="add" type="primary" icon={<PlusOutlined />} onClick={addOnclick}>
          Add
        </Button>,
      ]}
    >
      <div style={{ height: "600px" }}>
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          dataSource={dataSource}
          columns={configColumn}
          rowKey={(record) => record[key]}
          pagination={false}
          loading={loadingPopup}
          scroll={{
            y: 560,
          }}
        ></Table>
      </div>
    </Modal>
  );
}

export default ItemPopup;
