import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu, Spin } from "antd";
import PropTypes from "prop-types";
import "./styles/MenuApp.scss";
import { listMenu } from "redux/menuAction";
import { convertTreeDataMenuToTypeAntdMenu } from "utils/common";
import TokenService from "utils/TokenService";

const MenuApp = ({ theme }) => {
  const [openKeys, setOpenKeys] = useState([""]);
  const [items, setItems] = useState([]);
  const { success, loading } = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  const treeMenu = TokenService.getListMenuLocalStorage();
  useEffect(() => {
    if (treeMenu == null) {
      dispatch(listMenu());
    }
    setItems(convertTreeDataMenuToTypeAntdMenu(treeMenu));
  }, [success]);

  let rootSubmenuKeys = [];
  if (treeMenu) {
    rootSubmenuKeys = treeMenu && treeMenu.length > 0 && treeMenu.map((el) => el.mnuPgmId);
  } else {
    dispatch(listMenu());
  }
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <>
      {loading ? (
        <Spin spinning />
      ) : (
        <Menu
          theme={theme}
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          items={items}
        />
      )}
    </>
  );
};

MenuApp.propTypes = {
  theme: PropTypes.string,
};

MenuApp.defaultProps = {
  theme: "",
};

export default MenuApp;
