import { Button, Col, Form, Row } from "antd";
import { SearchOutlined, ExportOutlined } from "@ant-design/icons";
import { InputCustom } from "components";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import MaterialDetailPopup from "./popup/KhungGioDetail";
import moment from "moment";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import khungGioApi from "api/Call/khungGioApi";
dayjs.extend(customParseFormat);
import "moment/locale/vi";

moment.locale("vi");

function KhungGioForm({ onData, onLoading }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loadingExport, setLoadingExport] = useState(false);
  const [isOpenDetail, setIsOpenDetail] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      frmDt: moment(),
      toDt: moment(),
    });
    onFinish();
  }, []);

  const onFinish = (values) => {
    let data = {
      ...values,
    };
    loadDataInventoryHistory(data);
  };

  const loadDataInventoryHistory = async (param) => {
    try {
      onLoading(true);
      const result = await khungGioApi.getKhungGioList(param);
      if (result.success) {
        result.data.map(
          (item) => (
            (item.frmDt = moment(form.getFieldsValue().frmDt).format("YYYY-MM-DD 00:00:00")),
            (item.toDt = moment(form.getFieldsValue().toDt)
              .add(1, "day")
              .format("YYYY-MM-DD 00:00:00")),
            (item.inOutFlg = form.getFieldsValue().inOutFlg),
            (item.custOrdNo = form.getFieldsValue().custOrdNo),
            (item.purOrdNo = form.getFieldsValue().purOrdNo)
          )
        );
        onData(result.data);
      }
    } catch (error) {
      onLoading(false);
    } finally {
      onLoading(false);
    }
  };
  const onClose = () => {
    setIsOpenDetail(false);
    onFinish();
  };

  const handleAdd = () => {
    setIsOpenDetail(true);
  };

  return (
    <>
      <Form colon={false} onFinish={onFinish} form={form} className="form-control-inventory-his">
        <div className="flex justify-end">
          {/* <Form.Item name="mtrlId">
            <InputCustom
              label="Material"
              placeholdercustom="Material"
              style={{ width: "280px" }}
            ></InputCustom>
          </Form.Item> */}
          <div className="mt-1">
            <Button
              type="primary"
              disabled={false}
              htmlType="submit"
              className="button btn_custom"
              icon={<SearchOutlined />}
            >
              Search
            </Button>
            <Button
              type="primary"
              className="button btn_custom"
              icon={<ExportOutlined />}
              onClick={handleAdd}
              loading={loadingExport}
            >
              Thêm mới
            </Button>
          </div>
        </div>
      </Form>
      <MaterialDetailPopup
        // data={record}
        isOpen={isOpenDetail}
        onClose={onClose}
      ></MaterialDetailPopup>
    </>
  );
}

export default KhungGioForm;
