import { createSlice } from "@reduxjs/toolkit";
import { searchAllUser } from "./userPopupAtion";

const initialState = {
  isOpen: false,
  dataUser: null,
  dataCompany: null,
  loading: false,
  error: null,
  success: false,
  dataSelect: null,
};

const userPopupSlice = createSlice({
  name: "userPopup",
  initialState,
  reducers: {
    openUserPopup: (state) => {
      state.isOpen = true;
    },
    closeUserPopup: (state) => {
      state.isOpen = false;
    },
    okUserPopup: (state, { payload }) => {
      state.isOpen = false;
      state.dataSelect = payload;
    },
  },
  extraReducers: {
    [searchAllUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [searchAllUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.dataUser = payload.data.usrLst;
      state.dataCompany = payload.data.admCoVoList;
      state.success = true;
    },
    [searchAllUser.rejected]: (state, { payload }) => {
      state.error = payload.data;
      state.loading = true;
    },
  },
});

const { reducer, actions } = userPopupSlice;

export const { openUserPopup, closeUserPopup, okUserPopup } = actions;

export default reducer;
