import { Button, Form } from "antd";
import { FileAddOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import MaterialDetailPopup from "./popup/CarDetail";
import moment from "moment";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import carApi from "api/Call/carApi";
import "moment/locale/vi";

moment.locale("vi");
dayjs.extend(customParseFormat);

function CarForm({ onData, onLoading }) {
  const [form] = Form.useForm();
  const [dataCar, setDataCar] = useState({ newStatus: true, carStatus: "Y", carType: 6 });
  const [isOpenDetail, setIsOpenDetail] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      frmDt: moment(),
      toDt: moment(),
    });
    onFinish();
  }, []);

  useEffect(() => {
    onFinish();
  }, [isOpenDetail]);

  const onFinish = (values) => {
    let data = {
      ...values,
    };
    loadDataInventoryHistory(data);
  };

  const loadDataInventoryHistory = async (param) => {
    try {
      onLoading(true);
      const result = await carApi.getCar(param);
      if (result.success) {
        onData(result.data);
      }
    } catch (error) {
      onLoading(false);
    } finally {
      onLoading(false);
    }
  };
  const onClose = () => {
    setIsOpenDetail(false);
  };

  const handleAdd = () => {
    setIsOpenDetail(true);
  };

  return (
    <>
      <Form colon={false} onFinish={onFinish} form={form} className="form-control-inventory-his">
        <div className="flex justify-end">
          {/* <Form.Item name="mtrlId">
            <InputCustom
              label="Biển số xe"
              placeholdercustom="Material"
              style={{ width: "280px" }}
            ></InputCustom>
          </Form.Item> */}
          <div className="mt-1">
            {/* <Button
              type="primary"
              disabled={false}
              htmlType="submit"
              className="button btn_custom"
              icon={<SearchOutlined />}
            >
              Tìm
            </Button> */}
            <Button
              type="primary"
              className="button btn_custom"
              icon={<FileAddOutlined />}
              onClick={handleAdd}
            >
              Thêm mới
            </Button>
          </div>
        </div>
      </Form>
      <MaterialDetailPopup
        data={dataCar}
        isOpen={isOpenDetail}
        onClose={onClose}
      ></MaterialDetailPopup>
    </>
  );
}

export default CarForm;
