import { message, Modal, Button } from "antd";
import React, { useState, useEffect } from "react";
import TripRecordListTable from "pages/TripRecordList/TripRecordListTable";
import "./TripHistoryPopup.scss";
import tripRecordApi from "api/Call/tripRecord";
import TripMapPopup from "pages/TripMapPopup/TripMapPopup";

const { confirm } = Modal;

function TripHistoryPopup({ isOpen, onClose, data, cusId }) {
  const [loading, setLoading] = useState();
  const [dataSource, setDataSource] = useState();

  const [dataTripMapPopup, setDataTripMapPopup] = useState();
  const [isOpenTripMapPopup, setIsOpenTripMapPopup] = useState(false);

  useEffect(() => {
    if (isOpen) {
      getTripOrders(data);
    }
  }, [isOpen]);

  const getTripOrders = async (params) => {
    try {
      setLoading(true);
      const result = await tripRecordApi.getTripOrders(params);
      if (result.success) {
        setDataSource(result.data);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const onNew = () => {
    setDataTripMapPopup({ cusId: dataSource[0].cusId, name: dataSource[0].name, status: "NEW" });
    setIsOpenTripMapPopup(true);
  };

  const onCloseTripMapPopup = () => {
    setIsOpenTripMapPopup(false);
  };

  const handleClose = () => {
    onClose(true);
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        className="plan-popup"
        onCancel={() => {
          handleClose();
        }}
        title={
          <div className="flex justify-between">
            <div className="flex">
              <p className="text-lg mb-0 mt-1 flex">
                SĐT<span className="text-blue-500 ml-2">{cusId}</span>
              </p>
            </div>

            <div className="flex">
              <Button
                className="button btn_custom w-24"
                type="primary"
                onClick={onNew}
                size="medium"
              >
                Tạo mới
              </Button>
              <Button
                danger
                className="button btn_custom w-20"
                type="primary"
                onClick={onClose}
                size="medium"
              >
                Hủy
              </Button>
            </div>
          </div>
        }
        closable={false}
        footer={null}
        width={"99%"}
        open={isOpen}
      >
        <div className="flex h-full inventory-his">
          <TripRecordListTable loading={loading} dataTable={dataSource} />
        </div>
      </Modal>
      <TripMapPopup
        data={dataTripMapPopup}
        isOpen={isOpenTripMapPopup}
        onClose={onCloseTripMapPopup}
      ></TripMapPopup>
    </>
  );
}

export default TripHistoryPopup;
