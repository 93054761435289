import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import driverApi from "api/Call/driverApi";
import khungGioApi from "api/Call/khungGioApi";
import tripPlanApi from "api/Call/tripPlanApi";
import { Form, Button, Modal, Spin, message } from "antd";
import moment from "moment";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { DatePickerCustom } from "components";
import "moment/locale/vi";
import TokenService from "utils/TokenService";

const admin = TokenService.getUserLocalStorage()?.coCd == "CLV";
moment.locale("vi");

const { confirm } = Modal;

const TripPlan = () => {
  const [form] = Form.useForm();

  const dateFormat = "DD-MM-YYYY";
  const [loading, setLoading] = useState(false);
  const [listKhungGioTKDN, setListKhungGioTKDN] = useState([]);
  const [listKhungGioDNTK, setListKhungGioDNTK] = useState([]);
  const [date, setDate] = useState(
    moment().isAfter(moment(moment().format("DD/MM/YYYY") + "19:30", "DD/MM/YYYYHH:mm"))
      ? moment().add(1, "d").format("DD/MM/YYYY")
      : moment().format("DD/MM/YYYY")
  );
  const [oldDriverPlan, setOldDriverPlan] = useState([]);
  const [listDriver, setListDriver] = useState([]);
  const [listTKDN, setListTKDN] = useState([]);
  const [listDNTK, setListDNTK] = useState([]);

  const [soGhe, setSoGhe] = useState(0);
  const [soGheTKDN, setSoGheTKDN] = useState(0);
  const [soGheDNTK, setSoGheDNTK] = useState(0);
  const [soChuyen, setSoChuyen] = useState(0);
  const [soChuyenTKDN, setSoChuyenTKDN] = useState(0);
  const [soChuyenDNTK, setSoChuyenDNTK] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();
  const [driverClick, setDriverClick] = useState("");

  useEffect(() => {
    form.setFieldsValue({
      date: moment(date, "DD/MM/YYYY"),
    });
  }, []);

  useEffect(() => {
    onReload();
  }, [date]);

  const onReload = () => {
    setLoading(true);
    getDriver();
    if (date) {
      getKhungGio({ ngayPlan: date, tuyen: "" });
      getDriverPlan({ ngayPlan: date });
    }
  };

  const getDriver = async (param) => {
    const result = await driverApi.getDriver(param);
    if (result.success) {
      setListDriver(result.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    setSoGheTKDN(listKhungGioTKDN.reduce((n, { soKhach }) => n + soKhach, 0));
    setSoGheDNTK(listKhungGioDNTK.reduce((n, { soKhach }) => n + soKhach, 0));
  }, [listKhungGioDNTK, listKhungGioTKDN]);

  const getDriverPlan = async (param) => {
    const result = await tripPlanApi.getDriverPlan(param);

    if (result.success) {
      setOldDriverPlan(result.data);
      const tkdn = result.data.filter((item, index) => {
        return item.tuyen == "TKDN";
      });
      const dntk = result.data.filter((item, index) => {
        return item.tuyen == "DNTK";
      });
      setListTKDN(tkdn);
      setListDNTK(dntk);
      setSoChuyen(result.data.length);
      setSoChuyenTKDN(tkdn.length);
      setSoChuyenDNTK(dntk.length);
    }
  };

  const getKhungGio = async (param) => {
    const result = await khungGioApi.getKhungGio(param);
    setListKhungGioTKDN(
      result.data.filter((item) => {
        return item.tuyen == "TKDN";
      })
    );
    setListKhungGioDNTK(
      result.data.filter((item, index) => {
        return item.tuyen == "DNTK";
      })
    );
    if (result) {
      setSoGhe(result.data.reduce((n, { soKhach }) => n + soKhach, 0));
    }
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId == destination.droppableId) {
      return;
    }

    const oldDrop = source.droppableId.split("-");
    const newDrop = destination.droppableId.split("-");
    const drag = draggableId.split("-");

    const newDriver = listDriver.find((item) => {
      return item.driverId == drag[0];
    });
    const newLoc = {
      ...newDriver,
      tuyen: newDrop[0],
      ngayPlan: newDrop[1],
      khungGioId: newDrop[2],
    };
    let finalTKDN = [];
    let finalDNTK = [];
    if (newDrop[0] == "TKDN") {
      const khungGioLs = listTKDN.filter((item) => {
        return item.khungGioId == newDrop[2];
      });
      if (khungGioLs.some((e) => e.driverId == drag[0])) {
        messageApi.open({
          type: "error",
          content: "Đã tồn tại tài xế này trong khung giờ. Vui lòng kiểm tra lại!",
        });
        return;
      }
      const newList = [...listTKDN, newLoc];
      if (oldDrop[0] == "TKDN") {
        finalTKDN = newList.filter((item) => {
          return !(item.khungGioId == oldDrop[2] && item.driverId == newDriver.driverId);
        });
        finalDNTK = listDNTK;
      } else if (oldDrop[0] == "DNTK") {
        finalTKDN = newList;
        finalDNTK = listDNTK.filter((item) => {
          return !(item.khungGioId == oldDrop[2] && item.driverId == newDriver.driverId);
        });
      } else {
        finalTKDN = newList;
        finalDNTK = listDNTK;
      }
      setListTKDN(finalTKDN);
      setListDNTK(finalDNTK);
    } else if (newDrop[0] == "DNTK") {
      const khungGioLs = listDNTK.filter((item) => {
        return item.khungGioId == newDrop[2];
      });
      if (khungGioLs.some((e) => e.driverId == drag[0])) {
        messageApi.open({
          type: "error",
          content: "Đã tồn tại tài xế này trong khung giờ. Vui lòng kiểm tra lại!",
        });
        return;
      }
      const newList = [...listDNTK, newLoc];
      if (oldDrop[0] == "TKDN") {
        finalTKDN = listTKDN.filter((item) => {
          return !(item.khungGioId == oldDrop[2] && item.driverId == newDriver.driverId);
        });
        finalDNTK = newList;
      } else if (oldDrop[0] == "DNTK") {
        finalTKDN = listTKDN;
        finalDNTK = newList.filter((item) => {
          return !(item.khungGioId == oldDrop[2] && item.driverId == newDriver.driverId);
        });
      } else {
        finalTKDN = listTKDN;
        finalDNTK = newList;
      }
      setListTKDN(finalTKDN);
      setListDNTK(finalDNTK);
    }
  };

  const handleOnClickDriver = (param) => {
    if (param.driverId == driverClick) {
      setDriverClick("");
    } else {
      setDriverClick(param.driverId);
    }
  };

  const handleChange = (value) => {
    setDate(moment(value).format("DD/MM/YYYY"));
  };
  const handleNext = () => {
    form.setFieldsValue({
      date: moment(date, "DD/MM/YYYY").add(1, "d"),
    });
    setDate(moment(moment(date, "DD/MM/YYYY").add(1, "d")).format("DD/MM/YYYY"));
  };

  const handlePre = () => {
    form.setFieldsValue({
      date: moment(date, "DD/MM/YYYY").subtract(1, "d"),
    });
    setDate(moment(moment(date, "DD/MM/YYYY").subtract(1, "d")).format("DD/MM/YYYY"));
  };

  const saveTripPlan = () => {
    confirm({
      title: "Bạn có muốn lưu lại lịch chạy này",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        setLoading(true);
        const newDriverPlan = [...listDNTK, ...listTKDN];
        const lsDelete = oldDriverPlan.filter(
          (obj1) =>
            !newDriverPlan.some(
              (obj2) => obj2.khungGioId === obj1.khungGioId && obj2.driverId === obj1.driverId
            )
        );

        const lsInsert = newDriverPlan.filter(
          (obj1) =>
            !oldDriverPlan.some(
              (obj2) => obj2.khungGioId === obj1.khungGioId && obj2.driverId === obj1.driverId
            )
        );

        const result = await tripPlanApi.saveTripPlan({ lsDelete: lsDelete, lsInsert: lsInsert });
        if (result.success) {
          onReload();
        }
      },
      onCancel() {
        return;
      },
    });
  };

  const getTypeTitle = (lsXe, khungGioId, soKhach) => {
    const khungGioLs = lsXe.filter((item) => {
      return item.khungGioId == khungGioId;
    });
    let sum = khungGioLs.reduce(function (prev, current) {
      return prev + current.carType;
    }, 0);
    if (soKhach == 0 && sum == 0) {
      return "";
    } else if (soKhach < sum) {
      return "text-blue-500 ";
    } else if (soKhach > sum) {
      return "text-red-500 ";
    } else {
      return "text-green-500 ";
    }
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Spin spinning={loading}>
        <Form colon={false} form={form}>
          {contextHolder}
          <div className="!mt-0 w-full h-16">
            <div className="flex justify-between h-8">
              <div className="flex">
                <p className="text-lg font-bold mr-2">Tổng Cộng</p>
                <p className="text-lg font-bold text-red-500">{soGhe}</p>
                <p className="text-lg font-bold ml-2 mr-2">Ghế /</p>
                <p className="text-lg font-bold text-red-500">{soChuyen}</p>
                <p className="text-lg font-bold ml-2">Chuyến</p>
              </div>
              <div className="flex">
                <Button type="primary" size="medium" onClick={onReload} danger className="mr-2">
                  Tải lại
                </Button>
                <Button type="primary" size="medium" onClick={saveTripPlan} className="mr-2 ">
                  Lưu
                </Button>
              </div>
            </div>
            <div className="flex">
              <Button
                // type="dashed"
                icon={<LeftOutlined style={{ color: "black" }} />}
                // size="small"
                onClick={handlePre}
                className="mr-2"
              />
              <Form.Item
                name="date"
                rules={[
                  {
                    required: true,
                    message: "From Date is required",
                  },
                ]}
              >
                <DatePickerCustom
                  // label="Ngày chạy"
                  placeholder={null}
                  size="small"
                  style={{ width: "200px", height: "30px" }}
                  format={dateFormat}
                  // disabledDate={disabledDateTo}
                  onChange={handleChange}
                ></DatePickerCustom>
              </Form.Item>
              <Button
                // type="dashed"
                icon={<RightOutlined style={{ color: "black" }} />}
                onClick={handleNext}
                // size="large"
                className="ml-2 "
              />
            </div>
          </div>
          <div className="flex h-28 w-fit mb-4">
            <Droppable droppableId={"NOPLAN"} className="h-28 w-full">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="flex flex-wrap mt-2"
                >
                  {listDriver.map((item, index) => (
                    <Draggable
                      key={index}
                      draggableId={item.driverId + "-NOPLAN-" + date}
                      index={index}
                      isDragDisabled={!item.carNumber}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={
                            (item.carNumber
                              ? "border border-slate-500 bg-slate-100 rounded-md h-12 w-26 py-2 px-1 mr-2 "
                              : "border border-slate-500 bg-gray-400 rounded-md h-12 w-26 py-2 px-1 mr-2 ") +
                            (item.driverId == driverClick ? "!bg-blue-300" : "")
                          }
                          onClick={() => {
                            handleOnClickDriver(item);
                          }}
                        >
                          <p className="text-center text-xs m-0">{item.driverName}</p>
                          <p className="text-center text-xs m-0">
                            {item.carNumber ? item.carNumber : "-"}
                          </p>
                        </div>
                        // {snapshot.isDragging && (
                        //   <div
                        //     className={
                        //       item.carNumber
                        //         ? "border border-slate-500 bg-slate-100 rounded-md h-12 w-26 py-2 px-1 mr-2"
                        //         : "border border-slate-500 bg-gray-400 rounded-md h-12 w-26 py-2 px-1 mr-2"
                        //     }
                        //   >
                        //     {item.driverName}
                        //   </div>
                        // )}
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <div className="w-full overflow-x-auto">
            <div className="flex">
              <p className="text-base font-bold mb-0">Tam Kỳ - Đà Nẵng</p>
              <p className="text-base font-bold text-red-500 ml-2 mb-0">{soGheTKDN}</p>
              <p className="text-base font-bold ml-2 mb-0">Ghế /</p>
              <p className="text-base font-bold text-red-500 ml-1 mb-0">{soChuyenTKDN}</p>
              <p className="text-base font-bold ml-1 mb-0">Chuyến</p>
            </div>
            <div className="flex border-spacing-1 border-solid border-gray-200 border-2 mt-2 w-fit">
              {listKhungGioTKDN.map((itemKhungGio, indexKhungGio) => (
                <div
                  key={indexKhungGio}
                  className="border-spacing-1 border-solid border-gray-200 border-r-2 w-28"
                >
                  <p
                    className={
                      getTypeTitle(listTKDN, itemKhungGio.khungGioId, itemKhungGio.soKhach) +
                      "p-2 border-spacing-1 border-solid border-gray-200 border-b-2 m-0 text-center"
                    }
                  >
                    {itemKhungGio.khungGioName}({itemKhungGio.soKhach}/
                    {listTKDN
                      .filter((tkdn) => {
                        return tkdn.ngayPlan == date && tkdn.khungGioId == itemKhungGio.khungGioId;
                      })
                      .reduce((n, { carType }) => n + carType, 0)}
                    )
                  </p>
                  <Droppable
                    droppableId={"TKDN-" + date + "-" + itemKhungGio.khungGioId}
                    isDropDisabled={
                      moment()
                        .add(-90, "minutes")
                        .isAfter(moment(date + itemKhungGio.khungGioName, "DD/MM/YYYYHH:mm")) &&
                      !admin
                    }
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="w-full min-h-[8rem]"
                      >
                        {listTKDN
                          .filter((tkdn) => {
                            return (
                              tkdn.ngayPlan == date && tkdn.khungGioId == itemKhungGio.khungGioId
                            );
                          })
                          .map((item, index) => (
                            <Draggable
                              key={index}
                              draggableId={
                                item.driverId +
                                "-" +
                                item.tuyen +
                                "-" +
                                item.ngayPlan +
                                "-" +
                                item.khungGioId
                              }
                              index={item.khungGioId}
                              isDragDisabled={
                                moment()
                                  .add(-90, "minutes")
                                  .isAfter(
                                    moment(date + itemKhungGio.khungGioName, "DD/MM/YYYYHH:mm")
                                  ) && !admin
                              }
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  onClick={() => {
                                    handleOnClickDriver(item);
                                  }}
                                  className={
                                    item.driverId == driverClick
                                      ? "!bg-blue-300 border border-slate-500 rounded-md h-12 w-26 p-2 mr-2 my-1 mx-1 group relative"
                                      : "border border-slate-500 bg-slate-100 rounded-md h-12 w-26 p-2 mr-2 my-1 mx-1 group relative"
                                  }
                                >
                                  <p className="text-center text-xs m-0">{item.driverName}</p>
                                  <p className="text-center text-xs m-0">{item.carNumber}</p>
                                  {(!moment()
                                    .add(-90, "minutes")
                                    .isAfter(
                                      moment(date + itemKhungGio.khungGioName, "DD/MM/YYYYHH:mm")
                                    ) ||
                                    admin) && (
                                    <button
                                      onClick={() => {
                                        const khungGioLs = listTKDN.filter((o) => {
                                          return (
                                            o.khungGioId != item.khungGioId ||
                                            o.driverId != item.driverId
                                          );
                                        });
                                        setListTKDN(khungGioLs);
                                      }}
                                      className="hidden group-hover:block absolute h-full w-12 bg-red-400 text-lg font-extrabold bg-opacity-50 rounded-r-md border-slate-500 bottom-0 right-0 text-red-700"
                                    >
                                      x
                                    </button>
                                  )}
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full overflow-x-auto mt-4">
            <div className="flex">
              <p className="text-base font-bold mb-0">Đà Nẵng - Tam Kỳ</p>
              <p className="text-base font-bold text-red-500 ml-2 mb-0">{soGheDNTK}</p>
              <p className="text-base font-bold ml-2 mb-0">Ghế /</p>
              <p className="text-base font-bold text-red-500 ml-1 mb-0">{soChuyenDNTK}</p>
              <p className="text-base font-bold ml-1 mb-0">Chuyến</p>
            </div>
            <div className="flex border-spacing-1 border-solid border-gray-200 border-2 mt-2 w-fit">
              {listKhungGioDNTK.map((itemKhungGio, indexKhungGio) => (
                <div
                  key={indexKhungGio}
                  className="border-spacing-1 border-solid border-gray-200 border-r-2 w-28"
                >
                  <p
                    className={
                      getTypeTitle(listDNTK, itemKhungGio.khungGioId, itemKhungGio.soKhach) +
                      "p-2 border-spacing-1 border-solid border-gray-200 border-b-2 m-0 text-center"
                    }
                  >
                    {itemKhungGio.khungGioName}({itemKhungGio.soKhach}/
                    {listDNTK
                      .filter((dntk) => {
                        return dntk.ngayPlan == date && dntk.khungGioId == itemKhungGio.khungGioId;
                      })
                      .reduce((n, { carType }) => n + carType, 0)}
                    )
                  </p>
                  <Droppable
                    droppableId={"DNTK-" + date + "-" + itemKhungGio.khungGioId}
                    isDropDisabled={
                      moment()
                        .add(-90, "minutes")
                        .isAfter(moment(date + itemKhungGio.khungGioName, "DD/MM/YYYYHH:mm")) &&
                      !admin
                    }
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="w-full min-h-[8rem]"
                      >
                        {listDNTK
                          .filter((dntk) => {
                            return (
                              dntk.ngayPlan == date && dntk.khungGioId == itemKhungGio.khungGioId
                            );
                          })
                          .map((item, index) => (
                            <Draggable
                              key={index}
                              draggableId={
                                item.driverId +
                                "-" +
                                item.tuyen +
                                "-" +
                                item.ngayPlan +
                                "-" +
                                item.khungGioId
                              }
                              index={item.khungGioId}
                              isDragDisabled={
                                moment()
                                  .add(-90, "minutes")
                                  .isAfter(
                                    moment(date + itemKhungGio.khungGioName, "DD/MM/YYYYHH:mm")
                                  ) && !admin
                              }
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  onClick={() => {
                                    handleOnClickDriver(item);
                                  }}
                                  className={
                                    item.driverId == driverClick
                                      ? "border border-slate-500 bg-blue-300 rounded-md h-12 w-26 p-2 mr-2 my-1 mx-1 group relative"
                                      : "border border-slate-500 bg-slate-100 rounded-md h-12 w-26 p-2 mr-2 my-1 mx-1 group relative"
                                  }
                                >
                                  <p className="text-center text-xs m-0">{item.driverName}</p>
                                  <p className="text-center text-xs m-0">{item.carNumber}</p>
                                  {(!moment()
                                    .add(-90, "minutes")
                                    .isAfter(
                                      moment(date + itemKhungGio.khungGioName, "DD/MM/YYYYHH:mm")
                                    ) ||
                                    admin) && (
                                    <button
                                      onClick={() => {
                                        const khungGioLs = listDNTK.filter((o) => {
                                          return (
                                            o.khungGioId != item.khungGioId ||
                                            o.driverId != item.driverId
                                          );
                                        });
                                        setListDNTK(khungGioLs);
                                        handleOnClickDriver(item);
                                      }}
                                      className="hidden group-hover:block absolute h-full w-12 bg-red-400 text-lg font-extrabold bg-opacity-50 rounded-r-md border-slate-500 bottom-0 right-0 text-red-700"
                                    >
                                      x
                                    </button>
                                  )}
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              ))}
            </div>
          </div>
        </Form>
      </Spin>
    </DragDropContext>
  );
};

export default TripPlan;
