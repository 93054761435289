import { Button, Form, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import MaterialDetailPopup from "./popup/DriverDetail";
import moment from "moment";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import callApi from "api/Call/callApi";
dayjs.extend(customParseFormat);
import "moment/locale/vi";

moment.locale("vi");

function CallHistoryListForm({ onData, onLoading, loading }) {
  const [form] = Form.useForm();
  const [isOpenDetail, setIsOpenDetail] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      date: moment(),
    });
    if (!loading) {
      onFinish({});
    }
  }, []);

  useEffect(() => {
    // onFinish();
  }, [isOpenDetail]);

  const onFinish = (values) => {
    getCallHistory(values);
  };

  const getCallHistory = async (values) => {
    try {
      onLoading(true);
      const result = await callApi.getCallHistory(values);
      if (result.success) {
        onLoading(false);
        onData(result.data);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  // const onChangeForm = () => {
  //   const param = form.getFieldValue();
  //   const ls = dataSource.filter((item) => {
  //     return (
  //       (!param.tuyen || item.tuyen === param.tuyen) &&
  //       (!param.khungGioName || item.khungGioName === param.khungGioName)
  //     );
  //   });
  //   onData(ls);
  // };
  const onClose = () => {
    setIsOpenDetail(false);
  };

  return (
    <>
      <Form
        colon={false}
        form={form}
        onFinish={getCallHistory}
        className="form-control-inventory-his"
      >
        <div className="flex justify-end mt-1 mb-2">
          <Form.Item name="callNumber">
            <Input className="!rounded-md" allowClear placeholder="Số điện thoại"></Input>
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="button btn_custom !w-24"
            icon={<SearchOutlined />}
          >
            Tìm
          </Button>
        </div>
      </Form>
      <MaterialDetailPopup
        // data={record}
        isOpen={isOpenDetail}
        onClose={onClose}
      ></MaterialDetailPopup>
    </>
  );
}

export default CallHistoryListForm;
