import axiosClient from "core/axiosClient";
const driverApi = {
  getDriver: () => {
    const url = "/api/driver/getDriver";
    return axiosClient.get(url);
  },
  getDriverManage: () => {
    const url = "/api/driver/getDriverManage";
    return axiosClient.get(url);
  },
  saveDriver: (params) => {
    const url = "/api/driver/saveDriver";
    return axiosClient.post(url, params);
  },
  updateDriver: (params) => {
    const url = "/api/driver/updateDriver";
    return axiosClient.post(url, params);
  },
  updateDriverStaus: (params) => {
    const url = "/api/driver/updateDriverStatus";
    return axiosClient.post(url, params);
  },
};
export default driverApi;
