import React, { useState } from "react";
import "./VeThangList.scss";
import VeThangListForm from "./VeThangListForm";
import VeThangListTable from "./VeThangListTable";
import VeThangNew from "./VeThangNew";

function VeThangList() {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [onNew, setOnNew] = useState(false);
  const [dataEdit, setDataEdit] = useState();

  const onData = (value) => {
    setDataTable(value);
  };

  const onDataEdit = (value) => {
    setDataEdit(value);
  };

  const onLoading = (value) => {
    setLoading(value);
  };

  const setOnNewFunc = (param) => {
    setDataEdit();
    setOnNew(param);
  };
  const setOnEditFunc = (param) => {
    setOnNew(param);
  };

  return (
    <div>
      {!onNew && (
        <div>
          <VeThangListForm onData={onData} onLoading={onLoading} setOnNewFunc={setOnNewFunc} />
          <VeThangListTable
            loading={loading}
            dataTable={dataTable}
            onDataEdit={onDataEdit}
            setOnNewFunc={setOnEditFunc}
          />
        </div>
      )}
      {onNew && (
        <div>
          <VeThangNew dataEdit={dataEdit} onLoading={onLoading} setOnNewFunc={setOnNewFunc} />
        </div>
      )}
    </div>
  );
}

export default VeThangList;
