import {
  DATA_LOGIN,
  KEY_LOCAL_STORE,
  LIST_MENU,
  REMEMBER,
  USER_INFO,
  USER_CALL,
  TOKEN_CALL,
} from "core/Constants";

class TokenService {
  setUserLocalStorage(user) {
    localStorage.setItem(KEY_LOCAL_STORE, JSON.stringify(user));
  }

  getUserLocalStorage() {
    return JSON.parse(localStorage.getItem(KEY_LOCAL_STORE));
  }

  getTokenLocalStorage() {
    const user = JSON.parse(localStorage.getItem(KEY_LOCAL_STORE));
    return user?.token;
  }

  updateTokenLocalStorage(token) {
    let user = JSON.parse(localStorage.getItem(KEY_LOCAL_STORE));
    user.token = token;
    localStorage.setItem(KEY_LOCAL_STORE, JSON.stringify(user));
  }

  getRefreshTokenLocalStorage() {
    const user = JSON.parse(localStorage.getItem(KEY_LOCAL_STORE));
    return user?.refreshToken;
  }

  removeUserLocalStorage() {
    localStorage.removeItem(KEY_LOCAL_STORE);
  }

  getRoleLocalStorage() {
    return JSON.parse(localStorage.getItem(KEY_LOCAL_STORE))?.role;
  }
  setRemember(remember) {
    localStorage.setItem(REMEMBER, JSON.stringify(remember));
  }

  setAccount(data) {
    localStorage.setItem(DATA_LOGIN, JSON.stringify(data));
  }

  getAccount() {
    const data = JSON.parse(localStorage.getItem(DATA_LOGIN));
    return data ? data : { username: "", password: "" };
  }

  removeAccount() {
    localStorage.removeItem(DATA_LOGIN);
  }

  setListMenuLocalStorage(data) {
    localStorage.setItem(LIST_MENU, JSON.stringify(data));
  }

  getListMenuLocalStorage() {
    return JSON.parse(localStorage.getItem(LIST_MENU));
  }

  removeListMenuLocalStorage() {
    localStorage.removeItem(LIST_MENU);
  }

  setUsrId(data) {
    localStorage.setItem("usrId", data);
  }

  getUsrId() {
    return localStorage.getItem("usrId");
  }
  setUserInfo(user) {
    localStorage.setItem(USER_INFO, JSON.stringify(user));
  }
  getUserInfo() {
    return JSON.parse(localStorage.getItem(USER_INFO));
  }
  getTokenCall() {
    // const user = JSON.parse(localStorage.getItem(TOKEN_CALL));
    return localStorage.getItem(TOKEN_CALL);
  }

  updateTokenCall(token) {
    // let user = JSON.parse(localStorage.getItem(TOKEN_CALL));
    // user.token = token;
    localStorage.setItem(TOKEN_CALL, token);
  }

  setUserCall(user) {
    localStorage.setItem(USER_CALL, JSON.stringify(user));
  }
  getUserCall() {
    return JSON.parse(localStorage.getItem(USER_CALL));
  }
}

export default new TokenService();
