import { Row } from "antd";
import React, { useState } from "react";
import TripControl from "./Components/TripControl";
import MapDetail from "./Components/MapDetail";
import "./TripMap.scss";

function TripMap() {
  const [tripOrder, setTripOrder] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [khungGioList, setKhungGioList] = useState([]);

  const [addFlg, setAddFlg] = useState(false);
  const [ghiChuFlg, setGhiChuFlg] = useState(false);
  const [gheFlg, setGheFlg] = useState(false);
  const [reloadFlg, setReloadFlg] = useState(false);

  // const setTripInforFunc = (a, b, c, d, e, f) => {
  //   setTripInfor({
  //     diemDonAdd: a,
  //     diemDonLat: b,
  //     diemDonLng: c,
  //     diemTraAdd: d,
  //     diemTraLat: e,
  //     diemTraLng: f,
  //   });
  // };

  const setTripOrderFunc = (params, driverList, khungGioList) => {
    setTripOrder(params);
    setDriverList(driverList);
    setKhungGioList(khungGioList);
  };

  const setAddFlgFunc = () => {
    setAddFlg(!addFlg);
    setGhiChuFlg(false);
    setGheFlg(false);
  };

  const setGhiChuFlgFunc = () => {
    setAddFlg(false);
    setGhiChuFlg(!ghiChuFlg);
    setGheFlg(false);
  };

  const setGheFlgFunc = () => {
    setAddFlg(false);
    setGhiChuFlg(false);
    setGheFlg(!gheFlg);
  };

  const setReloadFlgFunc = () => {
    setReloadFlg(!reloadFlg);
  };

  return (
    <>
      <Row className="content-box">
        {/* <StandaloneSearchBox
          onLoad={(ref) => (inputRef.current = ref)}
          onPlacesChanged={handlePlaceChanged}
        >
          <input type="text" className="form-control" placeholder="Enter Location" />
        </StandaloneSearchBox> */}
        <TripControl
          setTripOrderFunc={setTripOrderFunc}
          setAddFlgFunc={setAddFlgFunc}
          setGhiChuFlgFunc={setGhiChuFlgFunc}
          setGheFlgFunc={setGheFlgFunc}
          setReloadFlgFunc={setReloadFlgFunc}
          addFlg={addFlg}
          ghiChuFlg={ghiChuFlg}
          gheFlg={gheFlg}
          reloadFlg={reloadFlg}
        />
        <MapDetail
          tripOrder={tripOrder}
          addFlg={addFlg}
          ghiChuFlg={ghiChuFlg}
          gheFlg={gheFlg}
          driverList={driverList}
          khungGioList={khungGioList}
          setReloadFlgFunc={setReloadFlgFunc}
        />
      </Row>
    </>
  );
}

export default TripMap;
