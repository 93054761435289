import React, { useState, useEffect } from "react";
// import "./stopwatch.scss";
const Stopwatch = ({ show, run }) => {
  // state to store time
  const [time, setTime] = useState(0);

  // state to check stopwatch running or not
  // const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let intervalId;
    if (run) {
      // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
      intervalId = setInterval(() => setTime(time + 1), 10);
    }
    return () => clearInterval(intervalId);
  }, [run, time]);

  useEffect(() => {
    reset();
  }, [run]);

  // Hours calculation

  // Minutes calculation
  const minutes = Math.floor((time % 360000) / 6000);

  // Seconds calculation
  const seconds = Math.floor((time % 6000) / 100);

  // Milliseconds calculation
  const milliseconds = time % 100;
  const reset = () => {
    setTime(0);
  };
  return (
    <div className={"stopwatch-container " + (!show ? "hidden" : "show")}>
      <p className="stopwatch-time text-lg text-center text-cyan-100">
        {minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}:
        {milliseconds.toString().padStart(2, "0")}
      </p>
    </div>
  );
};

export default Stopwatch;
