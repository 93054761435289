import { Button, Form, Modal, Checkbox, Switch } from "antd";
import { CheckOutlined, CloseOutlined, SaveOutlined, RollbackOutlined } from "@ant-design/icons";
import { InputCustom, SelectCustom, DatePickerCustom, InputNumberCustom } from "components";
import React, { useState, useEffect, useRef } from "react";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import Geocode from "react-geocode";
import moment from "moment";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import khungGioApi from "api/Call/khungGioApi";
import ticketApi from "api/Call/ticketApi";
dayjs.extend(customParseFormat);
import "moment/locale/vi";
import { GOOGLE_MAP_API } from "core/Constants";

moment.locale("vi");
const { confirm } = Modal;

const libraries = ["places"];

function VeThangListForm({ dataEdit, onLoading, setOnNewFunc }) {
  Geocode.setApiKey(GOOGLE_MAP_API.GEOCODE);
  Geocode.enableDebug();
  const [form] = Form.useForm();
  const CheckboxGroup = Checkbox.Group;
  const [khungGioComboDi, setKhungGioComboDi] = useState([]);
  const [khungGioComboVe, setKhungGioComboVe] = useState([]);
  const [loTrinhVe, setLotrinhVe] = useState(true);
  const [tuyenDiId, setTuyenDiId] = useState("TKDN");
  const [tuyenVeId, setTuyenVeId] = useState("DNTK");
  const [locDi, setLocDi] = useState({});
  const [locVe, setLocVe] = useState({});
  const diemDonRefDi = useRef();
  const diemTraRefDi = useRef();
  const diemDonRefVe = useRef();
  const diemTraRefVe = useRef();

  const thuCheckbox = [
    {
      value: "MONDAY",
      label: "Thứ hai",
    },
    {
      value: "TUESDAY",
      label: "Thứ ba",
    },
    {
      value: "WEDNESDAY",
      label: "Thứ tư",
    },
    {
      value: "THURSDAY",
      label: "Thứ năm",
    },
    {
      value: "FRIDAY",
      label: "Thứ sáu",
    },
    {
      value: "SATURDAY",
      label: "Thứ bảy",
    },
    {
      value: "SUNDAY",
      label: "Chủ nhật",
    },
  ];

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_API.MAP,
    libraries: libraries,
    language: "vi",
  });

  // useEffect(() => {
  //   form.setFieldsValue({
  //     startDate: moment(),
  //     tickStatus: "Y",
  //     tienFlg: 1,
  //     tuyenDi: "TKDN",
  //     tuyenVe: "DNTK",
  //   });
  //   setKhungGio();
  // }, []);

  useEffect(() => {
    if (dataEdit) {
      form.resetFields();
      form.setFieldsValue({
        cusName: dataEdit.cusName,
        cusId: dataEdit.cusId,
        tickStatus: dataEdit.tickStatus,
        tienFlg: dataEdit.tienFlg == 0 ? "Y" : "N",
        startDate: moment(dataEdit.startDate, "DD/MM/YYYY"),
        endDate: dataEdit.endDate ? moment(dataEdit.endDate, "DD/MM/YYYY") : null,
        remark: dataEdit.remark,
        tuyenDi: dataEdit.tuyenDi,
        khungGioIdDi: dataEdit.khungGioIdDi,
        carTypeDi: dataEdit.carTypeDi,
        soGheDi: dataEdit.soGheDi,
        giaMoiDi: dataEdit.giaMoiDi,
        tienDiFlg: dataEdit.tienDiFlg == 0,
        thuDi: dataEdit.thuDi.split(","),
      });
      setLocDi({
        tripStartAddDi: dataEdit.tripStartAddDi,
        tripStartLatDi: dataEdit.tripStartLatDi,
        tripStartLngDi: dataEdit.tripStartLngDi,
        tripEndAddDi: dataEdit.tripEndAddDi,
        tripEndLatDi: dataEdit.tripEndLatDi,
        tripEndLngDi: dataEdit.tripEndLngDi,
      });
      setTuyenDiId(dataEdit.tuyenDi);
      if (dataEdit.tuyenVe) {
        form.setFieldsValue({
          tuyenVe: dataEdit.tuyenVe,
          khungGioIdVe: dataEdit.khungGioIdVe,
          carTypeVe: dataEdit.carTypeVe,
          soGheVe: dataEdit.soGheVe,
          giaMoiVe: dataEdit.giaMoiVe,
          tienVeFlg: dataEdit.tienVeFlg == 0,
          thuVe: dataEdit.thuVe.split(","),
          loTrinhVeSwitch: true,
        });
        setLocVe({
          tripStartAddVe: dataEdit.tripStartAddVe,
          tripStartLatVe: dataEdit.tripStartLatVe,
          tripStartLngVe: dataEdit.tripStartLngVe,
          tripEndAddVe: dataEdit.tripEndAddVe,
          tripEndLatVe: dataEdit.tripEndLatVe,
          tripEndLngVe: dataEdit.tripEndLngVe,
        });
        setTuyenVeId(dataEdit.tuyenVe);
        setLotrinhVe(true);
      } else {
        setLotrinhVe(false);
        form.setFieldsValue({
          loTrinhVeSwitch: false,
        });
      }
    } else {
      form.resetFields();
      form.setFieldsValue({
        startDate: moment(),
        tickStatus: "Y",
        tienFlg: "N",
        tuyenDi: "TKDN",
        tuyenVe: "DNTK",
      });
      setKhungGio();
    }
  }, [dataEdit]);

  useEffect(() => {
    setKhungGio();
  }, [tuyenDiId, tuyenVeId]);

  const setKhungGio = async () => {
    const resultKhungGio = await khungGioApi.getKhungGioList({});
    if (resultKhungGio.success) {
      const rsDi = resultKhungGio.data.filter((item) => {
        return item.tuyen == tuyenDiId;
      });
      const rsVe = resultKhungGio.data.filter((item) => {
        return item.tuyen == tuyenVeId;
      });
      const dataParseDi = [];
      const dataParseVe = [];
      rsDi.map((item) =>
        dataParseDi.push({
          label: item["khungGioName"],
          value: item["khungGioId"],
        })
      );
      rsVe.map((item) =>
        dataParseVe.push({
          label: item["khungGioName"],
          value: item["khungGioId"],
        })
      );
      setKhungGioComboDi(dataParseDi);
      setKhungGioComboVe(dataParseVe);
    }
  };

  const onFinish = async () => {
    await confirm({
      title: "Bạn có muốn lưu lại chuyến đi này",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        const values = await form.getFieldValue();
        const param = {
          ...values,
          ...locDi,
          ...locVe,
          thuDi: values.thuDi.join(","),
          thuVe: values.thuDi.join(","),
          startDate: moment(values.startDate).format("DD/MM/YYYY"),
          endDate: values.endDate ? moment(values.endDate).format("DD/MM/YYYY") : "",
          tienDiFlg: values.tienDiFlg ? 0 : 1,
          tienVeFlg: values.tienVeFlg ? 0 : 1,
          tienFlg: values.tienFlg == "Y" ? 0 : 1,
          tuyenVe: loTrinhVe ? tuyenVeId : "",
        };
        let result;
        if (dataEdit) {
          result = await ticketApi.updateTicket({ ...param, tickId: dataEdit.tickId });
        } else {
          result = await ticketApi.saveTicket(param);
        }

        if (result.success) {
          setOnNewFunc(false);
        }
      },
      onCancel() {
        return;
      },
    });
  };

  const onDiemDonSelectedDi = () => {
    const [place] = diemDonRefDi.current.getPlaces();
    const address = place.formatted_address,
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    setLocDi({
      ...locDi,
      tripStartAddDi: address,
      tripStartLatDi: latValue,
      tripStartLngDi: lngValue,
    });
  };

  const onDiemTraSelectedDi = () => {
    const [place] = diemTraRefDi.current.getPlaces();

    const address = place.formatted_address,
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    setLocDi({
      ...locDi,
      tripEndAddDi: address,
      tripEndLatDi: latValue,
      tripEndLngDi: lngValue,
    });
  };

  const onDiemDonSelectedVe = () => {
    const [place] = diemDonRefVe.current.getPlaces();
    const address = place.formatted_address,
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    setLocVe({
      ...locVe,
      tripStartAddVe: address,
      tripStartLatVe: latValue,
      tripStartLngVe: lngValue,
    });
  };

  const onDiemTraSelectedVe = () => {
    const [place] = diemTraRefVe.current.getPlaces();

    const address = place.formatted_address,
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    setLocVe({
      ...locVe,
      tripEndAddVe: address,
      tripEndLatVe: latValue,
      tripEndLngVe: lngValue,
    });
  };

  return (
    <>
      <div className="text-lg font-semibold mb-2">Tạo mới vé tháng</div>
      <Form colon={false} form={form} className="form-control-inventory-his">
        <div className="p-4 mb-4 border border-white bg-white rounded-md">
          <p className="text-red-500 font-medium text-base">Thông tin khách</p>
          <div className="flex space-x-4">
            <Form.Item
              name="cusName"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Tên",
                },
              ]}
            >
              <InputCustom label="Tên" require="true"></InputCustom>
            </Form.Item>
            <Form.Item
              name="cusId"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập SĐT",
                },
              ]}
            >
              <InputCustom label="Số điện thoại" require="true"></InputCustom>
            </Form.Item>
            <Form.Item
              name="tickStatus"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn trạng thái",
                },
              ]}
            >
              <SelectCustom
                label="Trạng thái"
                require="true"
                options={[
                  { value: "Y", label: "Bật" },
                  { value: "N", label: "Tắt" },
                ]}
                showSearch
                className="!w-32"
              ></SelectCustom>
            </Form.Item>
            <Form.Item
              name="tienFlg"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn",
                },
              ]}
            >
              <SelectCustom
                label="Thu tiền"
                require="true"
                options={[
                  { value: "Y", label: "Đã thu" },
                  { value: "N", label: "Chưa thu" },
                ]}
                showSearch
                className="!w-40"
              ></SelectCustom>
            </Form.Item>
            <Form.Item
              name="startDate"
              // onChange={handleChangeDate}
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập ngày bắt đầu",
                },
              ]}
            >
              <DatePickerCustom
                className="w-full"
                label={"Ngày bắt đầu"}
                format="DD/MM/YYYY"
                placeholder=""
                require="true"
                // onChange={(date, dateString) => onChangeBrdyVal(date, dateString)}
              />
            </Form.Item>
            <Form.Item
              name="endDate"
              // onChange={handleChangeDate}
              // rules={[
              //   {
              //     required: true,
              //     message: "Vui lòng nhập ngày kết thúc",
              //   },
              // ]}
            >
              <DatePickerCustom
                className="w-full"
                label={"Ngày kết thúc"}
                format="DD/MM/YYYY"
                placeholder=""
                // onChange={(date, dateString) => onChangeBrdyVal(date, dateString)}
              />
            </Form.Item>
            <Form.Item
              name="remark"
              // rules={[
              //   {
              //     required: true,
              //     message: "Vui lòng nhập biển số xe",
              //   },
              // ]}
            >
              <InputCustom label="Ghi chú" className="!w-96"></InputCustom>
            </Form.Item>
          </div>
        </div>
        <div className="p-4 mb-4 border border-white bg-white rounded-md">
          <p className="text-red-500 text-base font-medium mb-6">Lộ trình đi</p>
          <div className="flex space-x-4 mb-4">
            <Form.Item name="tuyenDi">
              <SelectCustom
                onChange={(value) => setTuyenDiId(value)}
                require="true"
                options={[
                  { value: "TKDN", label: "Tam Kỳ - Đà Nẵng" },
                  { value: "DNTK", label: "Đà Nẵng - Tam Kỳ" },
                ]}
                label="Tuyến đi"
                placeholdercustom="Tuyến"
                // style={{ width: "288px" }}
                // loading={loadingSelect}
                // showSearch
                optionFilterProp="children"
                className="!w-64"
              ></SelectCustom>
            </Form.Item>

            <Form.Item
              name="khungGioIdDi"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn trạng thái",
                },
              ]}
            >
              <SelectCustom
                label="Thời gian đi"
                require="true"
                options={khungGioComboDi}
                showSearch
                className="!w-48"
              ></SelectCustom>
            </Form.Item>
            <Form.Item
              name="carTypeDi"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn",
                },
              ]}
            >
              <SelectCustom
                label="Loại xe"
                require="true"
                options={[
                  { value: "4", label: "4 chổ" },
                  { value: "6", label: "6 chổ" },
                ]}
                showSearch
                className="!w-44"
              ></SelectCustom>
            </Form.Item>
            <Form.Item
              name="soGheDi"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn",
                },
              ]}
            >
              <SelectCustom
                label="Số ghế"
                require="true"
                options={[
                  { value: 1, label: "1" },
                  { value: 2, label: "2" },
                  { value: 3, label: "3" },
                  { value: 4, label: "4" },
                  { value: 5, label: "5" },
                  { value: 0, label: "Bao xe" },
                ]}
                showSearch
                className="!w-44"
              ></SelectCustom>
            </Form.Item>
            <Form.Item
              name="giaMoiDi"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập",
                },
              ]}
            >
              <InputNumberCustom
                label="Giá mới"
                require="true"
                showSearch
                className="!w-48"
              ></InputNumberCustom>
            </Form.Item>
            <Form.Item valuePropName="checked" name="tienDiFlg" className="w-4 !ml-4">
              {/* <Tooltip title="Đã Thanh Toán"> */}
              <Switch
                // onClick={bindingData}
                // className="!pt-2"
                // style={{ backgroundColor: "orange", height: "2px" }}
                // size="small"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                className="!w-12"
              />
              {/* </Tooltip> */}
            </Form.Item>
          </div>
          {isLoaded && (
            <div className="mb-4 grid grid-cols-2">
              <div className="mr-4">
                <div className="flex">
                  <p className="">Điểm đón chiều đi</p>
                  <p className="font-bold ml-2 text-red-500">
                    {tuyenDiId === "TKDN" ? "Tam Kỳ" : "Đà Nẵng"}
                  </p>
                </div>

                <StandaloneSearchBox
                  onLoad={(ref) => (diemDonRefDi.current = ref)}
                  onPlacesChanged={onDiemDonSelectedDi}
                  options={{
                    bounds: new window.google.maps.LatLngBounds(
                      new window.google.maps.LatLng(15.15, 107.7), // SW corner
                      new window.google.maps.LatLng(16.2, 108.5) // NE corner
                    ),
                    componentRestrictions: { country: "vn" },
                  }}
                >
                  <input
                    type="text"
                    defaultValue={locDi.tripStartAddDi}
                    // onChange={(e) => {
                    //   setDiemDonAdd(e.target.value);
                    // }}
                    className="w-full h-10 text-sm p-4 border-2 rounded-md border-slate-200"
                    placeholder="Điểm đón"
                  />
                </StandaloneSearchBox>
              </div>
              <div className="">
                <div className="flex">
                  <p className="">Điểm trả chiều đi</p>
                  <p className="font-bold ml-2 text-red-500">
                    {tuyenDiId === "DNTK" ? "Tam Kỳ" : "Đà Nẵng"}
                  </p>
                </div>
                <StandaloneSearchBox
                  onLoad={(ref) => (diemTraRefDi.current = ref)}
                  onPlacesChanged={onDiemTraSelectedDi}
                  options={{
                    bounds: new window.google.maps.LatLngBounds(
                      new window.google.maps.LatLng(15.15, 107.7), // SW corner
                      new window.google.maps.LatLng(16.2, 108.5) // NE corner
                    ),
                    componentRestrictions: { country: "vn" },
                  }}
                >
                  <input
                    type="text"
                    defaultValue={locDi.tripEndAddDi}
                    // onChange={(e) => {
                    //   setDiemTraAdd(e.target.value);
                    // }}
                    className="w-full h-10 text-sm p-4 border-2 rounded-md border-slate-200"
                    placeholder="Điểm trả"
                  />
                </StandaloneSearchBox>
              </div>
            </div>
          )}
          <p className="flex">
            <span className="text-red-400 mr-2">*</span>Ngày đi
          </p>
          <div className="flex space-x-4">
            <Form.Item name="thuDi">
              <CheckboxGroup options={thuCheckbox} value={["Thứ hai"]} onChange={null} />
            </Form.Item>
          </div>
        </div>
        <div className="p-4 mb-4 border border-white bg-white rounded-md">
          <div className="flex justify-between">
            <p className="text-red-500 text-base font-medium mb-6">Lộ trình về</p>
            <Form.Item name="loTrinhVeSwitch" initialValue valuePropName="checked">
              <Switch
                // size="small"
                defaultChecked
                onChange={() => {
                  setLotrinhVe(!loTrinhVe);
                }}
                // checkedChildren={<CheckOutlined />}
                // unCheckedChildren={<CloseOutlined />}
                className="!w-12"
              />
            </Form.Item>
          </div>
          {loTrinhVe && (
            <div>
              <div className="flex space-x-4 mb-4">
                <Form.Item name="tuyenVe">
                  <SelectCustom
                    onChange={(value) => setTuyenVeId(value)}
                    require="true"
                    options={[
                      { value: "TKDN", label: "Tam Kỳ - Đà Nẵng" },
                      { value: "DNTK", label: "Đà Nẵng - Tam Kỳ" },
                    ]}
                    label="Tuyến về"
                    placeholdercustom="Tuyến"
                    // style={{ width: "288px" }}
                    // loading={loadingSelect}
                    // showSearch
                    optionFilterProp="children"
                    className="!w-64"
                  ></SelectCustom>
                </Form.Item>

                <Form.Item
                  name="khungGioIdVe"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng chọn trạng thái",
                    },
                  ]}
                >
                  <SelectCustom
                    label="Thời gian về"
                    require="true"
                    options={khungGioComboVe}
                    showSearch
                    className="!w-48"
                  ></SelectCustom>
                </Form.Item>
                <Form.Item
                  name="carTypeVe"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng chọn",
                    },
                  ]}
                >
                  <SelectCustom
                    label="Loại xe"
                    require="true"
                    options={[
                      { value: "4", label: "4 chổ" },
                      { value: "6", label: "6 chổ" },
                    ]}
                    showSearch
                    className="!w-44"
                  ></SelectCustom>
                </Form.Item>
                <Form.Item
                  name="soGheVe"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng chọn",
                    },
                  ]}
                >
                  <SelectCustom
                    label="Số ghế"
                    require="true"
                    options={[
                      { value: 1, label: "1" },
                      { value: 2, label: "2" },
                      { value: 3, label: "3" },
                      { value: 4, label: "4" },
                      { value: 5, label: "5" },
                      { value: 6, label: "Bao xe" },
                    ]}
                    showSearch
                    className="!w-44"
                  ></SelectCustom>
                </Form.Item>
                <Form.Item
                  name="giaMoiVe"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập",
                    },
                  ]}
                >
                  <InputNumberCustom
                    label="Giá mới"
                    require="true"
                    showSearch
                    className="!w-48"
                  ></InputNumberCustom>
                </Form.Item>
                <Form.Item valuePropName="checked" name="tienVeFlg" className="w-4 !ml-4">
                  {/* <Tooltip title="Đã Thanh Toán"> */}
                  <Switch
                    // onClick={bindingData}
                    // className="!pt-2"
                    // style={{ backgroundColor: "orange", height: "2px" }}
                    // size="small"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    className="!w-12"
                  />
                  {/* </Tooltip> */}
                </Form.Item>
              </div>
              {isLoaded && (
                <div className="mb-4 grid grid-cols-2">
                  <div className="mr-4">
                    <div className="flex">
                      <p className="">Điểm đón chiều đi</p>
                      <p className="font-bold ml-2 text-red-500">
                        {tuyenVeId === "TKDN" ? "Tam Kỳ" : "Đà Nẵng"}
                      </p>
                    </div>

                    <StandaloneSearchBox
                      onLoad={(ref) => (diemDonRefVe.current = ref)}
                      onPlacesChanged={onDiemDonSelectedVe}
                      options={{
                        bounds: new window.google.maps.LatLngBounds(
                          new window.google.maps.LatLng(15.15, 107.7), // SW corner
                          new window.google.maps.LatLng(16.2, 108.5) // NE corner
                        ),
                        componentRestrictions: { country: "vn" },
                      }}
                    >
                      <input
                        type="text"
                        defaultValue={locVe.tripStartAddVe}
                        // onChange={(e) => {
                        //   setDiemDonAdd(e.target.value);
                        // }}
                        className="w-full h-10 text-sm p-4 border-2 rounded-md border-slate-200"
                        placeholder="Điểm đón"
                      />
                    </StandaloneSearchBox>
                  </div>
                  <div className="">
                    <div className="flex">
                      <p className="">Điểm trả chiều đi</p>
                      <p className="font-bold ml-2 text-red-500">
                        {tuyenVeId === "DNTK" ? "Tam Kỳ" : "Đà Nẵng"}
                      </p>
                    </div>
                    <StandaloneSearchBox
                      onLoad={(ref) => (diemTraRefVe.current = ref)}
                      onPlacesChanged={onDiemTraSelectedVe}
                      options={{
                        bounds: new window.google.maps.LatLngBounds(
                          new window.google.maps.LatLng(15.15, 107.7), // SW corner
                          new window.google.maps.LatLng(16.2, 108.5) // NE corner
                        ),
                        componentRestrictions: { country: "vn" },
                      }}
                    >
                      <input
                        type="text"
                        defaultValue={locVe.tripEndAddVe}
                        // onChange={(e) => {
                        //   setDiemTraAdd(e.target.value);
                        // }}
                        className="w-full h-10 text-sm p-4 border-2 rounded-md border-slate-200"
                        placeholder="Điểm trả"
                      />
                    </StandaloneSearchBox>
                  </div>
                </div>
              )}
              <p className="flex">
                <span className="text-red-400 mr-2">*</span>Ngày đi
              </p>
              <div className="flex space-x-4">
                <Form.Item name="thuVe">
                  <CheckboxGroup options={thuCheckbox} onChange={null} />
                </Form.Item>
              </div>
            </div>
          )}
          {!loTrinhVe && <p>Không chọn lộ trình về</p>}
        </div>
        <div className="flex justify-end">
          <Button
            type="primary"
            disabled={false}
            onClick={() => {
              onFinish();
            }}
            className="button btn_custom"
            icon={<SaveOutlined />}
          >
            Lưu
          </Button>
          <Button
            // type="primary"
            className="button btn_custom"
            icon={<RollbackOutlined />}
            onClick={() => {
              setOnNewFunc(false);
            }}
            // loading={loadingExport}
          >
            Quay lại
          </Button>
        </div>
      </Form>
    </>
  );
}

export default VeThangListForm;
