import { Modal, Button, Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { InputCustom, SelectCustom } from "components";
import carApi from "api/Call/carApi";
import driverApi from "api/Call/driverApi";
import { parseDataCombobox } from "utils/common";

function CarDetail({ isOpen, onClose, data }) {
  const [carName, setCarName] = useState();
  const [listCar, setListCar] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    loadCarList();
  }, []);

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        driverName: data.driverName,
        carId: null,
      });
      if (data.carId) {
        setCarName(`${data.carName}-${data.carNumber}-Xe ${data.carType} chổ`);
      } else {
        setCarName();
      }
    }
  }, [isOpen]);

  const handleOk = () => {
    onFinish();
  };

  const handleClose = () => {
    onClose(true);
  };
  const loadCarList = async () => {
    try {
      const result = await carApi.getCarAssign();
      if (result.success) {
        const dataParse = [];
        result.data.map((item) =>
          dataParse.push({
            label: item["carName"],
            value: Number(item["carId"]),
          })
        );
        setListCar(dataParse);
      }
    } catch (error) {
      throw new Error();
    } finally {
      // setLoadingSelect(false);
    }
  };
  const onFinish = async () => {
    await form.validateFields().then(() => {
      driverApi.updateDriver({ driverId: data.driverId, carId: form.getFieldValue("carId") });
      onClose(true);
    });
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        className="material-popup"
        onCancel={handleClose}
        maskClosable={false}
        onOk={handleOk}
        okText="Thêm"
        cancelText="Hủy"
        title="Thêm xe cho tài xế"
        width={"30%"}
        open={isOpen}
      >
        <Form colon={false} onFinish={onFinish} form={form} className="form-control-inventory-his">
          {carName && (
            <div className="mb-4 p-2 bg-red-600 text-lg text-white">
              Tài xế được gán cho xe <p className="m-0 font-medium">{carName}</p>
            </div>
          )}
          <div className="mb-4">
            <Form.Item
              name="driverName"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập họ tên",
                },
              ]}
            >
              <InputCustom disabled label="Họ Tên" require="true"></InputCustom>
            </Form.Item>
          </div>
          <div>
            <Form.Item name="carId">
              <SelectCustom
                label="Chọn xe"
                // require="true"
                // disabled={true}
                options={listCar}
                showSearch
                optionFilterProp="label"
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
                allowClear
              ></SelectCustom>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default CarDetail;
