import axiosClient from "core/axiosClient";
const khungGioApi = {
  getKhungGio: (params) => {
    const url = "/api/khung-gio/getKhungGio";
    return axiosClient.post(url, params);
  },

  getKhungGioList: (params) => {
    const url = "/api/khung-gio/getKhungGioList";
    return axiosClient.post(url, params);
  },

  saveKhungGio: (params) => {
    const url = "/api/khung-gio/saveKhungGio";
    return axiosClient.post(url, params);
  },

  updateKhungGioStatus: (params) => {
    const url = "/api/khung-gio/updateKhungGioStatus";
    return axiosClient.post(url, params);
  },
};
export default khungGioApi;
