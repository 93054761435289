import { Col, Row, Table, Button, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import TripMapPopup from "pages/TripMapPopup/TripMapPopup";
import moment from "moment";
import tripRecordApi from "api/Call/tripRecord";
import TableFooter from "components/TableFooter";
import "moment/locale/vi";

moment.locale("vi");

function DriverSalaryListTable({ dataTable, loading }) {
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [dataPopup, setDataPopup] = useState({});

  useEffect(() => {
    const resizeHandler = () => {
      const windowHeight = window.innerHeight;
      const tableHeight = windowHeight - 170; // You can adjust this value according to your needs
      const tableElement = document.querySelector(".ant-table-body");
      if (tableElement) {
        tableElement.style.maxHeight = `${tableHeight}px`;
      }
    };
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  // const onRowClassName = (record, index) => {
  //   return (
  //     (index % 2 === 0 ? "table-row-light " : "table-row-dark") +
  //     (index === indexSelect ? " ant-table-row-selected" : "")
  //   );
  // };

  function transformColumns(columns) {
    return columns.map((column) => {
      if (column.dataIndex === "name") {
        return {
          ...column,
          render: (_, record) => (
            <div className="bg-blue-500 w-full h-full pl-2 p-1">
              <p className="text-white mb-0.5">{record.name}</p>
              <p className="text-white mb-0">{record.cusId}</p>
            </div>
          ),
        };
      } else if (column.dataIndex === "ghiChu") {
        return {
          ...column,
          render: (_, record) => (
            <div className="w-full h-full pl-2 p-1">
              <p className="text-red-500 mb-1 ">{record.tienFlg === 0 ? "Đã Thanh Toán" : ""}</p>
              <p className="mb-1">{record.ghiChu}</p>
            </div>
          ),
        };
      }
      {
        return column;
      }
    });
  }

  const onClose = () => {
    setIsOpenDetail(false);
  };

  const onEdit = (param) => {
    setDataPopup({ ...param, status: "EDIT" });
    setIsOpenDetail(true);
  };

  const onCopy = (param) => {
    setDataPopup({ ...param, status: "COPY" });
    setIsOpenDetail(true);
  };

  const onCancelTrip = (param) => {
    tripRecordApi.updateOrderStatus(param);
  };

  // const compareDateNow = (string1, string2) => {
  //   const part1 = string1.split(/[/]/);
  //   const part2 = string1.split(/[:]/);
  //   const date = new Date(part1[2], parts[1] - 1, parts[0], parts[3], parts[4]);
  // };

  const defineInventoryHis = [
    {
      title: "No.",
      dataIndex: "index",
      align: "center",
      width: "60px",
      footerContent: "Tổng:",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Lái xe",
      dataIndex: "driverName",
      align: "center",
      width: "130px",
    },
    {
      title: "Tổng chuyến",
      dataIndex: "tongChuyen",
      align: "center",
      width: "80px",
      footerSum: true,
      render: (_, record) => (record.tongChuyen > 0 ? record.tongChuyen : "-"),
    },
    {
      title: "Chuyến 4 chổ",
      dataIndex: "typeFour",
      align: "center",
      width: "80px",
      footerSum: true,
      render: (_, record) => (record.typeFour > 0 ? record.typeFour : "-"),
    },
    {
      title: "Chuyến 7 chổ",
      dataIndex: "typeSeven",
      align: "center",
      width: "80px",
      footerSum: true,
      render: (_, record) => (record.typeSeven > 0 ? record.typeSeven : "-"),
    },
    {
      title: "Chuyến Trống",
      dataIndex: "soTrong",
      align: "center",
      width: "80px",
      footerSum: true,
      render: (_, record) =>
        record.soTrong > 0 ? <p className="text-red-500 !m-0">{record.soTrong}</p> : "-",
    },
    {
      title: "Chuyến bx",
      dataIndex: "soBx",
      align: "center",
      width: "80px",
      footerSum: true,
      render: (_, record) => (record.soBx > 0 ? record.soBx : "-"),
    },
    {
      title: "Số ghế",
      dataIndex: "soGhe",
      align: "center",
      width: "80px",
      footerSum: true,
      render: (_, record) => (record.soGhe > 0 ? record.soGhe : "-"),
    },
    {
      title: "Hàng",
      dataIndex: "soHang",
      align: "center",
      width: "80px",
      footerSum: true,
      render: (_, record) => (record.soHang > 0 ? record.soHang : "-"),
    },
    {
      title: "% DT(1)",
      dataIndex: "revBx",
      align: "center",
      width: "100px",
      footerEndfix: true,
      render: (_, record) =>
        record.official == "Y" ? (record.revHang + record.revKhach + record.revBx) * 0.025 : "-",
    },
    {
      title: "Lương HĐ(1)",
      dataIndex: "salHD",
      align: "center",
      width: "100px",
      footerEndfix: true,
      render: (_, record) => (record.salHD > 0 ? `${record.salHD}K` : "-"),
    },
    {
      title: "Lương K.Lẻ(3)",
      dataIndex: "salKL",
      align: "center",
      width: "100px",
      footerEndfix: true,
      render: (_, record) => (record.salKL > 0 ? `${record.salKL}K` : "-"),
    },
    {
      title: "Tổng (1+2+3)",
      dataIndex: "totalSalary",
      width: "120px",
      align: "center",
      footerEndfix: true,
      render: (_, record) => (record.totalSalary > 0 ? `${record.totalSalary}K` : "-"),
    },
    {
      title: "Thao Tác",
      dataIndex: "ttlQty",
      responsive: ["md"],
      width: "100px",
      inputType: "number",
      require: false,
      align: "center",
      render: (_, record) => (
        <div className="flex justify-center">
          <Tooltip title="Chi tiết" color={"green"} key={"green5"}>
            <Button
              icon={<EyeOutlined style={{ fontSize: "20px", color: "#6aa84f" }} />}
              // onClick={() => {
              //   onCopy(record);
              // }}
              type="text"
              size="small"
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="table-salary-list">
        <Table
          columns={transformColumns(defineInventoryHis)}
          dataSource={dataTable}
          rowKey={() => Math.floor(Math.random() * 1000000).toString()}
          bordered
          scroll={{ y: 1100 }}
          footer={() => {
            return <TableFooter dataSource={dataTable} columns={defineInventoryHis} />;
          }}
          size="small"
          loading={loading}
          pagination={false}
        />
      </div>
      <TripMapPopup data={dataPopup} isOpen={isOpenDetail} onClose={onClose}></TripMapPopup>
    </>
  );
}

export default DriverSalaryListTable;
