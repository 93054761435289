import { message, Modal, Button, Spin } from "antd";
import React, { useState, useEffect } from "react";
import TripPopupControl from "./Components/TripPopupControl";
import MapPopupDetail from "./Components/MapPopupDetail";
import { useDispatch } from "react-redux";
import "./TripMapPopup.scss";
import tripRecordApi from "api/Call/tripRecord";
import { setPhoneNumberSave, setNhapLieuStatus } from "redux/callingSlice.js";
import _ from "lodash";

const { confirm } = Modal;

function TripMapPopup({ isOpen, onClose, data }) {
  const [onSaveFlg, setOnSaveFlg] = useState(false);
  const [tripInfor, setTripInfor] = useState();
  const [tripOrder, setTripOrder] = useState([]);
  const [tripOrderSave, setTripOrderSave] = useState();
  const [tuyenId, setTuyenId] = useState();
  const [driverList, setDriverList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState({ status: "", cusId: "" });
  const dispatch = useDispatch();

  const setTripInforFunc = (a, b, c, d, e, f) => {
    setTripInfor({
      diemDonAdd: a,
      diemDonLat: b,
      diemDonLng: c,
      diemTraAdd: d,
      diemTraLat: e,
      diemTraLng: f,
    });
  };

  const setLoadingFunc = (params) => {
    setLoading(params);
  };

  const setTripOrderFunc = (params, driverList) => {
    setTripOrder(params);
    setDriverList(driverList);
  };
  const setTuyenIdFunc = (params) => {
    setTuyenId(params);
  };
  const setTripOrderSaveFunc = (params) => {
    setTripOrderSave(params);
  };

  useEffect(() => {
    if (data) {
      setTitle(data);
    }
  }, [data]);

  useEffect(() => {
    if (isOpen) {
      setTripOrder([]);
      window.omiSDK.setBusy("all");
    } else {
      setTuyenId();
    }
  }, [isOpen]);

  // const handleOk = () => {
  //   onFinish();
  // };

  const handleClose = () => {
    window.omiSDK.setBusy(false);
    setTuyenId();
    onClose(true);
  };

  const getTripHistoryLs = (oldData, newData) => {
    let result = [];
    if (!_.isEqual(oldData.name, newData.name))
      // tên
      result.push({
        tripId: oldData.tripId,
        prmNm: "Tên",
        evntTpCd: "name",
        bfrCng: oldData.name,
        aftCng: newData.name,
      });
    if (!_.isEqual(oldData.diemDonAdd, newData.diemDonAdd))
      // điểm đón
      result.push({
        tripId: oldData.tripId,
        prmNm: "Điểm đón",
        evntTpCd: "diemDonAdd",
        bfrCng: oldData.diemDonAdd,
        aftCng: newData.diemDonAdd,
      });
    if (!_.isEqual(oldData.diemTraAdd, newData.diemTraAdd))
      // điểm trả
      result.push({
        tripId: oldData.tripId,
        prmNm: "Điểm trả",
        evntTpCd: "diemTraAdd",
        bfrCng: oldData.diemTraAdd,
        aftCng: newData.diemTraAdd,
      });
    if (!_.isEqual(oldData.ghiChu, newData.ghiChu))
      // điểm trả
      result.push({
        tripId: oldData.tripId,
        prmNm: "Ghi chú",
        evntTpCd: "ghiChu",
        bfrCng: oldData.ghiChu,
        aftCng: newData.ghiChu,
      });
    if (!_.isEqual(oldData.khungGioId, newData.khungGioId))
      result.push({
        tripId: oldData.tripId,
        prmNm: "Khung giờ",
        evntTpCd: "khungGioId",
        bfrCng: oldData.khungGioId,
        aftCng: newData.khungGioId,
      });
    if (!_.isEqual(oldData.ngayDi, newData.ngayDi))
      result.push({
        tripId: oldData.tripId,
        prmNm: "Ngày đi",
        evntTpCd: "ngayDi",
        bfrCng: oldData.ngayDi,
        aftCng: newData.ngayDi,
      });
    if (!_.isEqual(oldData.soKhach, newData.soGhe))
      result.push({
        tripId: oldData.tripId,
        prmNm: "Số ghế",
        evntTpCd: "soGhe",
        bfrCng: oldData.soKhach,
        aftCng: newData.soGhe,
      });
    if (!_.isEqual(oldData.soTien, newData.soTien))
      result.push({
        tripId: oldData.tripId,
        prmNm: "Số tiền",
        evntTpCd: "soTien",
        bfrCng: oldData.soTien,
        aftCng: newData.soTien,
      });
    if (!_.isEqual(oldData.tienFlg, newData.tienFlg))
      result.push({
        tripId: oldData.tripId,
        prmNm: "Đã thanh toán",
        evntTpCd: "tienFlg",
        bfrCng: oldData.tienFlg,
        aftCng: newData.tienFlg,
      });
    if (!_.isEqual(oldData.tripType, newData.tripType))
      result.push({
        tripId: oldData.tripId,
        prmNm: "Loại chuyến đi",
        evntTpCd: "tripType",
        bfrCng: oldData.tripType,
        aftCng: newData.tripType,
      });
    if (!_.isEqual(oldData.tuyen, newData.tuyen))
      result.push({
        tripId: oldData.tripId,
        prmNm: "Tuyến",
        evntTpCd: "tuyen",
        bfrCng: oldData.tuyen,
        aftCng: newData.tuyen,
      });
    // if (!_.isEqual(oldData.giaMoi, newData.giaMoi))
    //   result.push({
    //     tripId: oldData.tripId,
    //     prmNm: "Giá mới",
    //     bfrCng: oldData.giaMoi,
    //     aftCng: newData.giaMoi,
    //   });
    return result;
  };

  const onSave = async () => {
    if (!tripOrderSave || !tripOrderSave.name) {
      message.warning("Vui lòng nhập tên");
      return;
    }
    if (!tripInfor || tripInfor.diemDonAdd === "") {
      message.warning("Vui lòng nhập điểm đón");
      return;
    }
    if (!tripInfor || tripInfor.diemTraAdd === "") {
      message.warning("Vui lòng nhập điểm trả");
      return;
    }
    if (!tripOrderSave || !tripOrderSave.khungGioId) {
      message.warning("Vui lòng chọn khung giờ");
      return;
    }
    await confirm({
      title: "Bạn có muốn lưu lại chuyến đi này",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        const ls = {
          ...tripOrderSave,
          diemDonAdd: tripInfor.diemDonAdd,
          diemDonLat: tripInfor.diemDonLat,
          diemDonLng: tripInfor.diemDonLng,
          diemTraAdd: tripInfor.diemTraAdd,
          diemTraLat: tripInfor.diemTraLat,
          diemTraLng: tripInfor.diemTraLng,
          cusId: data.cusId,
          name: data.name === "" ? tripOrderSave.name : data.name,
          // hàng
          soGhe:
            tripOrderSave.soGhe === 100
              ? 0
              : tripOrderSave.soGhe === 101
              ? 6
              : tripOrderSave.soGhe === 102
              ? 4
              : tripOrderSave.soGhe,
          tripType:
            tripOrderSave.soGhe === 100
              ? 1
              : tripOrderSave.soGhe === 101
              ? 2
              : tripOrderSave.soGhe === 102
              ? 3
              : 0,
          soTien:
            tripOrderSave.soGhe === 100 // Hàng
              ? tripOrderSave.giaMoi || tripOrderSave.giaMoi === 0
                ? tripOrderSave.giaMoi
                : 50
              : tripOrderSave.soGhe === 101 // Bao xe 7 chổ
              ? tripOrderSave.giaMoi || tripOrderSave.giaMoi === 0
                ? tripOrderSave.giaMoi
                : 600
              : tripOrderSave.soGhe === 102 // Bao xe 4 chổ
              ? tripOrderSave.giaMoi || tripOrderSave.giaMoi === 0
                ? tripOrderSave.giaMoi
                : 350
              : tripOrderSave.giaMoi || tripOrderSave.giaMoi === 0 // Người
              ? tripOrderSave.giaMoi
              : 100,
          tienFlg: tripOrderSave.tienFlg ? 0 : 1,
          giaMoi: tripOrderSave.giaMoi || tripOrderSave.giaMoi === 0 ? 0 : 1,
          tripId: data.tripId,
        };
        if (data.status === "EDIT") {
          const hisVo = getTripHistoryLs(data, ls);
          if (hisVo.length > 0) {
            await tripRecordApi.updateOrder({ ...ls, lsTripRecordHis: hisVo });
          } else {
            await tripRecordApi.updateOrder(ls);
          }
          dispatch(setPhoneNumberSave(title.cusId));
          dispatch(setNhapLieuStatus(false));
          window.omiSDK.setBusy(false);
          onClose(true);
        } else {
          await tripRecordApi.saveTripRecord(ls);
          dispatch(setPhoneNumberSave(title.cusId));
          dispatch(setNhapLieuStatus(false));
          window.omiSDK.setBusy(false);
          onClose(true);
        }
      },
      onCancel() {
        return;
      },
    });
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        className="plan-popup"
        onCancel={() => {
          handleClose();
        }}
        title={
          <div className="flex justify-between">
            <div className="flex">
              <p className="text-lg mb-0 mt-1">
                {title.status === "NEW"
                  ? "Tạo mới cho"
                  : title.status === "COPY"
                  ? "Sao chép cho"
                  : "Chỉnh sửa cho"}
              </p>
              <p className="text-lg text-blue-500 ml-4 mb-0 mt-1">{title.cusId}</p>
            </div>

            <div className="flex">
              <Button
                className="button btn_custom w-24"
                type="primary"
                onClick={onSave}
                size="medium"
              >
                Lưu
              </Button>
              <Button
                danger
                className="button btn_custom w-20"
                type="primary"
                onClick={handleClose}
                size="medium"
              >
                Hủy
              </Button>
            </div>
          </div>
        }
        closable={false}
        footer={null}
        width={"99%"}
        open={isOpen}
      >
        <Spin spinning={loading}>
          <div className="flex h-full">
            <TripPopupControl
              onSaveFlg={onSaveFlg}
              dataCus={data}
              setTripOrderFunc={setTripOrderFunc}
              setTuyenIdFunc={setTuyenIdFunc}
              setTripOrderSaveFunc={setTripOrderSaveFunc}
              setLoadingFunc={setLoadingFunc}
            />
            <MapPopupDetail
              setTripInforFunc={setTripInforFunc}
              tripOrder={tripOrder}
              tuyenId={tuyenId}
              driverList={driverList}
              dataCus={data}
              isOpen={isOpen}
            />
          </div>
        </Spin>
      </Modal>
    </>
  );
}

export default TripMapPopup;
