import { Col, Row, Table, Tag, Modal } from "antd";
import React, { useEffect } from "react";

import moment from "moment";
import "moment/locale/vi";

moment.locale("vi");

function TripEditHisTable({ dataTable, loading }) {
  useEffect(() => {
    const resizeHandler = () => {
      const windowHeight = window.innerHeight;
      const tableHeight = windowHeight - 170; // You can adjust this value according to your needs
      const tableElement = document.querySelector(".ant-table-body");
      if (tableElement) {
        tableElement.style.maxHeight = `${tableHeight}px`;
      }
    };
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  const defineInventoryHis = [
    {
      title: "No.",
      dataIndex: "index",
      align: "center",
      width: "60px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Thay đổi",
      dataIndex: "prmNm",
      align: "left",
      width: "70px",
      // render: (text, record, index) => (
      //   <Tag color={"green"} key="Y">
      //     {record.prmNm}
      //   </Tag>
      // ),
    },
    {
      title: "Trước thay đổi",
      dataIndex: "bfrCng",
      align: "left",
      width: "150px",
      render: (text, record, index) =>
        record.evntTpCd === "status" ? (
          <Tag color={"green"} key="Y">
            Đang hoạt động
          </Tag>
        ) : (
          record.bfrCng
        ),
    },
    {
      title: "Sau thay đổi",
      dataIndex: "aftCng",
      align: "left",
      width: "150px",
      render: (text, record, index) =>
        record.evntTpCd === "status" ? (
          <Tag color={"volcano"} key="N">
            Đã hủy
          </Tag>
        ) : (
          record.aftCng
        ),
    },
    {
      title: "Thời gian thay đổi",
      dataIndex: "ngayDi",
      align: "center",
      width: "80px",
      render: (text, row) =>
        moment(moment(row["evntDt"], "HH:mm:ss DD/MM/YYYY")).format("HH:mm:ss DD-MM (dddd)"),
    },
    {
      title: "Ng.Thay đổi",
      dataIndex: "createUser",
      width: "80px",
      align: "center",
    },
  ];

  return (
    <>
      <Row>
        <Col className="table-inventory-his">
          <Table
            columns={defineInventoryHis}
            dataSource={dataTable}
            rowKey={(record) => record.mtrlId + Math.floor(Math.random() * 1000000).toString()}
            bordered
            scroll={{ y: 700 }}
            size="small"
            loading={loading}
            pagination={false}
          />
        </Col>
      </Row>
    </>
  );
}

export default TripEditHisTable;
