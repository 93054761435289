import React, { useState } from "react";
import "./UserMgt.scss";
import UserMgtDetail from "./UserMgtDetail";
import UserMgtForm from "./UserMgtForm";
import UserMgtList from "./UserMgtList";
import { Row } from "antd";

const UserMgt = () => {
  const [paramSearch, setParamSearch] = useState({});
  const [recordTable, setReCordTable] = useState({
    usrId: "",
    usrNm: "",
    usrEml: "",
  });
  const [clickNew, setClickNew] = useState(null);
  const [clickDelete, setClickDelete] = useState(null);
  const [clickSave, setClickSave] = useState(null);

  const handleSearch = (data) => {
    setParamSearch(data);
  };

  const handleClickTable = (data) => {
    setReCordTable(data);
  };

  const handleClickNew = () => {
    setClickNew(!clickNew);
  };

  const handleClickDelete = () => {
    setClickDelete(!clickDelete);
  };

  const handleClickSave = () => {
    setClickSave(!clickSave);
  };

  return (
    <>
      <UserMgtForm
        onSearch={handleSearch}
        onClickNew={handleClickNew}
        onClickDelete={handleClickDelete}
        onClickSave={handleClickSave}
      />
      <div className="container-form-user">
        <Row>
          <UserMgtList paramSearch={paramSearch} onRecord={handleClickTable} />
          <UserMgtDetail
            record={recordTable}
            clickNew={clickNew}
            clickDelete={clickDelete}
            clickSave={clickSave}
            onRecord={handleClickTable}
          />
        </Row>
      </div>
    </>
  );
};

export default UserMgt;
