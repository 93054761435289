import React, { useState, useEffect } from "react";
import { Button, Drawer, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import tripRecordApi from "api/Call/tripRecord";
import TripMapPopup2 from "pages/TripMapPopup/TripMapPopup";
import TripRecordListTable from "pages/TripRecordList/TripRecordListTable";
import _ from "lodash";
import { setPhoneNumberSave } from "redux/callingSlice.js";
import "./TripRecord.scss";
const TripRecord = () => {
  // state to store time
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [listOrder, setListOrder] = useState([]);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [newData, setNewData] = useState({ cusId: "", name: "" });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { status, acceptCall, searchFlg, phoneNumber, phoneNumberSave, nhapLieuStatus } =
    useSelector((state) => state.calling);
  const onNew = () => {
    // setDrawerVisible(false);
    // navigate("/trip-map", { state: { phoneNumber: phoneNumber, new: true } });
    setIsOpenDetail(true);
  };

  useEffect(() => {
    if (status == "incoming" && !nhapLieuStatus) {
      setDrawerVisible(true);
      getTripOrders();
    }
    if (phoneNumber && phoneNumber !== "" && !nhapLieuStatus) {
      setDrawerVisible(true);
      getTripOrders();
    }
  }, [phoneNumber, searchFlg, status]);

  useEffect(() => {
    if (phoneNumberSave) {
      getTripOrders();

      // setDrawerVisible(true);
    }
  }, [phoneNumberSave]);

  const getTripOrders = async () => {
    setLoading(true);
    dispatch(setPhoneNumberSave(""));
    const result = await tripRecordApi.getTripOrders({
      ngayPlan: "",
      cusId: phoneNumberSave ? phoneNumberSave : phoneNumber,
    });
    if (result.success) {
      if (result.data.length > 0) {
        setNewData({ cusId: phoneNumber, name: result.data[0].name, status: "NEW" });
        setListOrder(result.data);
        setDrawerVisible(true);
      } else {
        setListOrder([]);
        setDrawerVisible(false);
        setNewData({ cusId: phoneNumber, name: "", status: "NEW" });
        onNew();
        setIsOpenDetail(true);
      }
      setLoading(false);
    }
  };

  const onClose = (param) => {
    setIsOpenDetail(false);
  };

  return (
    <Drawer
      placement="top"
      title={
        <div className="flex items-center">
          <a className="text-black"></a>
          <a className="pl-4">{phoneNumber}</a>
        </div>
      }
      onClose={() => setDrawerVisible(false)}
      closable={false}
      open={drawerVisible}
      height={560}
      maskClosable={false}
      className="trip-record-his"
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space size="small">
          <Button onClick={onNew} type="primary">
            Tạo mới
          </Button>
          <Button onClick={() => setDrawerVisible(false)} type="primary" danger>
            Đóng
          </Button>
        </Space>
      }
      // getContainer="false"
    >
      <TripRecordListTable loading={loading} dataTable={listOrder} />
      <TripMapPopup2 data={newData} isOpen={isOpenDetail} onClose={onClose}></TripMapPopup2>
    </Drawer>
  );
};

export default TripRecord;
