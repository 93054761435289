import { Button, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { InputCustom } from "components";
import React, { useState, useEffect } from "react";
import MaterialDetailPopup from "./popup/DriverDetail";
import moment from "moment";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import khungGioApi from "api/Call/khungGioApi";
import ticketApi from "api/Call/ticketApi";
dayjs.extend(customParseFormat);
import "moment/locale/vi";

moment.locale("vi");

function VeThangListForm({ onData, onLoading, setOnNewFunc }) {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState();
  const [khungGioCombo, setKhungGioCombo] = useState();
  const [isOpenDetail, setIsOpenDetail] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      date: moment(),
    });
    setKhungGio();
    onFinish({ ngayPlan: moment().format("DD/MM/YYYY") });
  }, []);

  const setKhungGio = async () => {
    const resultKhungGio = await khungGioApi.getKhungGio({
      tuyen: "TKDN",
    });
    if (resultKhungGio.success) {
      const dataParse = [];
      resultKhungGio.data.map((item) =>
        dataParse.push({
          label: item["khungGioName"],
          value: item["khungGioName"],
        })
      );
      setKhungGioCombo(dataParse);
    }
  };

  const onFinish = (values) => {
    getTicketList(values);
  };

  const getTicketList = async (params) => {
    try {
      onLoading(true);
      const result = await ticketApi.getTicket(params);
      if (result.success) {
        setDataSource(result.data);
        onData(result.data);
      }
    } catch (error) {
      onLoading(false);
    } finally {
      onLoading(false);
    }
  };
  const onClose = () => {
    setIsOpenDetail(false);
  };

  return (
    <>
      <Form colon={false} onFinish={onFinish} form={form} className="form-control-inventory-his">
        <div className="flex justify-between">
          <div className="mt-1"></div>
          <div className="flex justify-start">
            <Form.Item name="name" className="!mr-2">
              <InputCustom label="Nhập nội dung cần tìm"></InputCustom>
            </Form.Item>

            <Button
              type="primary"
              disabled={false}
              onClick={() => {
                setOnNewFunc(true);
              }}
              size="large"
              className="!bg-[#4ec375] !border-none"
              icon={<PlusOutlined />}
            >
              Tạo mới
            </Button>
          </div>
        </div>
      </Form>
      <MaterialDetailPopup
        // data={record}
        isOpen={isOpenDetail}
        onClose={onClose}
      ></MaterialDetailPopup>
    </>
  );
}

export default VeThangListForm;
