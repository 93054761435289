import { Modal, Button } from "antd";
import React, { useState, useEffect } from "react";
import TripEditHisTable from "./TripEditHisTable";
import "./TripEditHisPopup.scss";
import tripRecordApi from "api/Call/tripRecord";

function TripEditHisPopup({ isOpen, onClose, data }) {
  const [loading, setLoading] = useState();
  const [dataSource, setDataSource] = useState();

  useEffect(() => {
    if (isOpen) {
      getTripOrders(data);
    }
  }, [isOpen]);

  const getTripOrders = async (param) => {
    try {
      setLoading(true);
      const result = await tripRecordApi.getTripRecordHis({ tripId: param.tripId });
      if (result.success) {
        setDataSource(result.data);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => {
    onClose(true);
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        className="plan-popup"
        onCancel={() => {
          handleClose();
        }}
        title={
          <div className="flex justify-between">
            <div className="flex">
              <p className="text-lg mb-0 mt-1 flex">
                Lịch sử thay đổi: SĐT
                <span className="text-blue-500 mx-2">{data ? data.cusId : ""}</span>
                Khung giờ
                <span className="text-blue-500 mx-2">{data ? data.khungGioName : ""}</span>
                Ngày
                <span className="text-blue-500 mx-2">{data ? data.ngayDi : ""}</span>
                Tuyến
                <span className="text-blue-500 mx-2">{data ? data.tuyenName : ""}</span>
              </p>
            </div>

            <div className="flex">
              <Button
                danger
                className="button btn_custom w-20"
                type="primary"
                onClick={onClose}
                size="medium"
              >
                Hủy
              </Button>
            </div>
          </div>
        }
        closable={false}
        footer={null}
        width={"99%"}
        open={isOpen}
      >
        <div className="flex h-full inventory-his">
          <TripEditHisTable loading={loading} dataTable={dataSource} />
        </div>
      </Modal>
    </>
  );
}

export default TripEditHisPopup;
