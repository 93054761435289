import React, { useState } from "react";
import { Select } from "antd";

import "./styles/Select.scss";

const SelectCustom = ({ selected, ...props }) => {
  const [focus, setFocus] = useState(false);
  let { label, value, placeholdercustom, type, require, hidden, defaultValue, ...restProps } =
    props;

  if (!placeholdercustom) placeholdercustom = label;

  const isOccupied = focus || (value && value.length !== 0) || value === "" || defaultValue;

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const requiredMark = require ? <span className="text-danger">*</span> : null;

  return (
    <div className="select-custom">
      <div className="float-label" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
        <Select {...props} />
        <label className={labelClass}>
          {isOccupied ? label : placeholdercustom} {requiredMark}
        </label>
      </div>
    </div>
  );
};

export default SelectCustom;
