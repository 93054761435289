import React, { useState } from "react";
import "./Car.scss";
import CarForm from "./CarForm";
import CarTable from "./CarTable";

function Car() {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);

  const onData = (value) => {
    setDataTable(value);
  };

  const onLoading = (value) => {
    setLoading(value);
  };

  return (
    <div>
      <CarForm onData={onData} onLoading={onLoading} />
      <CarTable loading={loading} dataTable={dataTable} />
    </div>
  );
}

export default Car;
